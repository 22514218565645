<template>
    <div
        v-if="recommends.length>0"
        class="home-block home-block3"
    >
        <div
            class="home-block-title"
            data-aos="fade-up"
        >
            {{ $t('home.block3.title') }}
        </div>
        <div class="swiper swiper-home-block3">
            <div class="swiper-wrapper">
                <!-- Slides -->
                <div
                    v-for="(item,index) in recommends"
                    :key="item.id"
                    class="swiper-slide"
                >
                    <img
                        class="image"
                        :src="recommendImg(index)"
                        alt=""
                    >
                    <div class="content">
                        {{ item.content }}
                    </div>
                </div>
            </div>
            <div class="swiper-button-prev block3-swiper-button-prev" />
            <div class="swiper-button-next block3-swiper-button-next" />
        </div>
    </div>
</template>

<script>
import API from '@/api';
import { mapMutations } from 'vuex';
import Swiper, { Navigation } from 'swiper';
import '@/assets/css/swiper-bundle.min.css';

Swiper.use([Navigation]);

export default {
    components: {},
    data() {
        return {
            recommends: [],
        };
    },
    computed: {},
    watch: {
        $route() {
            this.initSwiper();
        },
    },
    created() { },
    mounted() {
        this.initSwiper();
    },
    methods: {
        ...mapMutations('mNotification', ['setAlert', 'handleError']),
        async initSwiper() {
            try {
                const res = await API.HomeRecommend.PublicList({
                    headers: {
                        'Content-Language': (this.$route.path.split('/')[1] || 'zhTw') === 'en' ? 'en' : 'zhTw',
                    },
                });
                this.recommends = res.data.raw;
                setTimeout(() => {
                    this.setupSwiper();
                });
            } catch (e) {
                this.handleError({
                    message: e || this.$t('alertMessage.getdata-fail'),
                    color: 'error',
                });
            }
        },
        setupSwiper() {
            let slidesPerView = 3;
            const windowWidth = this.windowInnerWidth();

            if (windowWidth <= 800) {
                slidesPerView = 1;
            } else if (windowWidth > 600 && windowWidth <= 1280) {
                slidesPerView = 2;
            }

            // eslint-disable-next-line no-new
            new Swiper('.swiper-home-block3', {
                loop: true,
                slidesPerView,
                spaceBetween: 0,
                navigation: {
                    nextEl: '.block3-swiper-button-next',
                    prevEl: '.block3-swiper-button-prev',
                },
                autoplay: false,
            });
        },
        recommendImg(index) {
            const i = index % 3;
            return `/public-img/home/block-3-person-${(i % 3) + 1}.png`;
        },
        windowInnerWidth() {
            return window.innerWidth;
        },
    },
};
</script>

<style lang="scss">
.home-block3 {
  background-image: url('../../assets/home/block-bg-bottom.png');
  background-size: cover;
  background-repeat: no-repeat;
  padding: 120px 0 140px 0;

  .home-block-title {
    margin-bottom: 120px;
  }

  .swiper-home-block3 {
    width: 90%;

    .swiper-slide {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;

      .image {
        width: 191px;
        height: 172px;
      }

      .content {
        width: 335px;
        height: 170px;
        background-image: url('../../assets/home/block-3-content-bg.png');
        background-size: contain;
        background-repeat: no-repeat;
        font-weight: 700;
        font-size: 16px;
        line-height: 2;
        letter-spacing: 0.1em;
        color: $color-gray;
        padding: 10px 60px;
        overflow:hidden;
        display:-webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 5;
      }
    }

    .block3-swiper-button-next,
    .block3-swiper-button-prev {
      --swiper-theme-color: #f97e2b;
    }
  }
}

@media screen and (max-width: 600px) {
  .home-block3 {
    padding: 60px 0 120px 0;

    .block-title {
      margin-bottom: 70px;
    }

    .swiper-home-block3 {
      width: 95%;
    }
  }
}
</style>
