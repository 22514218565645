<template>
    <div class="recommend-block block">
        <div class="block-title">
            <div class="dot" />
            {{ $t('invention.recommend.title') }}
        </div>
        <div class="sub-block">
            <div class="swiper swiper-invention">
                <div class="swiper-wrapper">
                    <!-- Slides -->
                    <div
                        v-for="(item, index) in recommend"
                        :key="index"
                        class="swiper-slide"
                    >
                        <div class="content">
                            <div class="text item-name">
                                {{ item.name }}
                            </div>
                            <div class="text item-text">
                                {{ item.content }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="swiper-button-prev invention-swiper-button-prev" />
                <div class="swiper-button-next invention-swiper-button-next" />
            </div>
        </div>
    </div>
</template>

<script>
import Swiper, { Navigation } from 'swiper';
import '@/assets/css/swiper-bundle.min.css';

Swiper.use([Navigation]);

export default {
    components: {},
    props: {
        recommend: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
        };
    },
    computed: {},
    created() {

    },
    mounted() {
        this.initSwiper();
    },
    methods: {
        initSwiper() {
            let slidesPerView = 2;
            const windowWidth = this.windowInnerWidth();

            if (windowWidth <= 960) {
                slidesPerView = 1;
            }

            // eslint-disable-next-line no-new
            new Swiper('.swiper-invention', {
                loop: true,
                slidesPerView,
                spaceBetween: 0,
                navigation: {
                    nextEl: '.invention-swiper-button-next',
                    prevEl: '.invention-swiper-button-prev',
                },
                autoplay: false,
            });
        },
        windowInnerWidth() {
            return window.innerWidth;
        },
    },
};
</script>

<style lang="scss" scoped>
.recommend-block {
  .swiper-wrapper {
    padding: 0 80px;
  }
  .swiper-slide {
    .content {
      background-image: url('../../assets/invention/recommend-bg.png');
      background-size: contain;
      background-repeat: no-repeat;
      width: 400px;
      height: 177px;
      padding: 10px 55px;
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: center;

      .text {
        font-weight: 700;
        font-size: 16px;
        line-height: 2;
        letter-spacing: 0.1em;
        color: $color-blue;
      }
      .item-name{
        font-size: 18px;
        overflow:hidden;
        display:-webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
      }
      .item-text{
        overflow:hidden;
        display:-webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
      }
    }
  }

  .invention-swiper-button-next,
  .invention-swiper-button-prev {
    --swiper-theme-color: #f97e2b;
  }
}
@media screen and (max-width: 960px) {
  .recommend-block {
    .swiper-wrapper {
      padding: 0;
    }
    .swiper-slide {
      display: flex;
      justify-content: center;
      width: 100%;
    }
  }
}

@media screen and (max-width: 600px) {
  .recommend-block {
    .swiper-slide {
      .content {
      background-image: url('../../assets/invention/recommend-bg.png');
      width: 350px;
      height: 155px;
      padding: 5px 45px;

      .text {
        font-size: 14px;
      }
    }
    }
  }
}
</style>
