var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "academy-historyItem" },
    [
      _c("Anchor", { attrs: { id: "top" } }),
      _c(
        "div",
        { staticClass: "block mb-16" },
        [
          _c("div", { staticClass: "block-title" }, [
            _c("div", { staticClass: "dot" }),
            _vm._v(" " + _vm._s(_vm.$t("academy.landing.title[3]")) + " "),
          ]),
          _c("ListPagination", {
            attrs: {
              "block-data": _vm.History,
              "label-open": false,
              "router-name": "AcademyHistoryItem",
              "show-data": _vm.showData,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }