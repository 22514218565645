var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "incubator-proposal" },
    [
      _c(
        "v-form",
        {
          ref: "form",
          model: {
            value: _vm.valid,
            callback: function ($$v) {
              _vm.valid = $$v
            },
            expression: "valid",
          },
        },
        [
          _c("div", { staticClass: "apply-form-block" }, [
            _c("div", { staticClass: "left-block" }, [
              _c("div", { staticClass: "block-title" }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("IncubatorApply.Proposal.title[0]")) + " "
                ),
              ]),
            ]),
            _c("div", { staticClass: "form-content" }, [
              _c(
                "div",
                { staticClass: "form-row" },
                [
                  _c("v-text-field", {
                    staticClass: "border-orange-input form-input",
                    attrs: {
                      outlined: "",
                      label: _vm.$t("IncubatorApply.Proposal.label[0]"),
                      placeholder: "",
                      hint: _vm.$t("IncubatorApply.Proposal.hint[0]"),
                      "persistent-hint": "",
                      rules: _vm.emptyRule,
                    },
                    model: {
                      value: _vm.form.proposal.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.proposal, "name", $$v)
                      },
                      expression: "form.proposal.name",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _c("div", { staticClass: "apply-form-block" }, [
            _c("div", { staticClass: "left-block" }, [
              _c("div", { staticClass: "block-title" }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("IncubatorApply.Proposal.title[1]")) + " "
                ),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "form-content" },
              [
                _c(
                  "v-radio-group",
                  {
                    staticClass: "radio-group",
                    attrs: { rules: _vm.emptyRule },
                    model: {
                      value: _vm.form.proposal.property,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.proposal, "property", $$v)
                      },
                      expression: "form.proposal.property",
                    },
                  },
                  [
                    _c("v-radio", {
                      attrs: { value: "臺灣團隊-孵化組", color: "#F97E2B" },
                      scopedSlots: _vm._u([
                        {
                          key: "label",
                          fn: function () {
                            return [
                              _c("div", { staticClass: "radio-label" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("IncubatorApply.Proposal.radio[0]")
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    }),
                    _c("div", { staticClass: "radio-content" }, [
                      _c("ol", [
                        _c("li", [
                          _vm._v(
                            _vm._s(_vm.$t("IncubatorApply.Proposal.content[0]"))
                          ),
                        ]),
                        _c("li", [
                          _vm._v(
                            _vm._s(_vm.$t("IncubatorApply.Proposal.content[1]"))
                          ),
                        ]),
                        _c("li", [
                          _vm._v(
                            _vm._s(_vm.$t("IncubatorApply.Proposal.content[2]"))
                          ),
                        ]),
                      ]),
                    ]),
                    _c("v-radio", {
                      attrs: { value: "臺灣團隊-加速組", color: "#F97E2B" },
                      scopedSlots: _vm._u([
                        {
                          key: "label",
                          fn: function () {
                            return [
                              _c("div", { staticClass: "radio-label" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("IncubatorApply.Proposal.radio[1]")
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    }),
                    _c("div", { staticClass: "radio-content" }, [
                      _c("ol", [
                        _c("li", [
                          _vm._v(
                            _vm._s(_vm.$t("IncubatorApply.Proposal.content[3]"))
                          ),
                        ]),
                        _c("li", [
                          _vm._v(
                            _vm._s(_vm.$t("IncubatorApply.Proposal.content[4]"))
                          ),
                        ]),
                        _c("li", [
                          _vm._v(
                            _vm._s(_vm.$t("IncubatorApply.Proposal.content[5]"))
                          ),
                        ]),
                      ]),
                    ]),
                    _c("v-radio", {
                      attrs: { value: "亞太地區團隊", color: "#F97E2B" },
                      scopedSlots: _vm._u([
                        {
                          key: "label",
                          fn: function () {
                            return [
                              _c("div", { staticClass: "radio-label" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("IncubatorApply.Proposal.radio[2]")
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    }),
                    _c("div", { staticClass: "radio-content" }, [
                      _c("ol", [
                        _c("li", [
                          _vm._v(
                            _vm._s(_vm.$t("IncubatorApply.Proposal.content[6]"))
                          ),
                        ]),
                        _c("li", [
                          _vm._v(
                            _vm._s(_vm.$t("IncubatorApply.Proposal.content[7]"))
                          ),
                        ]),
                        _c("li", [
                          _vm._v(
                            _vm._s(_vm.$t("IncubatorApply.Proposal.content[8]"))
                          ),
                        ]),
                        _c("li", [
                          _vm._v(
                            _vm._s(_vm.$t("IncubatorApply.Proposal.content[9]"))
                          ),
                        ]),
                      ]),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "apply-form-block" }, [
            _c("div", { staticClass: "left-block" }, [
              _c("div", { staticClass: "block-title" }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("IncubatorApply.Proposal.title[2]")) + " "
                ),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "form-content" },
              [
                _c("v-textarea", {
                  attrs: {
                    rows: "10",
                    counter: "500",
                    label: _vm.$t("IncubatorApply.Proposal.label[1]"),
                    "no-resize": "",
                    rules: _vm.abstractRule,
                    outlined: "",
                    placeholder: "",
                    hint: _vm.$t("IncubatorApply.Proposal.hint[1]"),
                    "persistent-hint": "",
                  },
                  model: {
                    value: _vm.form.proposal.abstract,
                    callback: function ($$v) {
                      _vm.$set(_vm.form.proposal, "abstract", $$v)
                    },
                    expression: "form.proposal.abstract",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "apply-form-block" }, [
            _c("div", { staticClass: "left-block" }, [
              _c("div", { staticClass: "block-title" }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("IncubatorApply.Proposal.title[3]")) + " "
                ),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "form-content" },
              [
                _c(
                  "v-radio-group",
                  {
                    staticClass: "radio-group",
                    attrs: { rules: _vm.emptyRule },
                    model: {
                      value: _vm.form.proposal.issue,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.proposal, "issue", $$v)
                      },
                      expression: "form.proposal.issue",
                    },
                  },
                  [
                    _c("v-radio", {
                      attrs: { value: "慈善創新", color: "#F97E2B" },
                      scopedSlots: _vm._u([
                        {
                          key: "label",
                          fn: function () {
                            return [
                              _c("div", { staticClass: "radio-label" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("IncubatorApply.Proposal.radio[3]")
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    }),
                    _c("v-radio", {
                      attrs: { value: "醫療照護", color: "#F97E2B" },
                      scopedSlots: _vm._u([
                        {
                          key: "label",
                          fn: function () {
                            return [
                              _c("div", { staticClass: "radio-label" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("IncubatorApply.Proposal.radio[4]")
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    }),
                    _c("v-radio", {
                      attrs: { value: "教育創新", color: "#F97E2B" },
                      scopedSlots: _vm._u([
                        {
                          key: "label",
                          fn: function () {
                            return [
                              _c("div", { staticClass: "radio-label" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("IncubatorApply.Proposal.radio[5]")
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    }),
                    _c("v-radio", {
                      attrs: { value: "環境保護", color: "#F97E2B" },
                      scopedSlots: _vm._u([
                        {
                          key: "label",
                          fn: function () {
                            return [
                              _c("div", { staticClass: "radio-label" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("IncubatorApply.Proposal.radio[6]")
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    }),
                    _c("v-radio", {
                      attrs: { value: "循環經濟", color: "#F97E2B" },
                      scopedSlots: _vm._u([
                        {
                          key: "label",
                          fn: function () {
                            return [
                              _c("div", { staticClass: "radio-label" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("IncubatorApply.Proposal.radio[7]")
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    }),
                    _c("v-radio", {
                      attrs: { value: "青銀共創", color: "#F97E2B" },
                      scopedSlots: _vm._u([
                        {
                          key: "label",
                          fn: function () {
                            return [
                              _c("div", { staticClass: "radio-label" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("IncubatorApply.Proposal.radio[8]")
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    }),
                    _c("v-radio", {
                      attrs: { value: "食糧供應創新", color: "#F97E2B" },
                      scopedSlots: _vm._u([
                        {
                          key: "label",
                          fn: function () {
                            return [
                              _c("div", { staticClass: "radio-label" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("IncubatorApply.Proposal.radio[9]")
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    }),
                    _c("v-radio", {
                      attrs: { value: "災害防救創新", color: "#F97E2B" },
                      scopedSlots: _vm._u([
                        {
                          key: "label",
                          fn: function () {
                            return [
                              _c("div", { staticClass: "radio-label" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "IncubatorApply.Proposal.radio[10]"
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    }),
                    _c("v-radio", {
                      attrs: { value: "地方創生/社區營造", color: "#F97E2B" },
                      scopedSlots: _vm._u([
                        {
                          key: "label",
                          fn: function () {
                            return [
                              _c("div", { staticClass: "radio-label" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "IncubatorApply.Proposal.radio[11]"
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "apply-form-block team-image-block" }, [
            _c("div", { staticClass: "left-block" }, [
              _c("div", { staticClass: "block-title" }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("IncubatorApply.Proposal.title[4]")) + " "
                ),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "form-content" },
              [
                _c("div", { staticClass: "label" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("IncubatorApply.Proposal.label[2]")) +
                      " "
                  ),
                ]),
                _c(
                  "v-card",
                  {
                    staticClass: "preview-card rounded-lg",
                    attrs: { elevation: "4" },
                  },
                  [
                    _c(
                      "v-tooltip",
                      {
                        attrs: { top: "", "nudge-top": "-90" },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function (ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-img",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        staticClass: "og-image",
                                        attrs: {
                                          id: "img-file",
                                          height: "300px",
                                          src: _vm.$static(
                                            _vm.form.proposal.img
                                          ),
                                          contain: _vm.loading,
                                        },
                                      },
                                      "v-img",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _c("v-file-input", {
                                      staticClass: "upload pa-0 ma-0",
                                      attrs: {
                                        accept: "image/png, image/jpeg",
                                        "hide-input": "",
                                        "truncate-length": "0",
                                        "prepend-icon": "mdi-upload",
                                        dark: "",
                                        rules: [_vm.imgRule()],
                                      },
                                      on: { change: _vm.handleImageUpload },
                                      model: {
                                        value: _vm.proposalImg,
                                        callback: function ($$v) {
                                          _vm.proposalImg = $$v
                                        },
                                        expression: "proposalImg",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.$t("IncubatorApply.Proposal.image"))
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "apply-form-block" }, [
            _c("div", { staticClass: "left-block" }, [
              _c("div", { staticClass: "block-title" }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("IncubatorApply.Proposal.title[5]")) + " "
                ),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "form-content" },
              [
                _c("v-file-input", {
                  staticClass: "border-orange-input form-input",
                  attrs: {
                    accept: ".pdf",
                    label: _vm.$t("IncubatorApply.Proposal.label[3]"),
                    outlined: "",
                    placeholder: "",
                    hint: _vm.$t("IncubatorApply.Proposal.hint[2]"),
                    "persistent-hint": "",
                    rules: [_vm.pdfRule()],
                  },
                  on: {
                    change: function (file) {
                      return _vm.handlePdfChange(
                        "form.proposal.pdfFile",
                        file,
                        _vm.form.proposal.tmpPdf
                      )
                    },
                  },
                  model: {
                    value: _vm.tmpPdf,
                    callback: function ($$v) {
                      _vm.tmpPdf = $$v
                    },
                    expression: "tmpPdf",
                  },
                }),
                _vm.form.proposal.pdfFile
                  ? _c("div", { staticClass: "preivew" }, [
                      _c(
                        "a",
                        {
                          staticClass: "preview-link",
                          attrs: {
                            href: _vm.$static(_vm.form.proposal.pdfFile),
                            target: "_blank",
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("IncubatorApply.Proposal.link")))]
                      ),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ]
      ),
      _c("div", { staticClass: "apply-form-block" }, [
        _c("div", { staticClass: "left-block" }),
        _c("div", { staticClass: "form-content" }, [
          _c("div", { staticClass: "actions" }, [
            _c(
              "div",
              { staticClass: "btn white-btn", on: { click: _vm.prevStep } },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("IncubatorApply.Proposal.button[0]")) +
                    " "
                ),
              ]
            ),
            _c(
              "div",
              { staticClass: "btn blue-btn", on: { click: _vm.save } },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("IncubatorApply.Proposal.button[1]")) +
                    " "
                ),
              ]
            ),
            _c(
              "div",
              { staticClass: "btn orange-btn", on: { click: _vm.nextStep } },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("IncubatorApply.Proposal.button[2]")) +
                    " "
                ),
              ]
            ),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }