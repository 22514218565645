var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "policy-terms page" }, [
    _c("div", { staticClass: "web-content" }, [
      _c("div", { staticClass: "policy-title-box" }, [
        _c("div", { staticClass: "policy-title" }, [
          _vm._v(" " + _vm._s(_vm.$t("PolicyAndTerms.title")) + " "),
        ]),
      ]),
      _c("div", { staticClass: "policy-content" }, [
        _c("div", { staticClass: "sub-title" }, [
          _vm._v(" " + _vm._s(_vm.$t("PolicyAndTerms.subtitle")) + " "),
        ]),
        _c("br"),
        _c("div", { staticClass: "text" }, [
          _vm._v(" " + _vm._s(_vm.$t("PolicyAndTerms.text[0]")) + " "),
          _c("span", { staticClass: "color-orange" }, [
            _vm._v(" " + _vm._s(_vm.$t("PolicyAndTerms.text[1]")) + " "),
          ]),
        ]),
        _c("br"),
        _c("br"),
        _c("div", { staticClass: "text" }, [
          _c("div", [_vm._v(_vm._s(_vm.$t("PolicyAndTerms.text[2]")))]),
          _c("ol", [
            _c("li", [
              _vm._v(" " + _vm._s(_vm.$t("PolicyAndTerms.text[3]")) + " "),
            ]),
            _c("li", [
              _vm._v(" " + _vm._s(_vm.$t("PolicyAndTerms.text[4]")) + " "),
            ]),
            _c("li", [
              _vm._v(" " + _vm._s(_vm.$t("PolicyAndTerms.text[5]")) + " "),
            ]),
            _c("li", [
              _vm._v(" " + _vm._s(_vm.$t("PolicyAndTerms.text[6]")) + " "),
            ]),
            _c("li", [
              _vm._v(" " + _vm._s(_vm.$t("PolicyAndTerms.text[7]")) + " "),
            ]),
            _c("li", [_vm._v(_vm._s(_vm.$t("PolicyAndTerms.text[8]")))]),
            _c("li", [
              _vm._v(" " + _vm._s(_vm.$t("PolicyAndTerms.text[9]")) + " "),
            ]),
            _c("li", [_vm._v(_vm._s(_vm.$t("PolicyAndTerms.text[10]")))]),
            _c("li", [
              _vm._v(" " + _vm._s(_vm.$t("PolicyAndTerms.text[11]")) + " "),
            ]),
            _c("li", [
              _vm._v(" " + _vm._s(_vm.$t("PolicyAndTerms.text[12]")) + " "),
            ]),
          ]),
        ]),
        _c("br"),
        _c("div", { staticClass: "text" }, [
          _c("div", [_vm._v(_vm._s(_vm.$t("PolicyAndTerms.text[13]")))]),
          _vm._v(" " + _vm._s(_vm.$t("PolicyAndTerms.text[14]")) + " "),
        ]),
        _c("br"),
        _c("div", { staticClass: "text" }, [
          _c("div", [_vm._v(_vm._s(_vm.$t("PolicyAndTerms.text[15]")))]),
          _c("ol", [
            _c("li", [
              _vm._v(" " + _vm._s(_vm.$t("PolicyAndTerms.text[16]")) + " "),
            ]),
            _c("li", [
              _vm._v(" " + _vm._s(_vm.$t("PolicyAndTerms.text[17]")) + " "),
            ]),
          ]),
        ]),
        _c("br"),
        _c("div", { staticClass: "text" }, [
          _c("div", [_vm._v(_vm._s(_vm.$t("PolicyAndTerms.text[18]")))]),
          _c("ol", [
            _c("li", [
              _vm._v(" " + _vm._s(_vm.$t("PolicyAndTerms.text[19]")) + " "),
            ]),
            _c("li", [
              _vm._v(" " + _vm._s(_vm.$t("PolicyAndTerms.text[20]")) + " "),
            ]),
          ]),
        ]),
        _c("br"),
        _c("div", { staticClass: "text" }, [
          _c("div", [_vm._v(_vm._s(_vm.$t("PolicyAndTerms.text[21]")))]),
          _vm._v(" " + _vm._s(_vm.$t("PolicyAndTerms.text[22]")) + " "),
        ]),
        _c("br"),
        _c("div", { staticClass: "text" }, [
          _c("div", [_vm._v(_vm._s(_vm.$t("PolicyAndTerms.text[23]")))]),
          _vm._v(" " + _vm._s(_vm.$t("PolicyAndTerms.text[24]")) + " "),
        ]),
        _c("br"),
        _c("div", { staticClass: "text" }, [
          _c("div", [_vm._v(_vm._s(_vm.$t("PolicyAndTerms.text[25]")))]),
          _c("ol", [
            _c("li", [
              _vm._v(" " + _vm._s(_vm.$t("PolicyAndTerms.text[26]")) + " "),
            ]),
            _c("li", [_vm._v(_vm._s(_vm.$t("PolicyAndTerms.text[27]")))]),
            _c("li", [_vm._v(_vm._s(_vm.$t("PolicyAndTerms.text[28]")))]),
            _c("li", [_vm._v(_vm._s(_vm.$t("PolicyAndTerms.text[29]")))]),
            _c("li", [
              _vm._v(" " + _vm._s(_vm.$t("PolicyAndTerms.text[30]")) + " "),
            ]),
          ]),
        ]),
        _c("br"),
        _c("div", { staticClass: "text" }, [
          _c("div", [_vm._v(_vm._s(_vm.$t("PolicyAndTerms.text[31]")))]),
          _vm._v(" " + _vm._s(_vm.$t("PolicyAndTerms.text[32]")) + " "),
        ]),
        _c("br"),
        _c("div", { staticClass: "text" }, [
          _vm._v(" " + _vm._s(_vm.$t("PolicyAndTerms.text[33]")) + " "),
        ]),
        _c("br"),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }