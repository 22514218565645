var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "study-history-item mb-16" },
    [
      _c("div", { staticClass: "block" }, [
        _c("div", { staticClass: "block-title" }, [
          _c("div", { staticClass: "dot" }),
          _vm._v(" " + _vm._s(_vm.item.title) + " "),
        ]),
      ]),
      _c("PDFView", { attrs: { url: _vm.item.pdf } }),
      _c("div", {
        staticClass: "item-text",
        domProps: { innerHTML: _vm._s(_vm.item.content) },
      }),
      _c("div", { staticClass: "sub-block" }, [
        _c("div", { staticClass: "sub-title" }, [
          _vm._v(" " + _vm._s(_vm.$t("study.landing.download")) + " "),
        ]),
        _c("a", { attrs: { href: _vm.item.pdf } }, [
          _vm._v(_vm._s(_vm.item.title) + ".pdf"),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }