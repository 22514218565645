var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "incubator-confirm" },
    [
      _c("v-form", [
        _c("div", { staticClass: "apply-form-block" }, [
          _c("div", { staticClass: "left-block" }, [
            _c("div", { staticClass: "block-title" }, [
              _vm._v(
                " " + _vm._s(_vm.$t("IncubatorApply.Confirm.title[0]")) + " "
              ),
            ]),
          ]),
          _c("div", { staticClass: "form-content" }, [
            _c(
              "div",
              { staticClass: "form-row" },
              [
                _c("v-text-field", {
                  staticClass: "border-orange-input form-input",
                  attrs: {
                    outlined: "",
                    label: _vm.$t("IncubatorApply.Confirm.label[0]"),
                    placeholder: "",
                    hint: _vm.$t("IncubatorApply.Confirm.hint[0]"),
                    "persistent-hint": "",
                    readonly: "",
                  },
                  model: {
                    value: _vm.form.team.organizeName,
                    callback: function ($$v) {
                      _vm.$set(_vm.form.team, "organizeName", $$v)
                    },
                    expression: "form.team.organizeName",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
        _c("div", { staticClass: "apply-form-block" }, [
          _c("div", { staticClass: "left-block" }, [
            _c("div", { staticClass: "block-title" }, [
              _vm._v(
                " " + _vm._s(_vm.$t("IncubatorApply.Confirm.title[1]")) + " "
              ),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "form-content" },
            [
              _c(
                "v-radio-group",
                {
                  staticClass: "radio-group",
                  attrs: { readonly: "" },
                  model: {
                    value: _vm.form.team.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.form.team, "type", $$v)
                    },
                    expression: "form.team.type",
                  },
                },
                [
                  _c("v-radio", {
                    attrs: {
                      value: "非正式立案組織（個人團隊）",
                      color: "#F97E2B",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function () {
                          return [
                            _c("div", { staticClass: "radio-label" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("IncubatorApply.Confirm.radio[0]")
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                  _c("v-radio", {
                    attrs: { value: "非營利組織", color: "#F97E2B" },
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function () {
                          return [
                            _c("div", { staticClass: "radio-label" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("IncubatorApply.Confirm.radio[1]")
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                  _c("v-radio", {
                    attrs: { value: "營利組織", color: "#F97E2B" },
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function () {
                          return [
                            _c("div", { staticClass: "radio-label" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("IncubatorApply.Confirm.radio[2]")
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "apply-form-block" }, [
          _c("div", { staticClass: "left-block" }, [
            _c("div", { staticClass: "block-title" }, [
              _vm._v(
                " " + _vm._s(_vm.$t("IncubatorApply.Confirm.title[2]")) + " "
              ),
            ]),
          ]),
          _c("div", { staticClass: "form-content" }, [
            _c(
              "div",
              { staticClass: "form-row" },
              [
                _c("v-select", {
                  staticClass: "border-orange-input form-input",
                  attrs: {
                    items: _vm.countries,
                    outlined: "",
                    label: _vm.$t("IncubatorApply.Confirm.label[1]"),
                    readonly: "",
                  },
                  model: {
                    value: _vm.form.team.country,
                    callback: function ($$v) {
                      _vm.$set(_vm.form.team, "country", $$v)
                    },
                    expression: "form.team.country",
                  },
                }),
                _vm.form.team.country !== "臺灣"
                  ? _c("v-text-field", {
                      staticClass: "border-orange-input form-input",
                      attrs: {
                        outlined: "",
                        label: _vm.$t("IncubatorApply.Confirm.label[2]"),
                        placeholder: "",
                        readonly: "",
                      },
                      model: {
                        value: _vm.form.team.city,
                        callback: function ($$v) {
                          _vm.$set(_vm.form.team, "city", $$v)
                        },
                        expression: "form.team.city",
                      },
                    })
                  : _c("v-select", {
                      staticClass: "border-orange-input form-input",
                      attrs: {
                        items: _vm.cities,
                        outlined: "",
                        label: _vm.$t("IncubatorApply.Confirm.label[2]"),
                        readonly: "",
                      },
                      model: {
                        value: _vm.form.team.city,
                        callback: function ($$v) {
                          _vm.$set(_vm.form.team, "city", $$v)
                        },
                        expression: "form.team.city",
                      },
                    }),
              ],
              1
            ),
          ]),
        ]),
        _c("div", { staticClass: "apply-form-block" }, [
          _c("div", { staticClass: "left-block" }, [
            _c("div", { staticClass: "block-title" }, [
              _vm._v(
                " " + _vm._s(_vm.$t("IncubatorApply.Confirm.title[3]")) + " "
              ),
            ]),
          ]),
          _c("div", { staticClass: "form-content" }, [
            _c(
              "div",
              { staticClass: "form-row" },
              [
                _c("v-text-field", {
                  staticClass: "border-orange-input form-input",
                  attrs: {
                    outlined: "",
                    label: _vm.$t("IncubatorApply.Confirm.label[3]"),
                    placeholder: "",
                    hint: _vm.$t("IncubatorApply.Confirm.hint[1]"),
                    "persistent-hint": "",
                    readonly: "",
                  },
                  model: {
                    value: _vm.form.team.taxId,
                    callback: function ($$v) {
                      _vm.$set(_vm.form.team, "taxId", $$v)
                    },
                    expression: "form.team.taxId",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
        _c("div", { staticClass: "apply-form-block" }, [
          _c("div", { staticClass: "left-block" }, [
            _c("div", { staticClass: "block-title" }, [
              _vm._v(
                " " + _vm._s(_vm.$t("IncubatorApply.Confirm.title[4]")) + " "
              ),
            ]),
          ]),
          _c("div", { staticClass: "form-content" }, [
            _c(
              "div",
              { staticClass: "form-row" },
              [
                _c("v-text-field", {
                  staticClass: "border-orange-input form-input",
                  attrs: {
                    outlined: "",
                    label: _vm.$t("IncubatorApply.Confirm.label[4]"),
                    placeholder: "",
                    readonly: "",
                  },
                  model: {
                    value: _vm.form.team.name,
                    callback: function ($$v) {
                      _vm.$set(_vm.form.team, "name", $$v)
                    },
                    expression: "form.team.name",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
        _c("div", { staticClass: "apply-form-block" }, [
          _c("div", { staticClass: "left-block" }, [
            _c("div", { staticClass: "block-title" }, [
              _vm._v(
                " " + _vm._s(_vm.$t("IncubatorApply.Confirm.title[5]")) + " "
              ),
            ]),
            _c("div", { staticClass: "tips" }, [
              _vm._v(" " + _vm._s(_vm.$t("IncubatorApply.TeamData.tips[0]"))),
              _c("br"),
              _vm._v(_vm._s(_vm.$t("IncubatorApply.TeamData.tips[1]")) + " "),
            ]),
          ]),
          _c("div", { staticClass: "form-content" }, [
            _c(
              "div",
              { staticClass: "member-list" },
              _vm._l(_vm.form.team.members, function (item, index) {
                return _c("div", { key: index, staticClass: "member-item" }, [
                  _c(
                    "div",
                    { staticClass: "form-row" },
                    [
                      _c("v-text-field", {
                        staticClass: "border-orange-input form-input",
                        attrs: {
                          outlined: "",
                          label: _vm.memberNameLabel(index),
                          placeholder: "",
                          readonly: "",
                        },
                        model: {
                          value: item.name,
                          callback: function ($$v) {
                            _vm.$set(item, "name", $$v)
                          },
                          expression: "item.name",
                        },
                      }),
                      _c("v-text-field", {
                        staticClass: "border-orange-input form-input",
                        attrs: {
                          outlined: "",
                          label: _vm.$t("IncubatorApply.Confirm.label[7]"),
                          placeholder: "",
                          readonly: "",
                        },
                        model: {
                          value: item.phone,
                          callback: function ($$v) {
                            _vm.$set(item, "phone", $$v)
                          },
                          expression: "item.phone",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "form-row" },
                    [
                      _c("v-text-field", {
                        staticClass: "border-orange-input form-input",
                        attrs: {
                          outlined: "",
                          label: _vm.$t("IncubatorApply.Confirm.label[8]"),
                          placeholder: "",
                          readonly: "",
                        },
                        model: {
                          value: item.department,
                          callback: function ($$v) {
                            _vm.$set(item, "department", $$v)
                          },
                          expression: "item.department",
                        },
                      }),
                      _c("v-text-field", {
                        staticClass: "border-orange-input form-input",
                        attrs: {
                          outlined: "",
                          label: _vm.$t("IncubatorApply.Confirm.label[9]"),
                          placeholder: "",
                          readonly: "",
                        },
                        model: {
                          value: item.birthday,
                          callback: function ($$v) {
                            _vm.$set(item, "birthday", $$v)
                          },
                          expression: "item.birthday",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "form-row" },
                    [
                      _c("v-text-field", {
                        staticClass: "border-orange-input",
                        attrs: {
                          outlined: "",
                          label: _vm.$t("IncubatorApply.Confirm.label[10]"),
                          placeholder: "",
                          readonly: "",
                        },
                        model: {
                          value: item.email,
                          callback: function ($$v) {
                            _vm.$set(item, "email", $$v)
                          },
                          expression: "item.email",
                        },
                      }),
                    ],
                    1
                  ),
                ])
              }),
              0
            ),
          ]),
        ]),
        _c("div", { staticClass: "apply-form-block" }, [
          _c("div", { staticClass: "left-block" }, [
            _c("div", { staticClass: "block-title" }, [
              _vm._v(
                " " + _vm._s(_vm.$t("IncubatorApply.TeamData.title[6]")) + " "
              ),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "form-content" },
            [
              _c("v-checkbox", {
                staticClass: "checkbox",
                attrs: {
                  label: _vm.$t("IncubatorApply.TeamData.checkbox[0][0]"),
                  value: "FUN大視野官網",
                  color: "#898989",
                  readonly: "",
                },
                model: {
                  value: _vm.form.team.refers,
                  callback: function ($$v) {
                    _vm.$set(_vm.form.team, "refers", $$v)
                  },
                  expression: "form.team.refers",
                },
              }),
              _c("v-checkbox", {
                staticClass: "checkbox",
                attrs: {
                  label: _vm.$t("IncubatorApply.TeamData.checkbox[0][1]"),
                  value: "FUN大視野FACEBOOK",
                  color: "#898989",
                  readonly: "",
                },
                model: {
                  value: _vm.form.team.refers,
                  callback: function ($$v) {
                    _vm.$set(_vm.form.team, "refers", $$v)
                  },
                  expression: "form.team.refers",
                },
              }),
              _c("v-checkbox", {
                staticClass: "checkbox",
                attrs: {
                  label: _vm.$t("IncubatorApply.TeamData.checkbox[0][2]"),
                  value: "FUN大視野INSTAGRAM",
                  color: "#898989",
                  readonly: "",
                },
                model: {
                  value: _vm.form.team.refers,
                  callback: function ($$v) {
                    _vm.$set(_vm.form.team, "refers", $$v)
                  },
                  expression: "form.team.refers",
                },
              }),
              _c("v-checkbox", {
                staticClass: "checkbox",
                attrs: {
                  label: _vm.$t("IncubatorApply.TeamData.checkbox[0][3]"),
                  value: "FUN大視野電子報",
                  color: "#898989",
                  readonly: "",
                },
                model: {
                  value: _vm.form.team.refers,
                  callback: function ($$v) {
                    _vm.$set(_vm.form.team, "refers", $$v)
                  },
                  expression: "form.team.refers",
                },
              }),
              _c("v-checkbox", {
                staticClass: "checkbox",
                attrs: {
                  label: _vm.$t("IncubatorApply.TeamData.checkbox[0][4]"),
                  value: "學校活動公告(學校官網、海報、育成中心等)",
                  color: "#898989",
                  readonly: "",
                },
                model: {
                  value: _vm.form.team.refers,
                  callback: function ($$v) {
                    _vm.$set(_vm.form.team, "refers", $$v)
                  },
                  expression: "form.team.refers",
                },
              }),
              _c("v-checkbox", {
                staticClass: "checkbox",
                attrs: {
                  label: _vm.$t("IncubatorApply.TeamData.checkbox[0][5]"),
                  value: "縣市政府宣傳平台",
                  color: "#898989",
                  readonly: "",
                },
                model: {
                  value: _vm.form.team.refers,
                  callback: function ($$v) {
                    _vm.$set(_vm.form.team, "refers", $$v)
                  },
                  expression: "form.team.refers",
                },
              }),
              _c("v-checkbox", {
                staticClass: "checkbox",
                attrs: {
                  label: _vm.$t("IncubatorApply.TeamData.checkbox[0][6]"),
                  value: "社會創新平台官網",
                  color: "#898989",
                  readonly: "",
                },
                model: {
                  value: _vm.form.team.refers,
                  callback: function ($$v) {
                    _vm.$set(_vm.form.team, "refers", $$v)
                  },
                  expression: "form.team.refers",
                },
              }),
              _c("v-checkbox", {
                staticClass: "checkbox",
                attrs: {
                  label: _vm.$t("IncubatorApply.TeamData.checkbox[0][7]"),
                  value: "獎金獵人平台",
                  color: "#898989",
                  readonly: "",
                },
                model: {
                  value: _vm.form.team.refers,
                  callback: function ($$v) {
                    _vm.$set(_vm.form.team, "refers", $$v)
                  },
                  expression: "form.team.refers",
                },
              }),
              _c(
                "div",
                { staticStyle: { display: "flex", "flex-wrap": "wrap" } },
                [
                  _c("v-checkbox", {
                    staticClass: "checkbox",
                    attrs: {
                      label: _vm.$t("IncubatorApply.TeamData.checkbox[0][8]"),
                      value: "歷屆團隊推薦，團隊名:",
                      color: "#898989",
                      readonly: "",
                    },
                    model: {
                      value: _vm.form.team.refers,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.team, "refers", $$v)
                      },
                      expression: "form.team.refers",
                    },
                  }),
                  _c("v-text-field", {
                    staticClass:
                      "checkbox-input border-orange-input form-input",
                    attrs: { readonly: "" },
                    model: {
                      value: _vm.refers1,
                      callback: function ($$v) {
                        _vm.refers1 = $$v
                      },
                      expression: "refers1",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { display: "flex", "flex-wrap": "wrap" } },
                [
                  _c("v-checkbox", {
                    staticClass: "checkbox",
                    attrs: {
                      label: _vm.$t("IncubatorApply.TeamData.checkbox[0][9]"),
                      value: "親朋好友推薦，推薦人:",
                      color: "#898989",
                      readonly: "",
                    },
                    model: {
                      value: _vm.form.team.refers,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.team, "refers", $$v)
                      },
                      expression: "form.team.refers",
                    },
                  }),
                  _c("v-text-field", {
                    staticClass:
                      "checkbox-input border-orange-input form-input",
                    attrs: { readonly: "" },
                    model: {
                      value: _vm.refers2,
                      callback: function ($$v) {
                        _vm.refers2 = $$v
                      },
                      expression: "refers2",
                    },
                  }),
                ],
                1
              ),
              _c("v-checkbox", {
                staticClass: "checkbox",
                attrs: {
                  label: _vm.$t("IncubatorApply.TeamData.checkbox[0][10]"),
                  value: "曾經提案過",
                  color: "#898989",
                  readonly: "",
                },
                model: {
                  value: _vm.form.team.refers,
                  callback: function ($$v) {
                    _vm.$set(_vm.form.team, "refers", $$v)
                  },
                  expression: "form.team.refers",
                },
              }),
              _c("v-checkbox", {
                staticClass: "checkbox",
                attrs: {
                  label: _vm.$t("IncubatorApply.TeamData.checkbox[0][11]"),
                  value: "原本就知道",
                  color: "#898989",
                  readonly: "",
                },
                model: {
                  value: _vm.form.team.refers,
                  callback: function ($$v) {
                    _vm.$set(_vm.form.team, "refers", $$v)
                  },
                  expression: "form.team.refers",
                },
              }),
              _c("v-checkbox", {
                staticClass: "checkbox",
                attrs: {
                  label: _vm.$t("IncubatorApply.TeamData.checkbox[0][12]"),
                  value: "IMPACT HUB TAIPEI宣傳平台(官網、電子報、口頭邀約等)",
                  color: "#898989",
                  readonly: "",
                },
                model: {
                  value: _vm.form.team.refers,
                  callback: function ($$v) {
                    _vm.$set(_vm.form.team, "refers", $$v)
                  },
                  expression: "form.team.refers",
                },
              }),
              _c("v-checkbox", {
                staticClass: "checkbox",
                attrs: {
                  label: _vm.$t("IncubatorApply.TeamData.checkbox[0][13]"),
                  value: "慈濟基金會宣傳平台(官網、IG、LINE、大愛台等)",
                  color: "#898989",
                  readonly: "",
                },
                model: {
                  value: _vm.form.team.refers,
                  callback: function ($$v) {
                    _vm.$set(_vm.form.team, "refers", $$v)
                  },
                  expression: "form.team.refers",
                },
              }),
              _c(
                "div",
                { staticStyle: { display: "flex", "flex-wrap": "wrap" } },
                [
                  _c("v-checkbox", {
                    staticClass: "checkbox",
                    attrs: {
                      label: _vm.$t("IncubatorApply.TeamData.checkbox[0][14]"),
                      value: "媒體宣傳平台，平台名:",
                      color: "#898989",
                      readonly: "",
                    },
                    model: {
                      value: _vm.form.team.refers,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.team, "refers", $$v)
                      },
                      expression: "form.team.refers",
                    },
                  }),
                  _c("v-text-field", {
                    staticClass:
                      "checkbox-input border-orange-input form-input",
                    attrs: { readonly: "" },
                    model: {
                      value: _vm.refers3,
                      callback: function ($$v) {
                        _vm.refers3 = $$v
                      },
                      expression: "refers3",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { display: "flex", "flex-wrap": "wrap" } },
                [
                  _c("v-checkbox", {
                    staticClass: "checkbox",
                    attrs: {
                      label: _vm.$t("IncubatorApply.TeamData.checkbox[0][15]"),
                      value: "其他:",
                      color: "#898989",
                      readonly: "",
                    },
                    model: {
                      value: _vm.form.team.refers,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.team, "refers", $$v)
                      },
                      expression: "form.team.refers",
                    },
                  }),
                  _c("v-text-field", {
                    staticClass:
                      "checkbox-input border-orange-input form-input",
                    attrs: { readonly: "" },
                    model: {
                      value: _vm.refers4,
                      callback: function ($$v) {
                        _vm.refers4 = $$v
                      },
                      expression: "refers4",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "apply-form-block" }, [
          _c("div", { staticClass: "left-block" }, [
            _c("div", { staticClass: "block-title" }, [
              _vm._v(
                " " + _vm._s(_vm.$t("IncubatorApply.Confirm.title[6]")) + " "
              ),
            ]),
          ]),
          _c("div", { staticClass: "form-content" }, [
            _c(
              "div",
              { staticClass: "form-row" },
              [
                _c("v-text-field", {
                  staticClass: "border-orange-input form-input",
                  attrs: {
                    outlined: "",
                    label: _vm.$t("IncubatorApply.Confirm.label[11]"),
                    placeholder: "",
                    hint: _vm.$t("IncubatorApply.Confirm.hint[2]"),
                    "persistent-hint": "",
                    readonly: "",
                  },
                  model: {
                    value: _vm.form.proposal.name,
                    callback: function ($$v) {
                      _vm.$set(_vm.form.proposal, "name", $$v)
                    },
                    expression: "form.proposal.name",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
        _c("div", { staticClass: "apply-form-block" }, [
          _c("div", { staticClass: "left-block" }, [
            _c("div", { staticClass: "block-title" }, [
              _vm._v(
                " " + _vm._s(_vm.$t("IncubatorApply.Confirm.title[7]")) + " "
              ),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "form-content" },
            [
              _c(
                "v-radio-group",
                {
                  staticClass: "radio-group",
                  attrs: { readonly: "" },
                  model: {
                    value: _vm.form.proposal.property,
                    callback: function ($$v) {
                      _vm.$set(_vm.form.proposal, "property", $$v)
                    },
                    expression: "form.proposal.property",
                  },
                },
                [
                  _c("v-radio", {
                    attrs: { value: "臺灣團隊-孵化組", color: "#F97E2B" },
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function () {
                          return [
                            _c("div", { staticClass: "radio-label" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("IncubatorApply.Confirm.radio[3]")
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                  _c("div", { staticClass: "radio-content" }, [
                    _c("ol", [
                      _c("li", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("IncubatorApply.Confirm.content[0]")
                            ) +
                            " "
                        ),
                      ]),
                      _c("li", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("IncubatorApply.Confirm.content[1]")
                            ) +
                            " "
                        ),
                      ]),
                      _c("li", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("IncubatorApply.Confirm.content[2]")
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                  ]),
                  _c("v-radio", {
                    attrs: { value: "臺灣團隊-加速組", color: "#F97E2B" },
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function () {
                          return [
                            _c("div", { staticClass: "radio-label" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("IncubatorApply.Confirm.radio[4]")
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                  _c("div", { staticClass: "radio-content" }, [
                    _c("ol", [
                      _c("li", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("IncubatorApply.Confirm.content[3]")
                            ) +
                            " "
                        ),
                      ]),
                      _c("li", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("IncubatorApply.Confirm.content[4]")
                            ) +
                            " "
                        ),
                      ]),
                      _c("li", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("IncubatorApply.Confirm.content[5]")
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                  ]),
                  _c("v-radio", {
                    attrs: { value: "亞太地區團隊", color: "#F97E2B" },
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function () {
                          return [
                            _c("div", { staticClass: "radio-label" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("IncubatorApply.Confirm.radio[5]")
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                  _c("div", { staticClass: "radio-content" }, [
                    _c("ol", [
                      _c("li", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("IncubatorApply.Confirm.content[6]")
                            ) +
                            " "
                        ),
                      ]),
                      _c("li", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("IncubatorApply.Confirm.content[7]")
                            ) +
                            " "
                        ),
                      ]),
                      _c("li", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("IncubatorApply.Confirm.content[8]")
                            ) +
                            " "
                        ),
                      ]),
                      _c("li", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("IncubatorApply.Confirm.content[9]")
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "apply-form-block" }, [
          _c("div", { staticClass: "left-block" }, [
            _c("div", { staticClass: "block-title" }, [
              _vm._v(
                " " + _vm._s(_vm.$t("IncubatorApply.Confirm.title[8]")) + " "
              ),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "form-content" },
            [
              _c("v-textarea", {
                attrs: {
                  rows: "10",
                  counter: "500",
                  label: _vm.$t("IncubatorApply.Confirm.label[12]"),
                  "no-resize": "",
                  outlined: "",
                  placeholder: "",
                  hint: _vm.$t("IncubatorApply.Confirm.hint[3]"),
                  "persistent-hint": "",
                  readonly: "",
                },
                model: {
                  value: _vm.form.proposal.abstract,
                  callback: function ($$v) {
                    _vm.$set(_vm.form.proposal, "abstract", $$v)
                  },
                  expression: "form.proposal.abstract",
                },
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "apply-form-block" }, [
          _c("div", { staticClass: "left-block" }, [
            _c("div", { staticClass: "block-title" }, [
              _vm._v(
                " " + _vm._s(_vm.$t("IncubatorApply.Confirm.title[9]")) + " "
              ),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "form-content" },
            [
              _c(
                "v-radio-group",
                {
                  staticClass: "radio-group",
                  attrs: { readonly: "" },
                  model: {
                    value: _vm.form.proposal.issue,
                    callback: function ($$v) {
                      _vm.$set(_vm.form.proposal, "issue", $$v)
                    },
                    expression: "form.proposal.issue",
                  },
                },
                [
                  _c("v-radio", {
                    attrs: { value: "慈善創新", color: "#F97E2B" },
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function () {
                          return [
                            _c("div", { staticClass: "radio-label" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("IncubatorApply.Confirm.radio[6]")
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                  _c("v-radio", {
                    attrs: { value: "醫療照護", color: "#F97E2B" },
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function () {
                          return [
                            _c("div", { staticClass: "radio-label" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("IncubatorApply.Confirm.radio[7]")
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                  _c("v-radio", {
                    attrs: { value: "教育創新", color: "#F97E2B" },
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function () {
                          return [
                            _c("div", { staticClass: "radio-label" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("IncubatorApply.Confirm.radio[8]")
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                  _c("v-radio", {
                    attrs: { value: "環境保護", color: "#F97E2B" },
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function () {
                          return [
                            _c("div", { staticClass: "radio-label" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("IncubatorApply.Confirm.radio[9]")
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                  _c("v-radio", {
                    attrs: { value: "循環經濟", color: "#F97E2B" },
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function () {
                          return [
                            _c("div", { staticClass: "radio-label" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("IncubatorApply.Confirm.radio[10]")
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                  _c("v-radio", {
                    attrs: { value: "青銀共創", color: "#F97E2B" },
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function () {
                          return [
                            _c("div", { staticClass: "radio-label" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("IncubatorApply.Confirm.radio[11]")
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                  _c("v-radio", {
                    attrs: { value: "食糧供應創新", color: "#F97E2B" },
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function () {
                          return [
                            _c("div", { staticClass: "radio-label" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("IncubatorApply.Confirm.radio[12]")
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                  _c("v-radio", {
                    attrs: { value: "災害防救創新", color: "#F97E2B" },
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function () {
                          return [
                            _c("div", { staticClass: "radio-label" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("IncubatorApply.Confirm.radio[13]")
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                  _c("v-radio", {
                    attrs: { value: "地方創生/社區營造", color: "#F97E2B" },
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function () {
                          return [
                            _c("div", { staticClass: "radio-label" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("IncubatorApply.Confirm.radio[14]")
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "apply-form-block team-image-block" }, [
          _c("div", { staticClass: "left-block" }, [
            _c("div", { staticClass: "block-title" }, [
              _vm._v(
                " " + _vm._s(_vm.$t("IncubatorApply.Confirm.title[10]")) + " "
              ),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "form-content" },
            [
              _c(
                "v-card",
                {
                  staticClass: "preview-card rounded-lg",
                  attrs: { elevation: "4" },
                },
                [
                  _c("v-img", {
                    staticClass: "team-image",
                    attrs: {
                      height: "300px",
                      src: _vm.$static(_vm.form.proposal.img),
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "apply-form-block" }, [
          _c("div", { staticClass: "left-block" }, [
            _c("div", { staticClass: "block-title" }, [
              _vm._v(
                " " + _vm._s(_vm.$t("IncubatorApply.Confirm.title[11]")) + " "
              ),
            ]),
          ]),
          _c("div", { staticClass: "form-content" }, [
            _c(
              "div",
              { staticClass: "pdf-file" },
              [
                _c("v-icon", { staticClass: "icon" }, [
                  _vm._v(" mdi-paperclip "),
                ]),
                _c(
                  "a",
                  {
                    staticClass: "preview-link",
                    attrs: {
                      href: _vm.$static(_vm.form.proposal.pdfFile),
                      target: "_blank",
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("IncubatorApply.Confirm.link")))]
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "apply-form-block" }, [
        _c("div", { staticClass: "left-block" }),
        _c("div", { staticClass: "form-content" }, [
          _c("div", { staticClass: "actions" }, [
            _c(
              "div",
              { staticClass: "btn white-btn", on: { click: _vm.prevStep } },
              [
                _vm._v(
                  " " + _vm._s(_vm.$t("IncubatorApply.Confirm.button[0]")) + " "
                ),
              ]
            ),
            _c(
              "div",
              { staticClass: "btn orange-btn", on: { click: _vm.submit } },
              [
                _vm._v(
                  " " + _vm._s(_vm.$t("IncubatorApply.Confirm.button[1]")) + " "
                ),
              ]
            ),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }