var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.recommends.length > 0
    ? _c("div", { staticClass: "home-block home-block3" }, [
        _c(
          "div",
          { staticClass: "home-block-title", attrs: { "data-aos": "fade-up" } },
          [_vm._v(" " + _vm._s(_vm.$t("home.block3.title")) + " ")]
        ),
        _c("div", { staticClass: "swiper swiper-home-block3" }, [
          _c(
            "div",
            { staticClass: "swiper-wrapper" },
            _vm._l(_vm.recommends, function (item, index) {
              return _c("div", { key: item.id, staticClass: "swiper-slide" }, [
                _c("img", {
                  staticClass: "image",
                  attrs: { src: _vm.recommendImg(index), alt: "" },
                }),
                _c("div", { staticClass: "content" }, [
                  _vm._v(" " + _vm._s(item.content) + " "),
                ]),
              ])
            }),
            0
          ),
          _c("div", {
            staticClass: "swiper-button-prev block3-swiper-button-prev",
          }),
          _c("div", {
            staticClass: "swiper-button-next block3-swiper-button-next",
          }),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }