var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "sns-fixed" }, [
      _c(
        "a",
        {
          staticClass: "sns",
          attrs: {
            href: "https://www.facebook.com/visionfuture.org.tw",
            target: "_blank",
          },
        },
        [
          _c("img", {
            staticClass: "image",
            attrs: { src: require("../assets/sns/fb.png"), alt: "" },
          }),
        ]
      ),
      _c(
        "a",
        {
          staticClass: "sns",
          attrs: {
            href: "https://www.instagram.com/visionfuturefund/",
            target: "_blank",
          },
        },
        [
          _c("img", {
            staticClass: "image",
            attrs: { src: require("../assets/sns/ig.png"), alt: "" },
          }),
        ]
      ),
      _c(
        "a",
        {
          staticClass: "sns",
          attrs: {
            href: "https://www.youtube.com/channel/UCtCQpThY5bj9eS5XXIDsp3g",
            target: "_blank",
          },
        },
        [
          _c("img", {
            staticClass: "image",
            attrs: { src: require("../assets/sns/YT.png"), alt: "" },
          }),
        ]
      ),
      _c(
        "a",
        {
          staticClass: "sns",
          attrs: {
            href: "mailto:hivisionfuture@tzuchi.org.tw",
            target: "_blank",
          },
        },
        [
          _c("img", {
            staticClass: "image",
            attrs: { src: require("../assets/sns/mail.png"), alt: "" },
          }),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }