var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "news" },
    [
      _c("div", { staticClass: "block-content mb-11" }, [
        _vm._v(" " + _vm._s(_vm.$t("news.landing.content[0]")) + " "),
      ]),
      _c("Anchor", { attrs: { id: "now" } }),
      _c(
        "div",
        { staticClass: "block" },
        [
          _c("div", { staticClass: "block-title" }, [
            _c("div", { staticClass: "dot" }),
            _vm._v(" " + _vm._s(_vm.$t("news.landing.title[0]")) + " "),
          ]),
          _c("ThreeBlock", {
            attrs: {
              "block-data": _vm.now,
              "router-name": "NewsNowItem",
              "more-router-link": "NewsNow",
              "show-data": _vm.showData,
            },
          }),
        ],
        1
      ),
      _c("Anchor", { attrs: { id: "social-innovation" } }),
      _c(
        "div",
        { staticClass: "block" },
        [
          _c("div", { staticClass: "block-title" }, [
            _c("div", { staticClass: "dot" }),
            _vm._v(" " + _vm._s(_vm.$t("news.landing.title[1]")) + " "),
          ]),
          _c("div", { staticClass: "block-content" }, [
            _vm._v(" " + _vm._s(_vm.$t("news.landing.content[1]")) + " "),
          ]),
          _c("ThreeBlock", {
            attrs: {
              "block-data": _vm.socialInnovation,
              "router-name": "NewsSocialItem",
              "more-router-link": "NewsSocial",
              "show-data": _vm.showData,
            },
          }),
        ],
        1
      ),
      _c("Anchor", { attrs: { id: "teams" } }),
      _c(
        "div",
        { staticClass: "sub-block mb-16" },
        [
          _c("div", { staticClass: "block-title" }, [
            _c("div", { staticClass: "dot" }),
            _vm._v(" " + _vm._s(_vm.$t("news.landing.title[2]")) + " "),
          ]),
          _c("div", { staticClass: "block-content" }, [
            _vm._v(" " + _vm._s(_vm.$t("news.landing.content[2]")) + " "),
          ]),
          _c("ThreeBlock", {
            attrs: {
              "block-data": _vm.teams,
              "router-name": "NewsTeamsItem",
              "more-router-link": "NewsTeams",
              "show-data": _vm.showData,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }