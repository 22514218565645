var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "academy" },
    [
      _c("Anchor", { attrs: { id: "education" } }),
      _c("div", { staticClass: "block" }, [
        _c("div", { staticClass: "block-title" }, [
          _c("div", { staticClass: "dot" }),
          _vm._v(" " + _vm._s(_vm.$t("academy.landing.title[0]")) + " "),
        ]),
        _c("div", { staticClass: "block-content" }, [
          _vm._v(" " + _vm._s(_vm.$t("academy.landing.content[0]")) + " "),
        ]),
      ]),
      _c("Anchor", { attrs: { id: "curriculum" } }),
      _c("div", { staticClass: "block" }, [
        _c("div", { staticClass: "block-title" }, [
          _c("div", { staticClass: "dot" }),
          _vm._v(" " + _vm._s(_vm.$t("academy.landing.title[1]")) + " "),
        ]),
        _c("div", { staticClass: "block-content" }, [
          _vm._v(" " + _vm._s(_vm.$t("academy.landing.content[1]")) + " "),
        ]),
      ]),
      _c("Anchor", { attrs: { id: "curriculum-now" } }),
      _c(
        "div",
        { staticClass: "sub-block" },
        [
          _c("div", { staticClass: "sub-title" }, [
            _vm._v(" " + _vm._s(_vm.$t("academy.landing.subtitle[0]")) + " "),
          ]),
          _c("ThreeBlock", {
            attrs: {
              "block-data": _vm.curriculumNow,
              "show-data": _vm.showData,
            },
          }),
        ],
        1
      ),
      _c("Anchor", { attrs: { id: "curriculum-history" } }),
      _c(
        "div",
        { staticClass: "sub-block" },
        [
          _c("div", { staticClass: "sub-title" }, [
            _vm._v(" " + _vm._s(_vm.$t("academy.landing.subtitle[1]")) + " "),
          ]),
          _c("ThreeBlock", {
            attrs: {
              "block-data": _vm.curriculumHistory,
              "show-data": _vm.showData,
            },
          }),
        ],
        1
      ),
      _c("Anchor", { attrs: { id: "block" } }),
      _c("div", { staticClass: "block" }, [
        _c("div", { staticClass: "block-title" }, [
          _c("div", { staticClass: "dot" }),
          _vm._v(" " + _vm._s(_vm.$t("academy.landing.title[2]")) + " "),
        ]),
        _c("div", { staticClass: "block-content" }, [
          _vm._v(" " + _vm._s(_vm.$t("academy.landing.content[2]")) + " "),
        ]),
      ]),
      _c("Anchor", { attrs: { id: "reading-now" } }),
      _c(
        "div",
        { staticClass: "sub-block" },
        [
          _c("div", { staticClass: "sub-title" }, [
            _vm._v(" " + _vm._s(_vm.$t("academy.landing.subtitle[0]")) + " "),
          ]),
          _c("ThreeBlock", {
            attrs: { "block-data": _vm.readingNow, "show-data": _vm.showData },
          }),
        ],
        1
      ),
      _c("Anchor", { attrs: { id: "reading-history" } }),
      _c(
        "div",
        { staticClass: "sub-block" },
        [
          _c("div", { staticClass: "sub-title" }, [
            _vm._v(" " + _vm._s(_vm.$t("academy.landing.subtitle[1]")) + " "),
          ]),
          _c("ThreeBlock", {
            attrs: {
              "block-data": _vm.readingHistory,
              "show-data": _vm.showData,
            },
          }),
        ],
        1
      ),
      _c("Anchor", { attrs: { id: "history" } }),
      _c(
        "div",
        { staticClass: "block mb-16" },
        [
          _c("div", { staticClass: "block-title" }, [
            _c("div", { staticClass: "dot" }),
            _vm._v(" " + _vm._s(_vm.$t("academy.landing.title[3]")) + " "),
          ]),
          _c("ThreeBlock", {
            attrs: {
              "block-data": _vm.History,
              "router-name": "AcademyHistoryItem",
              "more-router-link": "AcademyHistory",
              "show-data": _vm.showData,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }