<template>
    <div class="member-data">
        <div class="register-form">
            <v-form
                ref="form"
                v-model="valid"
            >
                <v-text-field
                    v-model="registerForm.data.lastName"
                    outlined
                    :label="$t('Register.MemberData.label[0]')"
                    placeholder=""
                    :hint="$t('Register.MemberData.hint[0]')"
                    persistent-hint
                    :rules="emptyRule"
                    class="border-orange-input"
                />
                <v-text-field
                    v-model="registerForm.data.firstName"
                    outlined
                    :label="$t('Register.MemberData.label[1]')"
                    placeholder=""
                    :hint="$t('Register.MemberData.hint[0]')"
                    :rules="emptyRule"
                    persistent-hint
                    class="border-orange-input"
                />
                <v-text-field
                    v-model="registerForm.data.phone"
                    outlined
                    :label="$t('Register.MemberData.label[2]')"
                    placeholder=""
                    :hint="$t('Register.MemberData.hint[0]')"
                    persistent-hint
                    :rules="phoneRule"
                    class="border-orange-input"
                />
                <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="registerForm.data.birthday"
                            outlined
                            class="border-orange-input"
                            :placeholder="$t('Register.MemberData.label[3]')"
                            :hint="$t('Register.MemberData.hint[0]')"
                            persistent-hint
                            readonly
                            v-bind="attrs"
                            :rules="emptyRule"
                            append-icon="mdi-calendar"
                            v-on="on"
                            @click:append="menu = true"
                        />
                    </template>
                    <v-date-picker
                        v-model="registerForm.data.birthday"
                        no-title
                        scrollable
                        @input="menu = false"
                    />
                </v-menu>
                <v-text-field
                    v-model="registerForm.account"
                    outlined
                    :label="$t('Register.MemberData.label[4]')"
                    placeholder=""
                    :hint="$t('Register.MemberData.hint[1]')"
                    persistent-hint
                    :rules="emailRule"
                    class="border-orange-input"
                />
                <v-row
                    align="center"
                    style="margin: 0"
                >
                    <v-text-field
                        v-model="registerForm.data.contactEmail"
                        outlined
                        :label="$t('Register.MemberData.label[5]')"
                        placeholder=""
                        :hint="$t('Register.MemberData.hint[0]')"
                        persistent-hint
                        :rules="emailRule"
                        class="border-orange-input"
                    />
                    <v-checkbox
                        v-model="mailEqual"
                        :label="$t('Register.MemberData.label[6]')"
                        class="contact-email-equal"
                        @change="handleMailEqual"
                    />
                </v-row>
                <div style="position:relative">
                    <v-text-field
                        v-model="registerForm.password"
                        :type="showPWD ? 'text' : 'password'"
                        :append-icon="showPWD ? 'mdi-eye' : 'mdi-eye-off'"
                        outlined
                        :label="$t('Register.MemberData.label[7]')"
                        placeholder=""
                        :hint="passwordHint"
                        persistent-hint
                        :rules="passwordRule"
                        class="border-orange-input"
                        @click:append="showPWD = !showPWD"
                        @input="checkPasswordStrength"
                    />
                    <div class="password-requirements">
                        <div :class="['requirement', { met: hasMinLength }]">
                            {{ $t('Register.MemberData.password-requirements[0]') }}
                        </div>
                        <div class="divider" />
                        <div :class="['requirement', { met: hasUpperCase }]">
                            {{ $t('Register.MemberData.password-requirements[1]') }}
                        </div>
                        <div class="divider" />
                        <div :class="['requirement', { met: hasLowerCase }]">
                            {{ $t('Register.MemberData.password-requirements[2]') }}
                        </div>
                        <div class="divider" />
                        <div :class="['requirement', { met: hasNumber }]">
                            {{ $t('Register.MemberData.password-requirements[3]') }}
                        </div>
                        <div class="divider" />
                        <div :class="['requirement', { met: hasSpecial }]">
                            {{ $t('Register.MemberData.password-requirements[4]') }}
                        </div>
                    </div>
                </div>

                <v-text-field
                    v-model="rePassword"
                    :type="showRePWD ? 'text' : 'password'"
                    :append-icon="showRePWD ? 'mdi-eye' : 'mdi-eye-off'"
                    outlined
                    :label="$t('Register.MemberData.label[8]')"
                    placeholder=""
                    :hint="$t('Register.MemberData.hint[0]')"
                    persistent-hint
                    :rules="rePasswordRule"
                    class="border-orange-input"
                    @click:append="showRePWD = !showRePWD"
                />
                <v-checkbox
                    v-model="edm"
                    :label="$t('Register.MemberData.label[9]')"
                />
            </v-form>
            <div
                class="submit-btn"
                @click="register"
            >
                {{ $t('Register.MemberData.button') }}
            </div>
            <div class="tips">
                {{ $t('Register.MemberData.tips[0]') }}
                <a
                    href="/policy-terms"
                    target="_blank"
                > {{ $t('Register.MemberData.tips[1]') }}</a>
            </div>
        </div>
    </div>
</template>

<script>
// import _ from 'lodash';
import parsePhoneNumber from 'libphonenumber-js';
import API from '@/api';
import { mapMutations } from 'vuex';

export default {
    components: {},
    data() {
        return {
            valid: false,
            menu: false,
            mailEqual: false,
            showPWD: false,
            showRePWD: false,
            registerForm: {
                account: '',
                password: '',
                data: {
                    firstName: '',
                    lastName: '',
                    phone: '',
                    birthday: '',
                    contactEmail: '',
                },
            },
            edm: false,
            rePassword: '',
            // Password validation states
            hasMinLength: false,
            hasUpperCase: false,
            hasLowerCase: false,
            hasNumber: false,
            hasSpecial: false,
            // rules
            emptyRule: [(v) => !!v || this.$t('Register.MemberData.rule[0]')],
            emailRule: [
                (v) => !!v || this.$t('Register.MemberData.rule[0]'),
                (v) => /.+@.+\..+/.test(v) || this.$t('Register.MemberData.rule[1]'),
                (v) => !v.match(/\s/) || this.$t('Register.MemberData.rule[2]'),
            ],
            passwordRule: [
                (v) => !!v || this.$t('Register.MemberData.rule[0]'),
                (v) => this.validatePassword(v) || this.$t('Register.MemberData.rule[6]'),
                (v) => !v.match(/\s/) || this.$t('Register.MemberData.rule[2]'),
            ],
            phoneRule: [
                (v) => parsePhoneNumber(v, 'TW').isPossible()
                    || this.$t('Register.MemberData.rule[4]'),
            ],
            rePasswordRule: [
                (v) => !!v || this.$t('Register.MemberData.rule[0]'),
                (v) => v === this.registerForm.password || this.$t('Register.MemberData.rule[5]'),
            ],
        };
    },
    computed: {
        passwordHint() {
            return this.$t('Register.MemberData.hint[0]');
        },
    },
    created() {},
    mounted() {},
    methods: {
        ...mapMutations('mNotification', ['handleError']),
        ...mapMutations('mLoading', ['setLoading']),
        validatePassword(password) {
            if (!password) return false;

            const minLength = password.length >= 8;
            const hasUpper = /[A-Z]/.test(password);
            const hasLower = /[a-z]/.test(password);
            const hasNum = /[0-9]/.test(password);
            const hasSpecial = /[!@#$%^&*(),.?":{}|<>]/.test(password);

            return minLength && hasUpper && hasLower && hasNum && hasSpecial;
        },
        checkPasswordStrength(password) {
            this.hasMinLength = password.length >= 8;
            this.hasUpperCase = /[A-Z]/.test(password);
            this.hasLowerCase = /[a-z]/.test(password);
            this.hasNumber = /[0-9]/.test(password);
            this.hasSpecial = /[!@#$%^&*(),.?":{}|<>]/.test(password);
        },
        saveBirthday(date) {
            this.$refs.save(date);
            this.menu = false;
        },
        handleMailEqual() {
            if (this.mailEqual) {
                this.registerForm.data.contactEmail = this.registerForm.account;
            }
        },
        register() {
            this.$refs.form.validate();
            if (this.valid && this.validatePassword(this.registerForm.password)) {
                this.setLoading(true);
                API.User.Register(this.registerForm)
                    .then(() => {
                        this.$router.push({
                            name: 'RegisterEmailValidate',
                            query: { account: this.registerForm.account },
                        });
                        this.setLoading(false);
                    })
                    .catch((e) => {
                        this.setLoading(false);
                        this.handleError({
                            message: e || this.$t('alertMessage.register-fail'),
                            color: 'error',
                        });
                    });

                this.subscribeEDM();
            }
        },
        subscribeEDM() {
            const id = '8814cc3b93';
            if (this.edm) {
                const form = {
                    emailAddress: this.registerForm.data.contactEmail,
                };
                API.Mailchimp.Subscribe(id, form)
                    .then(() => {})
                    .catch((e) => {
                        this.handleError({
                            message: e || this.$t('alertMessage.register-fail'),
                            color: 'error',
                        });
                    });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.member-data {
    .tips {
        margin-top: 21px;
        font-size: 16px;
        letter-spacing: 0.1em;
        font-weight: 700;
        line-height: 1.5;
        text-align: center;
        a {
            color: $color-orange;
        }
    }

    .contact-email-equal {
        margin-left: 10px;
        margin-top: -25px;
    }

    .password-requirements {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        padding: 0 12px;
        position: absolute;
        bottom: 5px;
        right:0;
        .divider{
            width: 1px;
            height: auto;
            background-color: #cccccc;
        }

        .requirement {
            font-size: 12px;
            color: #cccccc;
            font-weight: 700;
            transition: color 0.3s ease;

            &.met {
                color: #4caf50;
            }
        }
    }
}

@media screen and (max-width: 385px) {
    .contact-email-equal {
        margin-left: 0;
    }
}
</style>
