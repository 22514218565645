var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { staticClass: "app" },
    [
      _c("Nav"),
      _c("div", { staticClass: "nav-padding" }),
      _c("router-view"),
      _c("Footer"),
      _c("ScrollTop"),
      _c("SnsFixed"),
      _c("Alert"),
      _c("Loading"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }