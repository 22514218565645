var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "email-validate" }, [
    _vm.status == "doing"
      ? _c("div", { staticClass: "status" }, [
          _c("img", {
            staticClass: "status-img",
            attrs: { src: require("../../assets/register/email.png"), alt: "" },
          }),
          _c("div", { staticClass: "text" }, [
            _c("div", { staticClass: "color-orange" }, [
              _vm._v(
                " " + _vm._s(_vm.$t("Register.EmailValidate.text[0]")) + " "
              ),
            ]),
            _c("div", {}, [
              _vm._v(
                " " + _vm._s(_vm.$t("Register.EmailValidate.text[1]")) + " "
              ),
            ]),
          ]),
        ])
      : _vm._e(),
    _vm.status == "failed"
      ? _c("div", { staticClass: "status" }, [
          _c("img", {
            staticClass: "status-img",
            attrs: {
              src: require("../../assets/register/failed.png"),
              alt: "",
            },
          }),
          _c("div", { staticClass: "text" }, [
            _c("div", { staticClass: "colof-gray" }, [
              _vm._v(
                " " + _vm._s(_vm.$t("Register.EmailValidate.text[2]")) + " "
              ),
            ]),
            _c("div", { staticStyle: { color: "#ADADAD" } }, [
              _vm._v(
                " " + _vm._s(_vm.$t("Register.EmailValidate.text[3]")) + " "
              ),
            ]),
          ]),
        ])
      : _vm._e(),
    _vm.confirmForm.account
      ? _c(
          "div",
          {
            staticClass: "submit-btn",
            class: { "btn-disable": !_vm.confirmEnable },
            on: { click: _vm.resentConfirmEmail },
          },
          [
            _vm._v(" " + _vm._s(_vm.$t("Register.EmailValidate.text[4]"))),
            !_vm.confirmEnable
              ? _c("span", [_vm._v("(" + _vm._s(_vm.count) + ")")])
              : _vm._e(),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }