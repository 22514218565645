var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "study" },
    [
      _c("Anchor", { attrs: { id: "study" } }),
      _c("div", { staticClass: "block" }, [
        _c("div", { staticClass: "block-title" }, [
          _c("div", { staticClass: "dot" }),
          _vm._v(" " + _vm._s(_vm.$t("study.landing.title[0]")) + " "),
        ]),
        _c("div", { staticClass: "block-content" }, [
          _vm._v(" " + _vm._s(_vm.$t("study.landing.content[0]")) + " "),
        ]),
      ]),
      _c("Anchor", { attrs: { id: "SROI" } }),
      _c("div", { staticClass: "block" }, [
        _c("div", { staticClass: "block-title" }, [
          _c("div", { staticClass: "dot" }),
          _vm._v(" " + _vm._s(_vm.$t("study.landing.title[1]")) + " "),
        ]),
        _c(
          "div",
          { staticClass: "list-group" },
          [
            !_vm.showData
              ? _c("v-progress-circular", {
                  attrs: { indeterminate: "", color: "orange" },
                })
              : _vm._e(),
            _vm.showData
              ? _c(
                  "div",
                  {
                    staticClass: "list-item",
                    on: {
                      click: function ($event) {
                        return _vm.goToSRIO()
                      },
                    },
                  },
                  [
                    _c("img", {
                      staticClass: "list-img",
                      attrs: {
                        width: "280px",
                        height: "210px",
                        src: _vm.image(_vm.SROIdata.image),
                        alt: "",
                      },
                    }),
                    _c("div", { staticClass: "list-content" }, [
                      _c("div", { staticClass: "list-title" }, [
                        _vm._v(" " + _vm._s(_vm.SROIdata.title) + " "),
                      ]),
                      _c("div", { staticClass: "list-caption" }, [
                        _vm._v(" " + _vm._s(_vm.SROIdata.description) + " "),
                      ]),
                    ]),
                  ]
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c("Anchor", { attrs: { id: "FUN" } }),
      _c(
        "div",
        { staticClass: "block" },
        [
          _c("div", { staticClass: "block-title" }, [
            _c("div", { staticClass: "dot" }),
            _vm._v(" " + _vm._s(_vm.$t("study.landing.title[2]")) + " "),
          ]),
          _c("div", { staticClass: "block-content" }, [
            _vm._v(" " + _vm._s(_vm.$t("study.landing.content[1]")) + " "),
          ]),
          _c("ThreeBlock", {
            attrs: {
              "caption-open": false,
              "block-data": _vm.funLecture,
              "router-name": "StudyFUNItem",
              "more-router-link": "StudyFUN",
              "show-data": _vm.showData,
            },
          }),
        ],
        1
      ),
      _c("Anchor", { attrs: { id: "history" } }),
      _c("div", { staticClass: "block mb-16" }, [
        _c("div", { staticClass: "block-title" }, [
          _c("div", { staticClass: "dot" }),
          _vm._v(" " + _vm._s(_vm.$t("study.landing.title[3]")) + " "),
        ]),
        _c("div", { staticClass: "block-content mb-11" }, [
          _vm._v(" " + _vm._s(_vm.$t("study.landing.content[2]")) + " "),
        ]),
        _c(
          "div",
          { staticClass: "panels" },
          [
            _c(
              "v-expansion-panels",
              { attrs: { multiple: "", accordion: "" } },
              _vm._l(_vm.historyLecture, function (history, index) {
                return _c(
                  "v-expansion-panel",
                  { key: index, staticClass: "panel-item" },
                  [
                    _c(
                      "v-expansion-panel-header",
                      { staticClass: "history-title" },
                      [_vm._v(" " + _vm._s(history.title) + " ")]
                    ),
                    _c(
                      "v-expansion-panel-content",
                      { attrs: { eager: true } },
                      [
                        _c(
                          "div",
                          { staticClass: "history-content" },
                          _vm._l(history.items, function (item, i) {
                            return _c(
                              "div",
                              {
                                key: i,
                                staticClass: "history-item",
                                on: {
                                  click: function ($event) {
                                    return _vm.goToLink(item)
                                  },
                                },
                              },
                              [
                                _c("v-img", {
                                  attrs: {
                                    width: "280px",
                                    height: "280px",
                                    src: _vm.image(item.image),
                                  },
                                }),
                                _c(
                                  "div",
                                  { staticClass: "history-title mt-2" },
                                  [_vm._v(" " + _vm._s(item.title) + " ")]
                                ),
                              ],
                              1
                            )
                          }),
                          0
                        ),
                        _c("div", { staticClass: "swiper swiper-history" }, [
                          _c(
                            "div",
                            { staticClass: "swiper-wrapper" },
                            _vm._l(history.items, function (item, i) {
                              return _c(
                                "div",
                                { key: i, staticClass: "swiper-slide" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "content",
                                      on: {
                                        click: function ($event) {
                                          return _vm.goToLink(item)
                                        },
                                      },
                                    },
                                    [
                                      _c("v-img", {
                                        staticClass: "swiper-img",
                                        attrs: { src: _vm.image(item.image) },
                                      }),
                                      _c(
                                        "div",
                                        { staticClass: "history-title mt-2" },
                                        [_vm._v(" " + _vm._s(item.title) + " ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            }),
                            0
                          ),
                          _c("div", {
                            staticClass:
                              "swiper-button-prev invention-swiper-button-prev",
                          }),
                          _c("div", {
                            staticClass:
                              "swiper-button-next invention-swiper-button-next",
                          }),
                        ]),
                      ]
                    ),
                  ],
                  1
                )
              }),
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }