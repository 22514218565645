var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Item" }, [
    _c("div", { staticClass: "item-content" }, [
      _c("div", { staticClass: "item-title" }, [
        _vm._v(" " + _vm._s(_vm.data.title) + " "),
      ]),
      _c("div", { staticClass: "item-caption" }, [
        _vm._v(" " + _vm._s(_vm.data.description) + " "),
      ]),
    ]),
    _vm.labelOpen
      ? _c(
          "div",
          { staticClass: "label-group" },
          _vm._l(_vm.data.tags, function (item) {
            return _c(
              "button",
              {
                key: item,
                staticClass: "label-item",
                on: {
                  click: function ($event) {
                    return _vm.transferLabel(item)
                  },
                },
              },
              [_vm._v(" " + _vm._s(item) + " ")]
            )
          }),
          0
        )
      : _vm._e(),
    _c("img", {
      staticClass: "item-img",
      attrs: { src: _vm.image(_vm.data.image), alt: "" },
    }),
    _c("div", {
      staticClass: "item-text",
      domProps: { innerHTML: _vm._s(_vm.data.content) },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }