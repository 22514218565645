import { render, staticRenderFns } from "./Proposal.vue?vue&type=template&id=7c1bc0d7&"
import script from "./Proposal.vue?vue&type=script&lang=js&"
export * from "./Proposal.vue?vue&type=script&lang=js&"
import style0 from "./Proposal.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VCheckbox,VFileInput,VForm,VRadio,VRadioGroup,VTextField,VTextarea})


/* hot reload */
if (module.hot) {
  var api = require("/Users/songlian/Developer/官網/慈濟/vision-future-web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7c1bc0d7')) {
      api.createRecord('7c1bc0d7', component.options)
    } else {
      api.reload('7c1bc0d7', component.options)
    }
    module.hot.accept("./Proposal.vue?vue&type=template&id=7c1bc0d7&", function () {
      api.rerender('7c1bc0d7', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/InventionApply/Proposal.vue"
export default component.exports