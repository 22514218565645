var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "old-member-popup popup" }, [
    _c("div", { staticClass: "mask" }),
    _c(
      "div",
      { staticClass: "panel" },
      [
        _c("v-icon", { staticClass: "close-icon", on: { click: _vm.close } }, [
          _vm._v(" mdi-close "),
        ]),
        _c("div", { staticClass: "text" }, [
          _c("div", { staticClass: "content" }, [
            _vm._v(" " + _vm._s(_vm.$t("OldMemberPopup.content")) + " "),
          ]),
          _c(
            "div",
            { staticClass: "checkbox-box" },
            [
              _c("v-checkbox", {
                staticClass: "checkbox",
                attrs: {
                  label: _vm.$t("OldMemberPopup.label"),
                  color: "#F97E2B",
                },
                model: {
                  value: _vm.notShow,
                  callback: function ($$v) {
                    _vm.notShow = $$v
                  },
                  expression: "notShow",
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "actions" }, [
            _c(
              "div",
              {
                staticClass: "btn bg-gray confirm",
                on: { click: _vm.confirm },
              },
              [_vm._v(" " + _vm._s(_vm.$t("OldMemberPopup.button[0]")) + " ")]
            ),
            _c(
              "div",
              {
                staticClass: "btn bg-orange forget",
                on: {
                  click: function ($event) {
                    return _vm.goto({ name: "ForgetPassword" })
                  },
                },
              },
              [_vm._v(" " + _vm._s(_vm.$t("OldMemberPopup.button[1]")) + " ")]
            ),
          ]),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }