var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login page-center" },
    [
      _c("div", { staticClass: "web-content" }, [
        _c("div", { staticClass: "process-structure" }, [
          _c("div", { staticClass: "auth-process-body" }, [
            _c("div", { staticClass: "process-page-title-row" }, [
              _c("div", { staticClass: "process-page-title" }, [
                _vm._v(" " + _vm._s(_vm.$t("auth.login.title")) + " "),
              ]),
            ]),
            _c("div", { staticClass: "auth-form-block" }, [
              _c(
                "div",
                { staticClass: "google-login", on: { click: _vm.googleLogin } },
                [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/register/google-icon.png"),
                      alt: "",
                    },
                  }),
                  _c("div", { staticClass: "text" }, [
                    _vm._v(" Log in with Google "),
                  ]),
                ]
              ),
              _c("div", { staticClass: "tips" }, [
                _vm._v(" " + _vm._s(_vm.$t("auth.login.tips")) + " "),
                _c(
                  "a",
                  { attrs: { href: "/policy-terms", target: "_blank" } },
                  [_vm._v(_vm._s(_vm.$t("auth.login.terms")))]
                ),
              ]),
              _c("div", { staticClass: "divided" }, [
                _c("div", { staticClass: "line" }),
                _c("span", [_vm._v(_vm._s(_vm.$t("auth.login.or")))]),
                _c("div", { staticClass: "line" }),
              ]),
              _c(
                "div",
                { staticClass: "login-form" },
                [
                  _c(
                    "v-form",
                    {
                      ref: "form",
                      model: {
                        value: _vm.valid,
                        callback: function ($$v) {
                          _vm.valid = $$v
                        },
                        expression: "valid",
                      },
                    },
                    [
                      _c("v-text-field", {
                        staticClass: "border-orange-input",
                        attrs: {
                          outlined: "",
                          label: _vm.$t("auth.login.label[0]"),
                          placeholder: "",
                          hint: "",
                          rules: _vm.emptyRule,
                          "persistent-hint": "",
                        },
                        on: {
                          keypress: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.login.apply(null, arguments)
                          },
                        },
                        model: {
                          value: _vm.loginForm.account,
                          callback: function ($$v) {
                            _vm.$set(_vm.loginForm, "account", $$v)
                          },
                          expression: "loginForm.account",
                        },
                      }),
                      _c("v-text-field", {
                        staticClass: "border-orange-input",
                        attrs: {
                          type: _vm.showPWD ? "text" : "password",
                          "append-icon": _vm.showPWD
                            ? "mdi-eye"
                            : "mdi-eye-off",
                          outlined: "",
                          label: _vm.$t("auth.login.label[1]"),
                          placeholder: "",
                          hint: "",
                          "persistent-hint": "",
                          rules: _vm.passwordRule,
                        },
                        on: {
                          keypress: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.login.apply(null, arguments)
                          },
                          "click:append": function ($event) {
                            _vm.showPWD = !_vm.showPWD
                          },
                        },
                        model: {
                          value: _vm.loginForm.password,
                          callback: function ($$v) {
                            _vm.$set(_vm.loginForm, "password", $$v)
                          },
                          expression: "loginForm.password",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass: "forget-password-link",
                      attrs: { to: { name: "ForgetPassword" } },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("auth.login.forget")) + " ")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "submit-btn",
                      on: {
                        click: _vm.login,
                        keypress: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.login.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("auth.login.login")) + " ")]
                  ),
                  _c(
                    "div",
                    { staticClass: "register-box" },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("auth.login.register.title")) + " "
                      ),
                      _c(
                        "router-link",
                        {
                          staticClass: "register-link",
                          attrs: { to: { name: "RegisterMemberData" } },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("auth.login.register.text")) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ]),
      _vm.oldMemberPopupShow
        ? _c("OldMemberPopup", {
            on: {
              close: function ($event) {
                _vm.oldMemberPopupShow = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }