var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "invention-confirm" },
    [
      _c("v-form", [
        _c("div", { staticClass: "apply-form-block" }, [
          _c("div", { staticClass: "left-block" }, [
            _c("div", { staticClass: "block-title" }, [
              _vm._v(
                " " + _vm._s(_vm.$t("InventionApply.Confirm.title[0]")) + " "
              ),
            ]),
          ]),
          _c("div", { staticClass: "form-content" }, [
            _c(
              "div",
              { staticClass: "form-row" },
              [
                _c("v-text-field", {
                  staticClass: "form-input",
                  attrs: {
                    outlined: "",
                    label: _vm.$t("InventionApply.Confirm.label[0]"),
                    placeholder: "",
                    readonly: "",
                    "background-color": "#F2F2F2",
                  },
                  model: {
                    value: _vm.form.team.name,
                    callback: function ($$v) {
                      _vm.$set(_vm.form.team, "name", $$v)
                    },
                    expression: "form.team.name",
                  },
                }),
                _c("v-text-field", {
                  staticClass: "form-input",
                  attrs: {
                    outlined: "",
                    label: _vm.$t("InventionApply.Confirm.label[1]"),
                    placeholder: "",
                    readonly: "",
                    "background-color": "#F2F2F2",
                  },
                  model: {
                    value: _vm.form.team.school,
                    callback: function ($$v) {
                      _vm.$set(_vm.form.team, "school", $$v)
                    },
                    expression: "form.team.school",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
        _c("div", { staticClass: "apply-form-block" }, [
          _c("div", { staticClass: "left-block" }, [
            _c("div", { staticClass: "block-title" }, [
              _vm._v(
                " " + _vm._s(_vm.$t("InventionApply.Confirm.title[1]")) + " "
              ),
            ]),
            _c("div", { staticClass: "tips" }, [
              _vm._v(
                " " + _vm._s(_vm.$t("InventionApply.Confirm.tips[0]")) + " "
              ),
            ]),
          ]),
          _c("div", { staticClass: "form-content" }, [
            _c(
              "div",
              { staticClass: "teacher-list" },
              _vm._l(_vm.form.team.teachers, function (item, index) {
                return _c("div", { key: index, staticClass: "teacher-item" }, [
                  _c(
                    "div",
                    { staticClass: "form-row" },
                    [
                      _c("v-text-field", {
                        staticClass: "form-input",
                        attrs: {
                          outlined: "",
                          label:
                            "" +
                            _vm.$t("InventionApply.Confirm.label[2]") +
                            (index + 1),
                          readonly: "",
                          "background-color": "#F2F2F2",
                        },
                        model: {
                          value: item.name,
                          callback: function ($$v) {
                            _vm.$set(item, "name", $$v)
                          },
                          expression: "item.name",
                        },
                      }),
                      _c("v-text-field", {
                        staticClass: "form-input",
                        attrs: {
                          outlined: "",
                          label: _vm.$t("InventionApply.Confirm.label[3]"),
                          readonly: "",
                          "background-color": "#F2F2F2",
                        },
                        model: {
                          value: item.phone,
                          callback: function ($$v) {
                            _vm.$set(item, "phone", $$v)
                          },
                          expression: "item.phone",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "form-row" },
                    [
                      _c("v-text-field", {
                        staticClass: "form-input",
                        attrs: {
                          outlined: "",
                          label: _vm.$t("InventionApply.Confirm.label[4]"),
                          placeholder: "",
                          readonly: "",
                          "background-color": "#F2F2F2",
                        },
                        model: {
                          value: item.title,
                          callback: function ($$v) {
                            _vm.$set(item, "title", $$v)
                          },
                          expression: "item.title",
                        },
                      }),
                      _c("v-text-field", {
                        staticClass: "form-input",
                        attrs: {
                          outlined: "",
                          label: _vm.$t("InventionApply.Confirm.label[5]"),
                          readonly: "",
                          "background-color": "#F2F2F2",
                        },
                        model: {
                          value: item.email,
                          callback: function ($$v) {
                            _vm.$set(item, "email", $$v)
                          },
                          expression: "item.email",
                        },
                      }),
                    ],
                    1
                  ),
                ])
              }),
              0
            ),
          ]),
        ]),
        _c("div", { staticClass: "apply-form-block" }, [
          _c("div", { staticClass: "left-block" }, [
            _c("div", { staticClass: "block-title" }, [
              _vm._v(
                " " + _vm._s(_vm.$t("InventionApply.Confirm.title[2]")) + " "
              ),
            ]),
            _c("div", { staticClass: "tips" }, [
              _vm._v(
                " " + _vm._s(_vm.$t("InventionApply.Confirm.tips[1]")) + " "
              ),
            ]),
          ]),
          _c("div", { staticClass: "form-content" }, [
            _c(
              "div",
              { staticClass: "teacher-list" },
              _vm._l(_vm.form.team.members, function (item, index) {
                return _c("div", { key: index, staticClass: "member-item" }, [
                  _c(
                    "div",
                    { staticClass: "form-row" },
                    [
                      _c("v-text-field", {
                        staticClass: "form-input",
                        attrs: {
                          outlined: "",
                          label: _vm.memberNameLabel(index),
                          readonly: "",
                          "background-color": "#F2F2F2",
                        },
                        model: {
                          value: item.name,
                          callback: function ($$v) {
                            _vm.$set(item, "name", $$v)
                          },
                          expression: "item.name",
                        },
                      }),
                      _c("v-text-field", {
                        staticClass: "form-input",
                        attrs: {
                          outlined: "",
                          label: _vm.$t("InventionApply.Confirm.label[8]"),
                          readonly: "",
                          "background-color": "#F2F2F2",
                        },
                        model: {
                          value: item.phone,
                          callback: function ($$v) {
                            _vm.$set(item, "phone", $$v)
                          },
                          expression: "item.phone",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "form-row" },
                    [
                      _c("v-text-field", {
                        staticClass: "form-input",
                        attrs: {
                          outlined: "",
                          label: _vm.$t("InventionApply.Confirm.label[9]"),
                          readonly: "",
                          "background-color": "#F2F2F2",
                        },
                        model: {
                          value: item.department,
                          callback: function ($$v) {
                            _vm.$set(item, "department", $$v)
                          },
                          expression: "item.department",
                        },
                      }),
                      _c("v-text-field", {
                        staticClass: "form-input",
                        attrs: {
                          outlined: "",
                          label: _vm.$t("InventionApply.Confirm.label[10]"),
                          readonly: "",
                          "background-color": "#F2F2F2",
                        },
                        model: {
                          value: item.email,
                          callback: function ($$v) {
                            _vm.$set(item, "email", $$v)
                          },
                          expression: "item.email",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "form-row" },
                    [
                      _c("v-text-field", {
                        staticClass: "form-input",
                        attrs: {
                          outlined: "",
                          label: _vm.$t("InventionApply.Confirm.label[11]"),
                          readonly: "",
                          "background-color": "#F2F2F2",
                        },
                        model: {
                          value: item.grade,
                          callback: function ($$v) {
                            _vm.$set(item, "grade", $$v)
                          },
                          expression: "item.grade",
                        },
                      }),
                      _c(
                        "div",
                        { staticClass: "member-file pdf-file" },
                        [
                          _c("v-icon", { staticClass: "icon" }, [
                            _vm._v(" mdi-paperclip "),
                          ]),
                          _c(
                            "a",
                            {
                              staticClass: "preview-link",
                              attrs: {
                                href: _vm.$static(item.pdfFile),
                                target: "_blank",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("InventionApply.Confirm.link[0]"))
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ])
              }),
              0
            ),
          ]),
        ]),
        _c("div", { staticClass: "apply-form-block team-image-block" }, [
          _c("div", { staticClass: "left-block" }, [
            _c("div", { staticClass: "block-title" }, [
              _vm._v(
                " " + _vm._s(_vm.$t("InventionApply.Confirm.title[3]")) + " "
              ),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "form-content" },
            [
              _c(
                "v-card",
                {
                  staticClass: "preview-card rounded-lg",
                  attrs: { elevation: "4" },
                },
                [
                  _c("v-img", {
                    staticClass: "team-image",
                    attrs: {
                      height: "300px",
                      src: _vm.$static(_vm.form.team.previewImg),
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "apply-form-block" }, [
          _c("div", { staticClass: "left-block" }, [
            _c("div", { staticClass: "block-title" }, [
              _vm._v(
                " " + _vm._s(_vm.$t("InventionApply.TeamData.title[4]")) + " "
              ),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "form-content" },
            [
              _c("v-checkbox", {
                staticClass: "checkbox",
                attrs: {
                  label: _vm.$t("InventionApply.TeamData.checkbox[0][0]"),
                  value: "學校活動公告(學校官網、海報、育成中心等)",
                  color: "#898989",
                  readonly: "",
                },
                model: {
                  value: _vm.form.team.refers,
                  callback: function ($$v) {
                    _vm.$set(_vm.form.team, "refers", $$v)
                  },
                  expression: "form.team.refers",
                },
              }),
              _c("v-checkbox", {
                staticClass: "checkbox",
                attrs: {
                  label: _vm.$t("InventionApply.TeamData.checkbox[0][1]"),
                  value: "獎金獵人平台",
                  color: "#898989",
                  readonly: "",
                },
                model: {
                  value: _vm.form.team.refers,
                  callback: function ($$v) {
                    _vm.$set(_vm.form.team, "refers", $$v)
                  },
                  expression: "form.team.refers",
                },
              }),
              _c("v-checkbox", {
                staticClass: "checkbox",
                attrs: {
                  label: _vm.$t("InventionApply.TeamData.checkbox[0][2]"),
                  value: "FUN大視野官網",
                  color: "#898989",
                  readonly: "",
                },
                model: {
                  value: _vm.form.team.refers,
                  callback: function ($$v) {
                    _vm.$set(_vm.form.team, "refers", $$v)
                  },
                  expression: "form.team.refers",
                },
              }),
              _c("v-checkbox", {
                staticClass: "checkbox",
                attrs: {
                  label: _vm.$t("InventionApply.TeamData.checkbox[0][3]"),
                  value: "FUN大視野FACEBOOK",
                  color: "#898989",
                  readonly: "",
                },
                model: {
                  value: _vm.form.team.refers,
                  callback: function ($$v) {
                    _vm.$set(_vm.form.team, "refers", $$v)
                  },
                  expression: "form.team.refers",
                },
              }),
              _c("v-checkbox", {
                staticClass: "checkbox",
                attrs: {
                  label: _vm.$t("InventionApply.TeamData.checkbox[0][4]"),
                  value: "FUN大視野INSTAGRAM",
                  color: "#898989",
                  readonly: "",
                },
                model: {
                  value: _vm.form.team.refers,
                  callback: function ($$v) {
                    _vm.$set(_vm.form.team, "refers", $$v)
                  },
                  expression: "form.team.refers",
                },
              }),
              _c(
                "div",
                { staticStyle: { display: "flex", "flex-wrap": "wrap" } },
                [
                  _c("v-checkbox", {
                    staticClass: "checkbox",
                    attrs: {
                      label: _vm.$t("InventionApply.TeamData.checkbox[0][5]"),
                      value: "歷屆團隊推薦，團隊名:",
                      color: "#898989",
                      readonly: "",
                    },
                    model: {
                      value: _vm.form.team.refers,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.team, "refers", $$v)
                      },
                      expression: "form.team.refers",
                    },
                  }),
                  _c("v-text-field", {
                    staticClass:
                      "checkbox-input border-orange-input form-input",
                    attrs: { readonly: "" },
                    model: {
                      value: _vm.refers1,
                      callback: function ($$v) {
                        _vm.refers1 = $$v
                      },
                      expression: "refers1",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { display: "flex", "flex-wrap": "wrap" } },
                [
                  _c("v-checkbox", {
                    staticClass: "checkbox",
                    attrs: {
                      label: _vm.$t("InventionApply.TeamData.checkbox[0][6]"),
                      value: "親朋好友推薦，推薦人:",
                      color: "#898989",
                      readonly: "",
                    },
                    model: {
                      value: _vm.form.team.refers,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.team, "refers", $$v)
                      },
                      expression: "form.team.refers",
                    },
                  }),
                  _c("v-text-field", {
                    staticClass:
                      "checkbox-input border-orange-input form-input",
                    attrs: { readonly: "" },
                    model: {
                      value: _vm.refers2,
                      callback: function ($$v) {
                        _vm.refers2 = $$v
                      },
                      expression: "refers2",
                    },
                  }),
                ],
                1
              ),
              _c("v-checkbox", {
                staticClass: "checkbox",
                attrs: {
                  label: _vm.$t("InventionApply.TeamData.checkbox[0][7]"),
                  value: "曾經提案過",
                  color: "#898989",
                  readonly: "",
                },
                model: {
                  value: _vm.form.team.refers,
                  callback: function ($$v) {
                    _vm.$set(_vm.form.team, "refers", $$v)
                  },
                  expression: "form.team.refers",
                },
              }),
              _c("v-checkbox", {
                staticClass: "checkbox",
                attrs: {
                  label: _vm.$t("InventionApply.TeamData.checkbox[0][8]"),
                  value: "原本就知道",
                  color: "#898989",
                  readonly: "",
                },
                model: {
                  value: _vm.form.team.refers,
                  callback: function ($$v) {
                    _vm.$set(_vm.form.team, "refers", $$v)
                  },
                  expression: "form.team.refers",
                },
              }),
              _c("v-checkbox", {
                staticClass: "checkbox",
                attrs: {
                  label: _vm.$t("InventionApply.TeamData.checkbox[0][9]"),
                  value: "慈濟基金會宣傳平台(官網、IG、LINE、大愛台等)",
                  color: "#898989",
                  readonly: "",
                },
                model: {
                  value: _vm.form.team.refers,
                  callback: function ($$v) {
                    _vm.$set(_vm.form.team, "refers", $$v)
                  },
                  expression: "form.team.refers",
                },
              }),
              _c(
                "div",
                { staticStyle: { display: "flex", "flex-wrap": "wrap" } },
                [
                  _c("v-checkbox", {
                    staticClass: "checkbox",
                    attrs: {
                      label: _vm.$t("InventionApply.TeamData.checkbox[0][10]"),
                      value: "其他:",
                      color: "#898989",
                      readonly: "",
                    },
                    model: {
                      value: _vm.form.team.refers,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.team, "refers", $$v)
                      },
                      expression: "form.team.refers",
                    },
                  }),
                  _c("v-text-field", {
                    staticClass:
                      "checkbox-input border-orange-input form-input",
                    attrs: { readonly: "" },
                    model: {
                      value: _vm.refers3,
                      callback: function ($$v) {
                        _vm.refers3 = $$v
                      },
                      expression: "refers3",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "apply-form-block" }, [
          _c("div", { staticClass: "left-block" }, [
            _c("div", { staticClass: "block-title" }, [
              _vm._v(
                " " + _vm._s(_vm.$t("InventionApply.Confirm.title[4]")) + " "
              ),
            ]),
          ]),
          _c("div", { staticClass: "form-content" }, [
            _c(
              "div",
              { staticClass: "form-row" },
              [
                _c("v-text-field", {
                  staticClass: "form-input",
                  attrs: {
                    outlined: "",
                    label: _vm.$t("InventionApply.Confirm.label[12]"),
                    readonly: "",
                    "background-color": "#F2F2F2",
                  },
                  model: {
                    value: _vm.form.proposal.name,
                    callback: function ($$v) {
                      _vm.$set(_vm.form.proposal, "name", $$v)
                    },
                    expression: "form.proposal.name",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
        _c("div", { staticClass: "apply-form-block" }, [
          _c("div", { staticClass: "left-block" }, [
            _c("div", { staticClass: "block-title" }, [
              _vm._v(
                " " + _vm._s(_vm.$t("InventionApply.Confirm.title[5]")) + " "
              ),
            ]),
            _c("div", { staticClass: "tips" }, [
              _vm._v(
                " " + _vm._s(_vm.$t("InventionApply.Confirm.tips[2]")) + " "
              ),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "form-content" },
            [
              _c(
                "v-radio-group",
                {
                  staticClass: "radio-group",
                  attrs: { readonly: "" },
                  model: {
                    value: _vm.form.proposal.level,
                    callback: function ($$v) {
                      _vm.$set(_vm.form.proposal, "level", $$v)
                    },
                    expression: "form.proposal.level",
                  },
                },
                [
                  _c("v-radio", {
                    attrs: { value: "大專校院組", color: "#898989" },
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function () {
                          return [
                            _c("div", { staticClass: "radio-label" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("InventionApply.Confirm.radio[0]")
                                  )
                              ),
                              _c("span", { staticClass: "radio-tips" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("InventionApply.Confirm.tips[3]")
                                    )
                                ),
                              ]),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                  _c("v-radio", {
                    attrs: { value: "高中職組", color: "#898989" },
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function () {
                          return [
                            _c("div", { staticClass: "radio-label" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("InventionApply.Confirm.radio[1]")
                                  )
                              ),
                              _c("span", { staticClass: "radio-tips" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("InventionApply.Confirm.tips[4]")
                                    )
                                ),
                              ]),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "apply-form-block" }, [
          _c("div", { staticClass: "left-block" }, [
            _c("div", { staticClass: "block-title" }, [
              _vm._v(
                " " + _vm._s(_vm.$t("InventionApply.Confirm.title[6]")) + " "
              ),
            ]),
            _c("div", { staticClass: "tips" }, [
              _vm._v(
                " " + _vm._s(_vm.$t("InventionApply.Confirm.tips[2]")) + " "
              ),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "form-content" },
            [
              _c(
                "v-radio-group",
                {
                  staticClass: "radio-group",
                  attrs: { readonly: "" },
                  model: {
                    value: _vm.form.proposal.main,
                    callback: function ($$v) {
                      _vm.$set(_vm.form.proposal, "main", $$v)
                    },
                    expression: "form.proposal.main",
                  },
                },
                [
                  _c("div", { staticClass: "radio-type" }, [
                    _c("div", { staticClass: "label" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("InventionApply.Confirm.label[13]")) +
                          " "
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "block" },
                      [
                        _c("v-radio", {
                          attrs: {
                            value:
                              "慈善 1.以賑災為主，研發備災，賑災所需相關產品。",
                            color: "#898989",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "label",
                              fn: function () {
                                return [
                                  _c("div", { staticClass: "radio-label" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "InventionApply.Confirm.radio[2]"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]
                              },
                              proxy: true,
                            },
                          ]),
                        }),
                        _c("v-radio", {
                          attrs: {
                            value:
                              "慈善 2.以人文關懷為主，研發可以改善弱勢或偏鄉地區之民生或日常問題相關產品。",
                            color: "#898989",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "label",
                              fn: function () {
                                return [
                                  _c("div", { staticClass: "radio-label" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "InventionApply.Confirm.radio[3]"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]
                              },
                              proxy: true,
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "type" }, [
                    _c("div", { staticClass: "label" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("InventionApply.Confirm.label[14]")) +
                          " "
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "block" },
                      [
                        _c("v-radio", {
                          attrs: {
                            value:
                              "醫療 1.以長照2.0為主，研發可幫助解決銀髮族或失能之身心障礙者的生理健康或日常照護之產品。",
                            color: "#898989",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "label",
                              fn: function () {
                                return [
                                  _c("div", { staticClass: "radio-label" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "InventionApply.Confirm.radio[4]"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]
                              },
                              proxy: true,
                            },
                          ]),
                        }),
                        _c("v-radio", {
                          attrs: {
                            value:
                              "醫療 2.研發可以改善醫療職業環境，提升病人安全及居家照者之產品。",
                            color: "#898989",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "label",
                              fn: function () {
                                return [
                                  _c("div", { staticClass: "radio-label" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "InventionApply.Confirm.radio[5]"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]
                              },
                              proxy: true,
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "apply-form-block" }, [
          _c("div", { staticClass: "left-block" }, [
            _c("div", { staticClass: "block-title" }, [
              _vm._v(
                " " + _vm._s(_vm.$t("InventionApply.Confirm.title[7]")) + " "
              ),
            ]),
            _c("div", { staticClass: "tips" }, [
              _vm._v(
                " " + _vm._s(_vm.$t("InventionApply.Confirm.tips[5]")) + " "
              ),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "form-content" },
            [
              _c("v-checkbox", {
                staticClass: "checkbox",
                attrs: {
                  label: _vm.$t("InventionApply.Confirm.label[15]"),
                  value: "SDGs1",
                  color: "#898989",
                  readonly: "",
                },
                model: {
                  value: _vm.form.proposal.subs,
                  callback: function ($$v) {
                    _vm.$set(_vm.form.proposal, "subs", $$v)
                  },
                  expression: "form.proposal.subs",
                },
              }),
              _c("v-checkbox", {
                staticClass: "checkbox",
                attrs: {
                  label: _vm.$t("InventionApply.Confirm.label[16]"),
                  value: "SDGs2",
                  color: "#898989",
                  readonly: "",
                },
                model: {
                  value: _vm.form.proposal.subs,
                  callback: function ($$v) {
                    _vm.$set(_vm.form.proposal, "subs", $$v)
                  },
                  expression: "form.proposal.subs",
                },
              }),
              _c("v-checkbox", {
                staticClass: "checkbox",
                attrs: {
                  label: _vm.$t("InventionApply.Confirm.label[17]"),
                  value: "SDGs3",
                  color: "#898989",
                  readonly: "",
                },
                model: {
                  value: _vm.form.proposal.subs,
                  callback: function ($$v) {
                    _vm.$set(_vm.form.proposal, "subs", $$v)
                  },
                  expression: "form.proposal.subs",
                },
              }),
              _c("v-checkbox", {
                staticClass: "checkbox",
                attrs: {
                  label: _vm.$t("InventionApply.Confirm.label[18]"),
                  value: "SDGs4",
                  color: "#898989",
                  readonly: "",
                },
                model: {
                  value: _vm.form.proposal.subs,
                  callback: function ($$v) {
                    _vm.$set(_vm.form.proposal, "subs", $$v)
                  },
                  expression: "form.proposal.subs",
                },
              }),
              _c("v-checkbox", {
                staticClass: "checkbox",
                attrs: {
                  label: _vm.$t("InventionApply.Confirm.label[19]"),
                  value: "SDGs5",
                  color: "#898989",
                  readonly: "",
                },
                model: {
                  value: _vm.form.proposal.subs,
                  callback: function ($$v) {
                    _vm.$set(_vm.form.proposal, "subs", $$v)
                  },
                  expression: "form.proposal.subs",
                },
              }),
              _c("v-checkbox", {
                staticClass: "checkbox",
                attrs: {
                  label: _vm.$t("InventionApply.Confirm.label[20]"),
                  value: "SDGs6",
                  color: "#898989",
                  readonly: "",
                },
                model: {
                  value: _vm.form.proposal.subs,
                  callback: function ($$v) {
                    _vm.$set(_vm.form.proposal, "subs", $$v)
                  },
                  expression: "form.proposal.subs",
                },
              }),
              _c("v-checkbox", {
                staticClass: "checkbox",
                attrs: {
                  label: _vm.$t("InventionApply.Confirm.label[21]"),
                  value: "SDGs7",
                  color: "#898989",
                  readonly: "",
                },
                model: {
                  value: _vm.form.proposal.subs,
                  callback: function ($$v) {
                    _vm.$set(_vm.form.proposal, "subs", $$v)
                  },
                  expression: "form.proposal.subs",
                },
              }),
              _c("v-checkbox", {
                staticClass: "checkbox",
                attrs: {
                  label: _vm.$t("InventionApply.Confirm.label[22]"),
                  value: "SDGs8",
                  color: "#898989",
                  readonly: "",
                },
                model: {
                  value: _vm.form.proposal.subs,
                  callback: function ($$v) {
                    _vm.$set(_vm.form.proposal, "subs", $$v)
                  },
                  expression: "form.proposal.subs",
                },
              }),
              _c("v-checkbox", {
                staticClass: "checkbox",
                attrs: {
                  label: _vm.$t("InventionApply.Confirm.label[23]"),
                  value: "SDGs9",
                  color: "#898989",
                  readonly: "",
                },
                model: {
                  value: _vm.form.proposal.subs,
                  callback: function ($$v) {
                    _vm.$set(_vm.form.proposal, "subs", $$v)
                  },
                  expression: "form.proposal.subs",
                },
              }),
              _c("v-checkbox", {
                staticClass: "checkbox",
                attrs: {
                  label: _vm.$t("InventionApply.Confirm.label[24]"),
                  value: "SDGs10",
                  color: "#898989",
                  readonly: "",
                },
                model: {
                  value: _vm.form.proposal.subs,
                  callback: function ($$v) {
                    _vm.$set(_vm.form.proposal, "subs", $$v)
                  },
                  expression: "form.proposal.subs",
                },
              }),
              _c("v-checkbox", {
                staticClass: "checkbox",
                attrs: {
                  label: _vm.$t("InventionApply.Confirm.label[25]"),
                  value: "SDGs11",
                  color: "#898989",
                  readonly: "",
                },
                model: {
                  value: _vm.form.proposal.subs,
                  callback: function ($$v) {
                    _vm.$set(_vm.form.proposal, "subs", $$v)
                  },
                  expression: "form.proposal.subs",
                },
              }),
              _c("v-checkbox", {
                staticClass: "checkbox",
                attrs: {
                  label: _vm.$t("InventionApply.Confirm.label[26]"),
                  value: "SDGs12",
                  color: "#898989",
                  readonly: "",
                },
                model: {
                  value: _vm.form.proposal.subs,
                  callback: function ($$v) {
                    _vm.$set(_vm.form.proposal, "subs", $$v)
                  },
                  expression: "form.proposal.subs",
                },
              }),
              _c("v-checkbox", {
                staticClass: "checkbox",
                attrs: {
                  label: _vm.$t("InventionApply.Confirm.label[27]"),
                  value: "SDGs13",
                  color: "#898989",
                  readonly: "",
                },
                model: {
                  value: _vm.form.proposal.subs,
                  callback: function ($$v) {
                    _vm.$set(_vm.form.proposal, "subs", $$v)
                  },
                  expression: "form.proposal.subs",
                },
              }),
              _c("v-checkbox", {
                staticClass: "checkbox",
                attrs: {
                  label: _vm.$t("InventionApply.Confirm.label[28]"),
                  value: "SDGs14",
                  color: "#898989",
                  readonly: "",
                },
                model: {
                  value: _vm.form.proposal.subs,
                  callback: function ($$v) {
                    _vm.$set(_vm.form.proposal, "subs", $$v)
                  },
                  expression: "form.proposal.subs",
                },
              }),
              _c("v-checkbox", {
                staticClass: "checkbox",
                attrs: {
                  label: _vm.$t("InventionApply.Confirm.label[29]"),
                  value: "SDGs15",
                  color: "#898989",
                  readonly: "",
                },
                model: {
                  value: _vm.form.proposal.subs,
                  callback: function ($$v) {
                    _vm.$set(_vm.form.proposal, "subs", $$v)
                  },
                  expression: "form.proposal.subs",
                },
              }),
              _c("v-checkbox", {
                staticClass: "checkbox",
                attrs: {
                  label: _vm.$t("InventionApply.Confirm.label[30]"),
                  value: "SDGs16",
                  color: "#898989",
                  readonly: "",
                },
                model: {
                  value: _vm.form.proposal.subs,
                  callback: function ($$v) {
                    _vm.$set(_vm.form.proposal, "subs", $$v)
                  },
                  expression: "form.proposal.subs",
                },
              }),
              _c("v-checkbox", {
                staticClass: "checkbox",
                attrs: {
                  label: _vm.$t("InventionApply.Confirm.label[31]"),
                  value: "SDGs17",
                  color: "#898989",
                  readonly: "",
                },
                model: {
                  value: _vm.form.proposal.subs,
                  callback: function ($$v) {
                    _vm.$set(_vm.form.proposal, "subs", $$v)
                  },
                  expression: "form.proposal.subs",
                },
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "apply-form-block" }, [
          _c("div", { staticClass: "left-block" }, [
            _c("div", { staticClass: "block-title" }, [
              _vm._v(
                " " + _vm._s(_vm.$t("InventionApply.Confirm.title[8]")) + " "
              ),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "form-content" },
            [
              _c("v-textarea", {
                attrs: {
                  rows: "10",
                  counter: "300",
                  label: _vm.$t("InventionApply.Confirm.label[32]"),
                  "no-resize": "",
                  outlined: "",
                  readonly: "",
                  "background-color": "#F2F2F2",
                },
                model: {
                  value: _vm.form.proposal.abstract,
                  callback: function ($$v) {
                    _vm.$set(_vm.form.proposal, "abstract", $$v)
                  },
                  expression: "form.proposal.abstract",
                },
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "apply-form-block" }, [
          _c("div", { staticClass: "left-block" }, [
            _c("div", { staticClass: "block-title" }, [
              _vm._v(
                " " + _vm._s(_vm.$t("InventionApply.Confirm.title[9]")) + " "
              ),
            ]),
          ]),
          _c("div", { staticClass: "form-content" }, [
            _c(
              "div",
              { staticClass: "pdf-file" },
              [
                _c("v-icon", { staticClass: "icon" }, [
                  _vm._v(" mdi-paperclip "),
                ]),
                _c(
                  "a",
                  {
                    staticClass: "preview-link",
                    attrs: {
                      href: _vm.$static(_vm.form.proposal.pdfFile),
                      target: "_blank",
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("InventionApply.Confirm.link[1]")))]
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "apply-form-block" }, [
        _c("div", { staticClass: "left-block" }),
        _c("div", { staticClass: "form-content" }, [
          _c("div", { staticClass: "actions" }, [
            _c(
              "div",
              { staticClass: "btn white-btn", on: { click: _vm.prevStep } },
              [
                _vm._v(
                  " " + _vm._s(_vm.$t("InventionApply.Confirm.button[0]")) + " "
                ),
              ]
            ),
            _c(
              "div",
              { staticClass: "btn orange-btn", on: { click: _vm.submit } },
              [
                _vm._v(
                  " " + _vm._s(_vm.$t("InventionApply.Confirm.button[1]")) + " "
                ),
              ]
            ),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }