var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "home page" }, [
    _c("div", { staticClass: "swiper-block" }, [
      _c("div", { staticClass: "swiper banner-swiper" }, [
        _c(
          "div",
          { staticClass: "swiper-wrapper" },
          _vm._l(_vm.banner, function (item, index) {
            return _c(
              "div",
              {
                key: item.id,
                staticClass: "swiper-slide",
                attrs: { "data-swiper-slide-index": index },
              },
              [
                _c("img", {
                  staticClass: "is-desktop",
                  attrs: { src: _vm.image(item.imageDes), alt: "" },
                }),
                _c("img", {
                  staticClass: "is-mobile",
                  attrs: { src: _vm.image(item.imageMob), alt: "" },
                }),
              ]
            )
          }),
          0
        ),
        _c("div", {
          staticClass: "swiper-pagination banner-swiper-pagination",
        }),
        _c("div", {
          staticClass: "swiper-button-prev banner-swiper-button-prev",
        }),
        _c("div", {
          staticClass: "swiper-button-next banner-swiper-button-next",
        }),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "web-content" },
      [
        _c("Marquee"),
        _c("Block1"),
        _c("Block2"),
        _c("Block3"),
        _c("Block4"),
        _c("Block5"),
        _c("Block6"),
        _c("Block7"),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }