var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "home-block home-block5" }, [
    _c("div", { staticClass: "title-block" }, [
      _c(
        "h2",
        { staticClass: "home-block-title", attrs: { "data-aos": "fade-up" } },
        [_vm._v(" " + _vm._s(_vm.$t("home.block5.title")) + " ")]
      ),
      _c(
        "h3",
        {
          staticClass: "home-block-subtitle",
          attrs: { "data-aos": "fade-up" },
        },
        [_vm._v(" " + _vm._s(_vm.$t("home.block5.subtitle")) + " ")]
      ),
    ]),
    _c("div", { staticClass: "impact-block" }, [
      _c("div", { staticClass: "line" }, [
        _c("div", { staticClass: "box", attrs: { "data-aos": "fade-up" } }, [
          _c("img", {
            attrs: { src: require("../../assets/home/impact-1.png"), alt: "" },
          }),
          _c(
            "div",
            { staticClass: "item" },
            [
              _c("div", { staticClass: "item-title" }, [
                _vm._v(" Impact Ventures "),
              ]),
              _c(
                "router-link",
                {
                  staticClass: "button",
                  attrs: { to: { name: "Invention" }, "data-aos": "fade-up" },
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("home.block5.buttonName[0]")) + " "
                  ),
                  _c("v-icon", { staticClass: "arrow" }, [
                    _vm._v(" mdi-arrow-right "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "box", attrs: { "data-aos": "fade-up" } }, [
          _c("img", {
            attrs: { src: require("../../assets/home/impact-2.png"), alt: "" },
          }),
          _c(
            "div",
            { staticClass: "item" },
            [
              _c("div", { staticClass: "item-title" }, [
                _vm._v(" Impact Academy "),
              ]),
              _c(
                "router-link",
                {
                  staticClass: "button",
                  attrs: {
                    to: { name: "AcademyIndex" },
                    "data-aos": "fade-up",
                  },
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("home.block5.buttonName[1]")) + " "
                  ),
                  _c("v-icon", { staticClass: "arrow" }, [
                    _vm._v(" mdi-arrow-right "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
      _c("div", { staticClass: "line" }, [
        _c("div", { staticClass: "box", attrs: { "data-aos": "fade-up" } }, [
          _c("img", {
            attrs: { src: require("../../assets/home/impact-3.png"), alt: "" },
          }),
          _c(
            "div",
            { staticClass: "item" },
            [
              _c("div", { staticClass: "item-title" }, [
                _vm._v(" Impact Study "),
              ]),
              _c(
                "router-link",
                {
                  staticClass: "button",
                  attrs: { to: { name: "StudyIndex" }, "data-aos": "fade-up" },
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("home.block5.buttonName[2]")) + " "
                  ),
                  _c("v-icon", { staticClass: "arrow" }, [
                    _vm._v(" mdi-arrow-right "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "box", attrs: { "data-aos": "fade-up" } }, [
          _c("img", {
            attrs: { src: require("../../assets/home/impact-4.png"), alt: "" },
          }),
          _c(
            "div",
            { staticClass: "item" },
            [
              _c("div", { staticClass: "item-title" }, [
                _vm._v(" Impact News "),
              ]),
              _c(
                "router-link",
                {
                  staticClass: "button",
                  attrs: { to: { name: "NewsIndex" }, "data-aos": "fade-up" },
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("home.block5.buttonName[3]")) + " "
                  ),
                  _c("v-icon", { staticClass: "arrow" }, [
                    _vm._v(" mdi-arrow-right "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }