var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "registration-block block" },
    [
      _c("div", { staticClass: "block-title" }, [
        _c("div", { staticClass: "dot" }),
        _vm._v(" " + _vm._s(_vm.$t("invention.registration.title[0]")) + " "),
      ]),
      _c("div", { staticClass: "sub-block" }, [
        _c("div", { staticClass: "sub-title" }, [
          _vm._v(" " + _vm._s(_vm.$t("invention.registration.title[1]")) + " "),
        ]),
        _c("div", {
          staticClass: "block-content",
          domProps: { innerHTML: _vm._s(_vm.content.qualifications) },
        }),
      ]),
      _c("Anchor", { attrs: { id: "judge-method" } }),
      _c("div", { staticClass: "sub-block" }, [
        _c("div", { staticClass: "sub-title" }, [
          _vm._v(" " + _vm._s(_vm.$t("invention.registration.title[2]")) + " "),
        ]),
        _c("div", {
          staticClass: "block-content",
          domProps: { innerHTML: _vm._s(_vm.content.judgeMethod) },
        }),
      ]),
      _c("Anchor", { attrs: { id: "schedule" } }),
      _c("div", { staticClass: "sub-block" }, [
        _c("div", { staticClass: "sub-title" }, [
          _vm._v(" " + _vm._s(_vm.$t("invention.registration.title[3]")) + " "),
        ]),
        _c("div", {
          staticClass: "block-content",
          domProps: { innerHTML: _vm._s(_vm.content.schedule) },
        }),
      ]),
      _c("Anchor", { attrs: { id: "prize" } }),
      _c("div", { staticClass: "sub-block prize" }, [
        _c("div", { staticClass: "sub-title" }, [
          _vm._v(" " + _vm._s(_vm.$t("invention.registration.title[4]")) + " "),
        ]),
        _c("div", { staticClass: "block-content font-size-medium" }, [
          _c("div", [
            _vm._v(
              " " + _vm._s(_vm.$t("invention.registration.content")) + " "
            ),
          ]),
          _c("br"),
          _c("div", { staticClass: "prize-block" }, [
            _c(
              "div",
              { staticClass: "panels" },
              [
                _c(
                  "v-expansion-panels",
                  { attrs: { multiple: "", accordion: "" } },
                  _vm._l(_vm.prizeList, function (item, i) {
                    return _c(
                      "v-expansion-panel",
                      { key: i, staticClass: "panel-item" },
                      [
                        _c(
                          "v-expansion-panel-header",
                          { staticClass: "prize-title" },
                          [_vm._v(" " + _vm._s(item.title) + " ")]
                        ),
                        _c(
                          "v-expansion-panel-content",
                          { staticClass: "prize-content" },
                          [_vm._v(" " + _vm._s(item.content) + " ")]
                        ),
                      ],
                      1
                    )
                  }),
                  1
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "sub-block certificate" }, [
        _c("div", { staticClass: "sub-title" }, [
          _vm._v(" " + _vm._s(_vm.$t("invention.registration.title[5]")) + " "),
        ]),
        _c("div", {
          staticClass: "block-content",
          domProps: { innerHTML: _vm._s(_vm.content.certificate) },
        }),
      ]),
      _c("div", { staticClass: "sub-block contact" }, [
        _c("div", { staticClass: "sub-title" }, [
          _vm._v(" " + _vm._s(_vm.$t("invention.registration.title[6]")) + " "),
        ]),
        _c("div", { staticClass: "block-content" }, [
          _c("div", {
            staticClass: "color-gray remark",
            domProps: { innerHTML: _vm._s(_vm.content.contact) },
          }),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }