var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "member-data" }, [
    _c(
      "div",
      { staticClass: "register-form" },
      [
        _c(
          "v-form",
          {
            ref: "form",
            model: {
              value: _vm.valid,
              callback: function ($$v) {
                _vm.valid = $$v
              },
              expression: "valid",
            },
          },
          [
            _c("v-text-field", {
              staticClass: "border-orange-input",
              attrs: {
                outlined: "",
                label: _vm.$t("Register.MemberData.label[0]"),
                placeholder: "",
                hint: _vm.$t("Register.MemberData.hint[0]"),
                "persistent-hint": "",
                rules: _vm.emptyRule,
              },
              model: {
                value: _vm.registerForm.data.lastName,
                callback: function ($$v) {
                  _vm.$set(_vm.registerForm.data, "lastName", $$v)
                },
                expression: "registerForm.data.lastName",
              },
            }),
            _c("v-text-field", {
              staticClass: "border-orange-input",
              attrs: {
                outlined: "",
                label: _vm.$t("Register.MemberData.label[1]"),
                placeholder: "",
                hint: _vm.$t("Register.MemberData.hint[0]"),
                rules: _vm.emptyRule,
                "persistent-hint": "",
              },
              model: {
                value: _vm.registerForm.data.firstName,
                callback: function ($$v) {
                  _vm.$set(_vm.registerForm.data, "firstName", $$v)
                },
                expression: "registerForm.data.firstName",
              },
            }),
            _c("v-text-field", {
              staticClass: "border-orange-input",
              attrs: {
                outlined: "",
                label: _vm.$t("Register.MemberData.label[2]"),
                placeholder: "",
                hint: _vm.$t("Register.MemberData.hint[0]"),
                "persistent-hint": "",
                rules: _vm.phoneRule,
              },
              model: {
                value: _vm.registerForm.data.phone,
                callback: function ($$v) {
                  _vm.$set(_vm.registerForm.data, "phone", $$v)
                },
                expression: "registerForm.data.phone",
              },
            }),
            _c(
              "v-menu",
              {
                ref: "menu",
                attrs: {
                  "close-on-content-click": false,
                  transition: "scale-transition",
                  "offset-y": "",
                  "min-width": "auto",
                },
                scopedSlots: _vm._u([
                  {
                    key: "activator",
                    fn: function (ref) {
                      var on = ref.on
                      var attrs = ref.attrs
                      return [
                        _c(
                          "v-text-field",
                          _vm._g(
                            _vm._b(
                              {
                                staticClass: "border-orange-input",
                                attrs: {
                                  outlined: "",
                                  placeholder: _vm.$t(
                                    "Register.MemberData.label[3]"
                                  ),
                                  hint: _vm.$t("Register.MemberData.hint[0]"),
                                  "persistent-hint": "",
                                  readonly: "",
                                  rules: _vm.emptyRule,
                                  "append-icon": "mdi-calendar",
                                },
                                on: {
                                  "click:append": function ($event) {
                                    _vm.menu = true
                                  },
                                },
                                model: {
                                  value: _vm.registerForm.data.birthday,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.registerForm.data,
                                      "birthday",
                                      $$v
                                    )
                                  },
                                  expression: "registerForm.data.birthday",
                                },
                              },
                              "v-text-field",
                              attrs,
                              false
                            ),
                            on
                          )
                        ),
                      ]
                    },
                  },
                ]),
                model: {
                  value: _vm.menu,
                  callback: function ($$v) {
                    _vm.menu = $$v
                  },
                  expression: "menu",
                },
              },
              [
                _c("v-date-picker", {
                  attrs: { "no-title": "", scrollable: "" },
                  on: {
                    input: function ($event) {
                      _vm.menu = false
                    },
                  },
                  model: {
                    value: _vm.registerForm.data.birthday,
                    callback: function ($$v) {
                      _vm.$set(_vm.registerForm.data, "birthday", $$v)
                    },
                    expression: "registerForm.data.birthday",
                  },
                }),
              ],
              1
            ),
            _c("v-text-field", {
              staticClass: "border-orange-input",
              attrs: {
                outlined: "",
                label: _vm.$t("Register.MemberData.label[4]"),
                placeholder: "",
                hint: _vm.$t("Register.MemberData.hint[1]"),
                "persistent-hint": "",
                rules: _vm.emailRule,
              },
              model: {
                value: _vm.registerForm.account,
                callback: function ($$v) {
                  _vm.$set(_vm.registerForm, "account", $$v)
                },
                expression: "registerForm.account",
              },
            }),
            _c(
              "v-row",
              { staticStyle: { margin: "0" }, attrs: { align: "center" } },
              [
                _c("v-text-field", {
                  staticClass: "border-orange-input",
                  attrs: {
                    outlined: "",
                    label: _vm.$t("Register.MemberData.label[5]"),
                    placeholder: "",
                    hint: _vm.$t("Register.MemberData.hint[0]"),
                    "persistent-hint": "",
                    rules: _vm.emailRule,
                  },
                  model: {
                    value: _vm.registerForm.data.contactEmail,
                    callback: function ($$v) {
                      _vm.$set(_vm.registerForm.data, "contactEmail", $$v)
                    },
                    expression: "registerForm.data.contactEmail",
                  },
                }),
                _c("v-checkbox", {
                  staticClass: "contact-email-equal",
                  attrs: { label: _vm.$t("Register.MemberData.label[6]") },
                  on: { change: _vm.handleMailEqual },
                  model: {
                    value: _vm.mailEqual,
                    callback: function ($$v) {
                      _vm.mailEqual = $$v
                    },
                    expression: "mailEqual",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticStyle: { position: "relative" } },
              [
                _c("v-text-field", {
                  staticClass: "border-orange-input",
                  attrs: {
                    type: _vm.showPWD ? "text" : "password",
                    "append-icon": _vm.showPWD ? "mdi-eye" : "mdi-eye-off",
                    outlined: "",
                    label: _vm.$t("Register.MemberData.label[7]"),
                    placeholder: "",
                    hint: _vm.passwordHint,
                    "persistent-hint": "",
                    rules: _vm.passwordRule,
                  },
                  on: {
                    "click:append": function ($event) {
                      _vm.showPWD = !_vm.showPWD
                    },
                    input: _vm.checkPasswordStrength,
                  },
                  model: {
                    value: _vm.registerForm.password,
                    callback: function ($$v) {
                      _vm.$set(_vm.registerForm, "password", $$v)
                    },
                    expression: "registerForm.password",
                  },
                }),
                _c("div", { staticClass: "password-requirements" }, [
                  _c(
                    "div",
                    { class: ["requirement", { met: _vm.hasMinLength }] },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "Register.MemberData.password-requirements[0]"
                            )
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c("div", { staticClass: "divider" }),
                  _c(
                    "div",
                    { class: ["requirement", { met: _vm.hasUpperCase }] },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "Register.MemberData.password-requirements[1]"
                            )
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c("div", { staticClass: "divider" }),
                  _c(
                    "div",
                    { class: ["requirement", { met: _vm.hasLowerCase }] },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "Register.MemberData.password-requirements[2]"
                            )
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c("div", { staticClass: "divider" }),
                  _c(
                    "div",
                    { class: ["requirement", { met: _vm.hasNumber }] },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "Register.MemberData.password-requirements[3]"
                            )
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c("div", { staticClass: "divider" }),
                  _c(
                    "div",
                    { class: ["requirement", { met: _vm.hasSpecial }] },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "Register.MemberData.password-requirements[4]"
                            )
                          ) +
                          " "
                      ),
                    ]
                  ),
                ]),
              ],
              1
            ),
            _c("v-text-field", {
              staticClass: "border-orange-input",
              attrs: {
                type: _vm.showRePWD ? "text" : "password",
                "append-icon": _vm.showRePWD ? "mdi-eye" : "mdi-eye-off",
                outlined: "",
                label: _vm.$t("Register.MemberData.label[8]"),
                placeholder: "",
                hint: _vm.$t("Register.MemberData.hint[0]"),
                "persistent-hint": "",
                rules: _vm.rePasswordRule,
              },
              on: {
                "click:append": function ($event) {
                  _vm.showRePWD = !_vm.showRePWD
                },
              },
              model: {
                value: _vm.rePassword,
                callback: function ($$v) {
                  _vm.rePassword = $$v
                },
                expression: "rePassword",
              },
            }),
            _c("v-checkbox", {
              attrs: { label: _vm.$t("Register.MemberData.label[9]") },
              model: {
                value: _vm.edm,
                callback: function ($$v) {
                  _vm.edm = $$v
                },
                expression: "edm",
              },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "submit-btn", on: { click: _vm.register } }, [
          _vm._v(" " + _vm._s(_vm.$t("Register.MemberData.button")) + " "),
        ]),
        _c("div", { staticClass: "tips" }, [
          _vm._v(" " + _vm._s(_vm.$t("Register.MemberData.tips[0]")) + " "),
          _c("a", { attrs: { href: "/policy-terms", target: "_blank" } }, [
            _vm._v(" " + _vm._s(_vm.$t("Register.MemberData.tips[1]"))),
          ]),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }