var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "incubator-complete" }, [
    _c("div", { staticClass: "status" }, [
      _c("img", {
        staticClass: "status-img",
        attrs: { src: require("../../assets/register/success.png"), alt: "" },
      }),
      _c("div", { staticClass: "text" }, [
        _c("div", { staticStyle: { color: "#61c88a" } }, [
          _vm._v(" " + _vm._s(_vm.$t("IncubatorApply.Complete.text[0]")) + " "),
        ]),
        _c("div", [
          _vm._v(" " + _vm._s(_vm.$t("IncubatorApply.Complete.text[1]")) + " "),
          _c("br"),
          _vm._v(" " + _vm._s(_vm.$t("IncubatorApply.Complete.text[2]")) + " "),
        ]),
        _c("div", { staticStyle: { "margin-top": "20px" } }, [
          _vm._v(" " + _vm._s(_vm.$t("IncubatorApply.Complete.text[3]")) + " "),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "action" }, [
      _c(
        "div",
        { staticClass: "btn white-btn", on: { click: _vm.backToHome } },
        [
          _vm._v(
            " " + _vm._s(_vm.$t("IncubatorApply.Complete.button[0]")) + " "
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "btn orange-btn", on: { click: _vm.toProfile } },
        [
          _vm._v(
            " " + _vm._s(_vm.$t("IncubatorApply.Complete.button[1]")) + " "
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }