var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "invention-proposal" },
    [
      _c(
        "v-form",
        {
          ref: "form",
          model: {
            value: _vm.valid,
            callback: function ($$v) {
              _vm.valid = $$v
            },
            expression: "valid",
          },
        },
        [
          _c("div", { staticClass: "apply-form-block" }, [
            _c("div", { staticClass: "left-block" }, [
              _c("div", { staticClass: "block-title" }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("InventionApply.Proposal.title[0]")) + " "
                ),
              ]),
            ]),
            _c("div", { staticClass: "form-content" }, [
              _c(
                "div",
                { staticClass: "form-row" },
                [
                  _c("v-text-field", {
                    staticClass: "border-orange-input form-input",
                    attrs: {
                      outlined: "",
                      label: _vm.$t("InventionApply.Proposal.label[0]"),
                      placeholder: "",
                      hint: _vm.$t("InventionApply.Proposal.hint[0]"),
                      "persistent-hint": "",
                      rules: _vm.twelveWordsRule,
                    },
                    model: {
                      value: _vm.form.proposal.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.proposal, "name", $$v)
                      },
                      expression: "form.proposal.name",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _c("div", { staticClass: "apply-form-block" }, [
            _c("div", { staticClass: "left-block" }, [
              _c("div", { staticClass: "block-title" }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("InventionApply.Proposal.title[1]")) + " "
                ),
              ]),
              _c("div", { staticClass: "tips" }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("InventionApply.Proposal.tips[0]")) + " "
                ),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "form-content" },
              [
                _c(
                  "v-radio-group",
                  {
                    staticClass: "radio-group",
                    attrs: { rules: _vm.emptyRule },
                    model: {
                      value: _vm.form.proposal.level,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.proposal, "level", $$v)
                      },
                      expression: "form.proposal.level",
                    },
                  },
                  [
                    _c("v-radio", {
                      attrs: { value: "大專校院組", color: "#F97E2B" },
                      scopedSlots: _vm._u([
                        {
                          key: "label",
                          fn: function () {
                            return [
                              _c("div", { staticClass: "radio-label" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("InventionApply.Proposal.radio[0]")
                                    )
                                ),
                                _c("span", { staticClass: "radio-tips" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("InventionApply.Proposal.tips[1]")
                                    )
                                  ),
                                ]),
                              ]),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    }),
                    _c("v-radio", {
                      attrs: { value: "高中職組", color: "#F97E2B" },
                      scopedSlots: _vm._u([
                        {
                          key: "label",
                          fn: function () {
                            return [
                              _c("div", { staticClass: "radio-label" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("InventionApply.Proposal.radio[1]")
                                    )
                                ),
                                _c("span", { staticClass: "radio-tips" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("InventionApply.Proposal.tips[2]")
                                    )
                                  ),
                                ]),
                              ]),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "apply-form-block" }, [
            _c("div", { staticClass: "left-block" }, [
              _c("div", { staticClass: "block-title" }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("InventionApply.Proposal.title[2]")) + " "
                ),
              ]),
              _c("div", { staticClass: "tips" }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("InventionApply.Proposal.tips[0]")) + " "
                ),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "form-content" },
              [
                _c(
                  "v-radio-group",
                  {
                    staticClass: "radio-group",
                    attrs: { rules: _vm.emptyRule },
                    model: {
                      value: _vm.form.proposal.main,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.proposal, "main", $$v)
                      },
                      expression: "form.proposal.main",
                    },
                  },
                  [
                    _c("div", { staticClass: "radio-type" }, [
                      _c("div", { staticClass: "label" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("InventionApply.Proposal.label[1]")) +
                            " "
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "block" },
                        [
                          _c("v-radio", {
                            attrs: {
                              value:
                                "慈善 1.以賑災為主，研發備災，賑災所需相關產品。",
                              color: "#F97E2B",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "label",
                                fn: function () {
                                  return [
                                    _c("div", { staticClass: "radio-label" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "InventionApply.Proposal.radio[2]"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                          }),
                          _c("v-radio", {
                            attrs: {
                              value:
                                "慈善 2.以人文關懷為主，研發可以改善弱勢或偏鄉地區之民生或日常問題相關產品。",
                              color: "#F97E2B",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "label",
                                fn: function () {
                                  return [
                                    _c("div", { staticClass: "radio-label" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "InventionApply.Proposal.radio[3]"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "type" }, [
                      _c("div", { staticClass: "label" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("InventionApply.Proposal.label[2]")) +
                            " "
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "block" },
                        [
                          _c("v-radio", {
                            attrs: {
                              value:
                                "醫療 1.以長照2.0為主，研發可幫助解決銀髮族或失能之身心障礙者的生理健康或日常照護之產品。",
                              color: "#F97E2B",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "label",
                                fn: function () {
                                  return [
                                    _c("div", { staticClass: "radio-label" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "InventionApply.Proposal.radio[4]"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                          }),
                          _c("v-radio", {
                            attrs: {
                              value:
                                "醫療 2.研發可以改善醫療職業環境，提升病人安全及居家照者之產品。",
                              color: "#F97E2B",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "label",
                                fn: function () {
                                  return [
                                    _c("div", { staticClass: "radio-label" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "InventionApply.Proposal.radio[5]"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "apply-form-block" }, [
            _c(
              "div",
              { staticClass: "left-block" },
              [
                _c("div", { staticClass: "block-title" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("InventionApply.Proposal.title[3]")) +
                      " "
                  ),
                ]),
                _c("div", { staticClass: "tips" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("InventionApply.Proposal.tips[3]")) +
                      " "
                  ),
                ]),
                _c("v-checkbox", {
                  staticClass: "checkbox checkbox-title",
                  staticStyle: { "pointer-events": "none" },
                  attrs: { rules: _vm.subsRule },
                  model: {
                    value: _vm.form.proposal.subs,
                    callback: function ($$v) {
                      _vm.$set(_vm.form.proposal, "subs", $$v)
                    },
                    expression: "form.proposal.subs",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "form-content" },
              [
                _c("v-checkbox", {
                  staticClass: "checkbox",
                  attrs: {
                    label: _vm.$t("InventionApply.Proposal.label[3]"),
                    value: "SDGs1",
                    color: "#F97E2B",
                  },
                  model: {
                    value: _vm.form.proposal.subs,
                    callback: function ($$v) {
                      _vm.$set(_vm.form.proposal, "subs", $$v)
                    },
                    expression: "form.proposal.subs",
                  },
                }),
                _c("v-checkbox", {
                  staticClass: "checkbox",
                  attrs: {
                    label: _vm.$t("InventionApply.Proposal.label[4]"),
                    value: "SDGs2",
                    color: "#F97E2B",
                  },
                  model: {
                    value: _vm.form.proposal.subs,
                    callback: function ($$v) {
                      _vm.$set(_vm.form.proposal, "subs", $$v)
                    },
                    expression: "form.proposal.subs",
                  },
                }),
                _c("v-checkbox", {
                  staticClass: "checkbox",
                  attrs: {
                    label: _vm.$t("InventionApply.Proposal.label[5]"),
                    value: "SDGs3",
                    color: "#F97E2B",
                  },
                  model: {
                    value: _vm.form.proposal.subs,
                    callback: function ($$v) {
                      _vm.$set(_vm.form.proposal, "subs", $$v)
                    },
                    expression: "form.proposal.subs",
                  },
                }),
                _c("v-checkbox", {
                  staticClass: "checkbox",
                  attrs: {
                    label: _vm.$t("InventionApply.Proposal.label[6]"),
                    value: "SDGs4",
                    color: "#F97E2B",
                  },
                  model: {
                    value: _vm.form.proposal.subs,
                    callback: function ($$v) {
                      _vm.$set(_vm.form.proposal, "subs", $$v)
                    },
                    expression: "form.proposal.subs",
                  },
                }),
                _c("v-checkbox", {
                  staticClass: "checkbox",
                  attrs: {
                    label: _vm.$t("InventionApply.Proposal.label[7]"),
                    value: "SDGs5",
                    color: "#F97E2B",
                  },
                  model: {
                    value: _vm.form.proposal.subs,
                    callback: function ($$v) {
                      _vm.$set(_vm.form.proposal, "subs", $$v)
                    },
                    expression: "form.proposal.subs",
                  },
                }),
                _c("v-checkbox", {
                  staticClass: "checkbox",
                  attrs: {
                    label: _vm.$t("InventionApply.Proposal.label[8]"),
                    value: "SDGs6",
                    color: "#F97E2B",
                  },
                  model: {
                    value: _vm.form.proposal.subs,
                    callback: function ($$v) {
                      _vm.$set(_vm.form.proposal, "subs", $$v)
                    },
                    expression: "form.proposal.subs",
                  },
                }),
                _c("v-checkbox", {
                  staticClass: "checkbox",
                  attrs: {
                    label: _vm.$t("InventionApply.Proposal.label[9]"),
                    value: "SDGs7",
                    color: "#F97E2B",
                  },
                  model: {
                    value: _vm.form.proposal.subs,
                    callback: function ($$v) {
                      _vm.$set(_vm.form.proposal, "subs", $$v)
                    },
                    expression: "form.proposal.subs",
                  },
                }),
                _c("v-checkbox", {
                  staticClass: "checkbox",
                  attrs: {
                    label: _vm.$t("InventionApply.Proposal.label[10]"),
                    value: "SDGs8",
                    color: "#F97E2B",
                  },
                  model: {
                    value: _vm.form.proposal.subs,
                    callback: function ($$v) {
                      _vm.$set(_vm.form.proposal, "subs", $$v)
                    },
                    expression: "form.proposal.subs",
                  },
                }),
                _c("v-checkbox", {
                  staticClass: "checkbox",
                  attrs: {
                    label: _vm.$t("InventionApply.Proposal.label[11]"),
                    value: "SDGs9",
                    color: "#F97E2B",
                  },
                  model: {
                    value: _vm.form.proposal.subs,
                    callback: function ($$v) {
                      _vm.$set(_vm.form.proposal, "subs", $$v)
                    },
                    expression: "form.proposal.subs",
                  },
                }),
                _c("v-checkbox", {
                  staticClass: "checkbox",
                  attrs: {
                    label: _vm.$t("InventionApply.Proposal.label[12]"),
                    value: "SDGs10",
                    color: "#F97E2B",
                  },
                  model: {
                    value: _vm.form.proposal.subs,
                    callback: function ($$v) {
                      _vm.$set(_vm.form.proposal, "subs", $$v)
                    },
                    expression: "form.proposal.subs",
                  },
                }),
                _c("v-checkbox", {
                  staticClass: "checkbox",
                  attrs: {
                    label: _vm.$t("InventionApply.Proposal.label[13]"),
                    value: "SDGs11",
                    color: "#F97E2B",
                  },
                  model: {
                    value: _vm.form.proposal.subs,
                    callback: function ($$v) {
                      _vm.$set(_vm.form.proposal, "subs", $$v)
                    },
                    expression: "form.proposal.subs",
                  },
                }),
                _c("v-checkbox", {
                  staticClass: "checkbox",
                  attrs: {
                    label: _vm.$t("InventionApply.Proposal.label[14]"),
                    value: "SDGs12",
                    color: "#F97E2B",
                  },
                  model: {
                    value: _vm.form.proposal.subs,
                    callback: function ($$v) {
                      _vm.$set(_vm.form.proposal, "subs", $$v)
                    },
                    expression: "form.proposal.subs",
                  },
                }),
                _c("v-checkbox", {
                  staticClass: "checkbox",
                  attrs: {
                    label: _vm.$t("InventionApply.Proposal.label[15]"),
                    value: "SDGs13",
                    color: "#F97E2B",
                  },
                  model: {
                    value: _vm.form.proposal.subs,
                    callback: function ($$v) {
                      _vm.$set(_vm.form.proposal, "subs", $$v)
                    },
                    expression: "form.proposal.subs",
                  },
                }),
                _c("v-checkbox", {
                  staticClass: "checkbox",
                  attrs: {
                    label: _vm.$t("InventionApply.Proposal.label[16]"),
                    value: "SDGs14",
                    color: "#F97E2B",
                  },
                  model: {
                    value: _vm.form.proposal.subs,
                    callback: function ($$v) {
                      _vm.$set(_vm.form.proposal, "subs", $$v)
                    },
                    expression: "form.proposal.subs",
                  },
                }),
                _c("v-checkbox", {
                  staticClass: "checkbox",
                  attrs: {
                    label: _vm.$t("InventionApply.Proposal.label[17]"),
                    value: "SDGs15",
                    color: "#F97E2B",
                  },
                  model: {
                    value: _vm.form.proposal.subs,
                    callback: function ($$v) {
                      _vm.$set(_vm.form.proposal, "subs", $$v)
                    },
                    expression: "form.proposal.subs",
                  },
                }),
                _c("v-checkbox", {
                  staticClass: "checkbox",
                  attrs: {
                    label: _vm.$t("InventionApply.Proposal.label[18]"),
                    value: "SDGs16",
                    color: "#F97E2B",
                  },
                  model: {
                    value: _vm.form.proposal.subs,
                    callback: function ($$v) {
                      _vm.$set(_vm.form.proposal, "subs", $$v)
                    },
                    expression: "form.proposal.subs",
                  },
                }),
                _c("v-checkbox", {
                  staticClass: "checkbox",
                  attrs: {
                    label: _vm.$t("InventionApply.Proposal.label[19]"),
                    value: "SDGs17",
                    color: "#F97E2B",
                  },
                  model: {
                    value: _vm.form.proposal.subs,
                    callback: function ($$v) {
                      _vm.$set(_vm.form.proposal, "subs", $$v)
                    },
                    expression: "form.proposal.subs",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "apply-form-block" }, [
            _c("div", { staticClass: "left-block" }, [
              _c("div", { staticClass: "block-title" }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("InventionApply.Proposal.title[4]")) + " "
                ),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "form-content" },
              [
                _c("v-textarea", {
                  attrs: {
                    rows: "10",
                    counter: "300",
                    label: _vm.$t("InventionApply.Proposal.label[20]"),
                    "no-resize": "",
                    rules: _vm.abstractRule,
                    outlined: "",
                    placeholder: "",
                    hint: _vm.$t("InventionApply.Proposal.hint[0]"),
                    "persistent-hint": "",
                  },
                  model: {
                    value: _vm.form.proposal.abstract,
                    callback: function ($$v) {
                      _vm.$set(_vm.form.proposal, "abstract", $$v)
                    },
                    expression: "form.proposal.abstract",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "apply-form-block" }, [
            _c("div", { staticClass: "left-block" }, [
              _c("div", { staticClass: "block-title" }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("InventionApply.Proposal.title[5]")) + " "
                ),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "form-content" },
              [
                _c("v-file-input", {
                  staticClass: "border-orange-input form-input",
                  attrs: {
                    accept: ".pdf",
                    label: _vm.$t("InventionApply.Proposal.label[21]"),
                    outlined: "",
                    placeholder: "",
                    hint: _vm.$t("InventionApply.Proposal.hint[1]"),
                    "persistent-hint": "",
                    rules: [_vm.pdfRule()],
                  },
                  on: {
                    change: function (file) {
                      return _vm.handlePdfChange(
                        "form.proposal.pdfFile",
                        file,
                        _vm.form.proposal.tmpPdf
                      )
                    },
                  },
                  model: {
                    value: _vm.tmpPdf,
                    callback: function ($$v) {
                      _vm.tmpPdf = $$v
                    },
                    expression: "tmpPdf",
                  },
                }),
                _vm.form.proposal.pdfFile
                  ? _c("div", { staticClass: "preivew" }, [
                      _c(
                        "a",
                        {
                          staticClass: "preview-link",
                          attrs: {
                            href: _vm.$static(_vm.form.proposal.pdfFile),
                            target: "_blank",
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("InventionApply.Proposal.link")))]
                      ),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ]
      ),
      _c("div", { staticClass: "apply-form-block" }, [
        _c("div", { staticClass: "left-block" }),
        _c("div", { staticClass: "form-content" }, [
          _c("div", { staticClass: "actions" }, [
            _c(
              "div",
              { staticClass: "btn white-btn", on: { click: _vm.prevStep } },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("InventionApply.Proposal.button[0]")) +
                    " "
                ),
              ]
            ),
            _c(
              "div",
              { staticClass: "btn blue-btn", on: { click: _vm.save } },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("InventionApply.Proposal.button[1]")) +
                    " "
                ),
              ]
            ),
            _c(
              "div",
              { staticClass: "btn orange-btn", on: { click: _vm.nextStep } },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("InventionApply.Proposal.button[2]")) +
                    " "
                ),
              ]
            ),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }