var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "incubator-final" }, [
    _vm.isFinish
      ? _c("div", { staticClass: "deadline" }, [
          _vm._v(" " + _vm._s(_vm.$t("IncubatorFinal.index.deadline")) + " "),
        ])
      : _c("div", { staticClass: "web-content" }, [
          _c("div", { staticClass: "process-structure" }, [
            _c(
              "div",
              { staticClass: "apply-process-body" },
              [
                _c("div", { staticClass: "process-page-title-row" }, [
                  _c("div", { staticClass: "process-page-title" }, [
                    _vm._v(
                      " " + _vm._s(_vm.$t("IncubatorFinal.index.title")) + " "
                    ),
                  ]),
                ]),
                _c(
                  "v-stepper",
                  {
                    staticClass: "stepper",
                    attrs: { "alt-labels": "", value: _vm.nowStep },
                  },
                  [
                    _c(
                      "v-stepper-header",
                      [
                        _c(
                          "v-stepper-step",
                          {
                            attrs: {
                              color: _vm.stepColor(1),
                              complete: _vm.nowStep > 1,
                              step: "1",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("IncubatorFinal.index.stepper[0]")
                                ) +
                                " "
                            ),
                          ]
                        ),
                        _c("v-divider"),
                        _c(
                          "v-stepper-step",
                          {
                            attrs: {
                              color: _vm.stepColor(2),
                              complete: _vm.nowStep > 2,
                              step: "2",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("IncubatorFinal.index.stepper[1]")
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("router-view"),
              ],
              1
            ),
          ]),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }