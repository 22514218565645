var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "invention-terms apply-form-block" }, [
    _c("div", { staticClass: "terms-box" }, [
      _c("div", { staticClass: "terms-title" }, [
        _vm._v(" " + _vm._s(_vm.$t("InventionApply.Terms.title")) + " "),
      ]),
      _c("div", { staticClass: "terms-content" }, [
        _c("p", [
          _vm._v(" " + _vm._s(_vm.$t("InventionApply.Terms.content[0]")) + " "),
        ]),
        _c("p", [
          _vm._v(" " + _vm._s(_vm.$t("InventionApply.Terms.content[1]")) + " "),
        ]),
        _c("p", [
          _vm._v(" " + _vm._s(_vm.$t("InventionApply.Terms.content[2]")) + " "),
        ]),
        _c("p", [
          _vm._v(" " + _vm._s(_vm.$t("InventionApply.Terms.content[3]")) + " "),
        ]),
        _c("p", [
          _vm._v(" " + _vm._s(_vm.$t("InventionApply.Terms.content[4]")) + " "),
        ]),
        _c("p", [
          _vm._v(" " + _vm._s(_vm.$t("InventionApply.Terms.content[5]")) + " "),
        ]),
        _c("p", [
          _vm._v(" " + _vm._s(_vm.$t("InventionApply.Terms.content[6]")) + " "),
        ]),
        _c("p", [
          _vm._v(" " + _vm._s(_vm.$t("InventionApply.Terms.content[7]")) + " "),
        ]),
        _c("p", [
          _vm._v(" " + _vm._s(_vm.$t("InventionApply.Terms.content[8]")) + " "),
        ]),
        _c("p", [
          _vm._v(" " + _vm._s(_vm.$t("InventionApply.Terms.content[9]")) + " "),
        ]),
        _c("p", [
          _vm._v(
            " " + _vm._s(_vm.$t("InventionApply.Terms.content[10]")) + " "
          ),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "actions" }, [
      _c("div", { staticClass: "btn white-btn", on: { click: _vm.prevStep } }, [
        _vm._v(" " + _vm._s(_vm.$t("InventionApply.Terms.button[0]")) + " "),
      ]),
      _c("div", { staticClass: "btn orange-btn", on: { click: _vm.agree } }, [
        _vm._v(" " + _vm._s(_vm.$t("InventionApply.Terms.button[1]")) + " "),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }