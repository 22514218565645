var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "home-block home-block6" }, [
    _c("img", {
      staticClass: "left is-desktop",
      attrs: { src: require("../../assets/home/block-6-left.jpg"), alt: "" },
    }),
    _c("img", {
      staticClass: "left is-mobile",
      attrs: {
        src: require("../../assets/home/mobile/block-6-left-mobile.jpg"),
        alt: "",
      },
    }),
    _c(
      "div",
      { staticClass: "right" },
      [
        _c(
          "router-link",
          {
            staticClass: "button",
            attrs: { to: { name: "About" }, "data-aos": "fade-up" },
          },
          [
            _vm._v(" " + _vm._s(_vm.$t("home.block6.buttonName[0]")) + " "),
            _c("v-icon", { staticClass: "arrow" }, [
              _vm._v(" mdi-arrow-right "),
            ]),
          ],
          1
        ),
        _c(
          "router-link",
          {
            staticClass: "button",
            attrs: { to: { name: "SubscribeEDM" }, "data-aos": "fade-up" },
          },
          [
            _vm._v(" " + _vm._s(_vm.$t("home.block6.buttonName[1]")) + " "),
            _c("v-icon", { staticClass: "arrow" }, [
              _vm._v(" mdi-arrow-right "),
            ]),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }