var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "teacher-list-block block" }, [
    _c("div", { staticClass: "block-title" }, [
      _c("div", { staticClass: "dot" }),
      _vm._v(" " + _vm._s(_vm.$t("incubator.teacher.title")) + " "),
    ]),
    _c("div", { staticClass: "sub-block" }, [
      _c("div", { staticClass: "swiper swiper-teacher" }, [
        _c(
          "div",
          { staticClass: "swiper-wrapper" },
          _vm._l(_vm.teacher, function (item, index) {
            return _c("div", { key: index, staticClass: "swiper-slide" }, [
              _c("div", { staticClass: "content" }, [
                _c("img", { attrs: { src: _vm.image(item.image), alt: "" } }),
                _c("div", { staticClass: "name color-blue" }, [
                  _vm._v(" " + _vm._s(item.name) + " "),
                ]),
                _c("div", { staticClass: "text color-orange" }, [
                  _vm._v(" " + _vm._s(item.unit) + " "),
                ]),
                _c("div", { staticClass: "position color-orange" }, [
                  _vm._v(" " + _vm._s(item.position) + " "),
                ]),
              ]),
            ])
          }),
          0
        ),
        _c("div", {
          staticClass: "swiper-button-prev invention-swiper-button-prev",
        }),
        _c("div", {
          staticClass: "swiper-button-next invention-swiper-button-next",
        }),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }