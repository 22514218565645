var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "home-block home-block4" }, [
    _c(
      "div",
      { staticClass: "home-block-title", attrs: { "data-aos": "fade-up" } },
      [_vm._v(" " + _vm._s(_vm.$t("home.block4.title")) + " ")]
    ),
    _c(
      "div",
      { staticClass: "number-animate-block" },
      _vm._l(_vm.numbers, function (item) {
        return _c(
          "div",
          {
            key: item.ref,
            ref: item.ref,
            refInFor: true,
            staticClass: "number-item",
          },
          [
            _c("div", { staticClass: "number-animate-2" }, [
              _c(
                "div",
                { staticClass: "number-box" },
                [
                  _c("number", {
                    ref: item.numberRef,
                    refInFor: true,
                    staticClass: "number",
                    attrs: {
                      from: item.from,
                      to: item.to,
                      duration: 5,
                      delay: 0,
                      easing: "Power1.easeOut",
                      "animation-paused": true,
                      format: item.format,
                    },
                  }),
                  _c("div", { staticClass: "unit" }, [
                    _vm._v(" " + _vm._s(_vm.$t(item.unit)) + " "),
                  ]),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "label" }, [
              _vm._v(" " + _vm._s(_vm.$t(item.label)) + " "),
            ]),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }