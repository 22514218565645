const TIMEOUT_DURATION = 15 * 60 * 1000; // 30 minutes

const handleLogout = async (router, store) => {
    try {
        await store.dispatch('mAuth/Logout');
        router.push('/login');
    } catch (error) {
        console.error('Logout failed:', error);
    }
};

export default function setupAutoLogout(router, store) {
    let timeoutId = null;

    const resetTimer = () => {
        // 只在用戶已登入時才重置計時器
        if (!store.getters['mAuth/isLogin']) {
            return;
        }

        if (timeoutId) {
            clearTimeout(timeoutId);
        }

        timeoutId = setTimeout(() => {
            handleLogout(router, store);
        }, TIMEOUT_DURATION);
    };

    // 監聽用戶活動
    const activities = ['mousedown', 'mousemove', 'keypress', 'scroll', 'touchstart'];

    // Add event listeners and verify they're being added
    activities.forEach((activity) => {
        document.addEventListener(activity, resetTimer, true);
        console.log(`Added event listener for: ${activity}`);
    });

    // 初始化計時器
    resetTimer();

    // 返回清理函數
    return () => {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
        activities.forEach((activity) => {
            document.removeEventListener(activity, resetTimer, true); // Match capture phase
        });
    };
}
