var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "reset-password page-center" }, [
    _c("div", { staticClass: "web-content" }, [
      _c("div", { staticClass: "process-structure" }, [
        _c("div", { staticClass: "auth-process-body" }, [
          _c("div", { staticClass: "process-page-title-row" }, [
            _c("div", { staticClass: "process-page-title" }, [
              _vm._v(" " + _vm._s(_vm.$t("auth.ResetPassword.title")) + " "),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "form-data" },
            [
              _c(
                "v-form",
                {
                  ref: "form",
                  model: {
                    value: _vm.valid,
                    callback: function ($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid",
                  },
                },
                [
                  _c("v-text-field", {
                    staticClass: "border-orange-input",
                    attrs: {
                      outlined: "",
                      label: _vm.$t("auth.ResetPassword.label[0]"),
                      readonly: "",
                      hint: _vm.$t("auth.ResetPassword.hint[0]"),
                      "persistent-hint": "",
                    },
                    model: {
                      value: _vm.account,
                      callback: function ($$v) {
                        _vm.account = $$v
                      },
                      expression: "account",
                    },
                  }),
                  _c("v-text-field", {
                    staticClass: "border-orange-input",
                    attrs: {
                      type: _vm.showPWD ? "text" : "password",
                      "append-icon": _vm.showPWD ? "mdi-eye" : "mdi-eye-off",
                      outlined: "",
                      label: _vm.$t("auth.ResetPassword.label[1]"),
                      placeholder: "",
                      hint: _vm.$t("auth.ResetPassword.hint[1]"),
                      "persistent-hint": "",
                      rules: _vm.passwordRule,
                    },
                    on: {
                      "click:append": function ($event) {
                        _vm.showPWD = !_vm.showPWD
                      },
                    },
                    model: {
                      value: _vm.resetForm.password,
                      callback: function ($$v) {
                        _vm.$set(_vm.resetForm, "password", $$v)
                      },
                      expression: "resetForm.password",
                    },
                  }),
                ],
                1
              ),
              !_vm.disable
                ? _c(
                    "div",
                    {
                      staticClass: "submit-btn",
                      on: { click: _vm.resetPassword },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("auth.ResetPassword.button[0]")) +
                          " "
                      ),
                    ]
                  )
                : _c("div", { staticClass: "disable-btn" }, [
                    _vm._v(
                      " " + _vm._s(_vm.$t("auth.ResetPassword.button[1]")) + " "
                    ),
                  ]),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }