var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "list-pagination" }, [
    _vm.labelOpen
      ? _c("div", { staticClass: "sub-block" }, [
          _c("div", { staticClass: "sub-title" }, [
            _vm._v(" " + _vm._s(_vm.$t("ListPagination.title")) + " "),
          ]),
          _c(
            "div",
            { staticClass: "label-group" },
            _vm._l(_vm.label, function (item) {
              return _c(
                "button",
                {
                  key: item,
                  staticClass: "label-item",
                  class: {
                    labelActive: _vm.isActive(item),
                    labelUnactive: !_vm.isActive(item),
                  },
                  on: {
                    click: function ($event) {
                      return _vm.transferLabel(item)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(item) + " ")]
              )
            }),
            0
          ),
        ])
      : _vm._e(),
    _c(
      "div",
      { staticClass: "list-group" },
      [
        !_vm.showData
          ? _c("v-progress-circular", {
              attrs: { indeterminate: "", color: "orange" },
            })
          : _vm._e(),
        _vm.showData
          ? _c(
              "div",
              { staticStyle: { width: "100%" } },
              _vm._l(_vm.listData, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "list-item",
                    on: {
                      click: function ($event) {
                        return _vm.goToLink(item)
                      },
                    },
                  },
                  [
                    _c("img", {
                      staticClass: "list-img",
                      attrs: {
                        width: "280px",
                        height: "210px",
                        src: _vm.image(item.image),
                        alt: "",
                      },
                    }),
                    _c("div", { staticClass: "list-content" }, [
                      _c("div", { staticClass: "list-title" }, [
                        _vm._v(" " + _vm._s(item.title) + " "),
                      ]),
                      _c("div", { staticClass: "list-caption" }, [
                        _vm._v(" " + _vm._s(item.description) + " "),
                      ]),
                    ]),
                  ]
                )
              }),
              0
            )
          : _vm._e(),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "pagination" },
      [
        _c("v-pagination", {
          attrs: { length: _vm.MaxPage, "total-visible": 6, color: "orange" },
          model: {
            value: _vm.page,
            callback: function ($$v) {
              _vm.page = $$v
            },
            expression: "page",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }