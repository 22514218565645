var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-snackbar",
    {
      staticClass: "text-center",
      attrs: { timeout: 5000, value: !!_vm.message, color: _vm.color, top: "" },
      on: { input: _vm.closeAlert },
    },
    [_vm._v(" " + _vm._s(_vm.message) + " ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }