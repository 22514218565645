var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "home-block home-block2" }, [
    _c(
      "div",
      { staticClass: "box image-left box-1", attrs: { "data-aos": "fade-up" } },
      [
        _c("img", {
          staticClass: "img is-desktop",
          attrs: { src: require("../../assets/home/block2-1.jpg"), alt: "" },
        }),
        _c("img", {
          staticClass: "img is-mobile",
          attrs: {
            src: require("../../assets/home/mobile/block2-1-mobile.jpg"),
            alt: "",
          },
        }),
        _c("div", { staticClass: "content" }, [
          _c("div", { staticClass: "content-title" }, [
            _vm._v(" " + _vm._s(_vm.$t("home.block2[0].title")) + " "),
          ]),
          _c("div", { staticClass: "text" }, [
            _vm._v(" " + _vm._s(_vm.$t("home.block2[0].text")) + " "),
          ]),
        ]),
      ]
    ),
    _c(
      "div",
      {
        staticClass: "box image-right box-2",
        attrs: { "data-aos": "fade-up" },
      },
      [
        _c("img", {
          staticClass: "img is-desktop",
          attrs: { src: require("../../assets/home/block2-2.jpg"), alt: "" },
        }),
        _c("img", {
          staticClass: "img is-mobile",
          attrs: {
            src: require("../../assets/home/mobile/block2-2-mobile.jpg"),
            alt: "",
          },
        }),
        _c("div", { staticClass: "content" }, [
          _c("div", { staticClass: "content-title" }, [
            _vm._v(" " + _vm._s(_vm.$t("home.block2[1].title")) + " "),
          ]),
          _c("div", { staticClass: "text" }, [
            _vm._v(" " + _vm._s(_vm.$t("home.block2[1].text")) + " "),
          ]),
        ]),
      ]
    ),
    _c(
      "div",
      { staticClass: "box image-left box-3", attrs: { "data-aos": "fade-up" } },
      [
        _c("img", {
          staticClass: "img is-desktop",
          attrs: { src: require("../../assets/home/block2-3.jpg"), alt: "" },
        }),
        _c("img", {
          staticClass: "img is-mobile",
          attrs: {
            src: require("../../assets/home/mobile/block2-3-mobile.jpg"),
            alt: "",
          },
        }),
        _c("div", { staticClass: "content" }, [
          _c("div", { staticClass: "content-title" }, [
            _vm._v(" " + _vm._s(_vm.$t("home.block2[2].title")) + " "),
          ]),
          _c("div", { staticClass: "text" }, [
            _vm._v(" " + _vm._s(_vm.$t("home.block2[2].text")) + " "),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }