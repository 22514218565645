var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "invention page normal-page" }, [
    _c("div", { staticClass: "page-banner" }, [
      _c("img", {
        staticClass: "desktop-banner",
        attrs: { src: _vm.image(_vm.content.imageDes), alt: "" },
      }),
      _c("img", {
        staticClass: "mobile-banner",
        attrs: { src: _vm.image(_vm.content.imageMob), alt: "" },
      }),
    ]),
    _c("div", { staticClass: "web-content" }, [
      _c(
        "div",
        { staticClass: "page-structure" },
        [
          _c("PageMenu", { attrs: { "menu-data": _vm.pageMenu } }),
          _c(
            "div",
            { staticClass: "page-content column-center" },
            [
              _c("Anchor", { attrs: { id: "explain" } }),
              _c("ExplainBlock", { attrs: { content: _vm.content } }),
              _c("Anchor", { attrs: { id: "registration" } }),
              _c("RegistrationBlock", {
                attrs: { content: _vm.content, "prize-list": _vm.prize },
              }),
              _c("Anchor", { attrs: { id: "q-and-a" } }),
              _c("QandABlock", { attrs: { question: _vm.qa } }),
              _c("Anchor", { attrs: { id: "recommend" } }),
              _c("RecommendBlock", { attrs: { recommend: _vm.recommend } }),
              _c("Anchor", { attrs: { id: "history-team" } }),
              _c("HistoryBlock", { attrs: { "history-team": _vm.team } }),
              _c(
                "div",
                { staticClass: "sign-up-btn", on: { click: _vm.goToSignUp } },
                [_vm._v(" " + _vm._s(_vm.$t("invention.goToSignUp")) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }