<template>
    <v-app class="app">
        <Nav />
        <div class="nav-padding" />
        <router-view />
        <Footer />
        <ScrollTop />
        <SnsFixed />
        <Alert />
        <Loading />
    </v-app>
</template>

<script>
import Nav from '@/components/Nav.vue';
import Footer from '@/components/Footer.vue';
import ScrollTop from '@/components/ScrollTop.vue';
import SnsFixed from '@/components/SnsFixed.vue';
import Alert from '@/components/UI/Alert.vue';
import Loading from '@/components/UI/Loading.vue';
import setupAutoLogout from '@/boot/auto-logout';
import { mapGetters } from 'vuex';

export default {
    name: 'App',
    components: {
        Nav,
        Footer,
        ScrollTop,
        SnsFixed,
        Alert,
        Loading,
    },
    data() {
        return {
            cleanupAutoLogout: null,
        };
    },
    computed: {
        ...mapGetters('mAuth', ['isLogin', 'getRole']),
    },
    watch: {
        isLogin: {
            immediate: true,
            handler(newValue) {
                // Clean up existing auto-logout if any
                if (this.cleanupAutoLogout) {
                    this.cleanupAutoLogout();
                    this.cleanupAutoLogout = null;
                }

                // Set up auto-logout only when logged in
                if (newValue) {
                    console.log('Setting up auto-logout');
                    this.cleanupAutoLogout = setupAutoLogout(this.$router, this.$store);
                }
            },
        },
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    unmounted() {
        if (this.cleanupAutoLogout) {
            this.cleanupAutoLogout();
        }
    },
    methods: {
        handleScroll() {
            // const { scrollY } = window;
            // this.handlePageMenu(scrollY);
        },
        handlePageMenu(scrollY) {
            const pageMenu = document.getElementsByClassName('page-menu')[0];
            const banner = document.getElementsByClassName('page-banner')[0];
            const menuHideBox = document.getElementsByClassName('page-menu-hide-box')[0];
            // const footer = document.getElementsByTagName('footer')[0];
            if (!pageMenu) {
                return;
            }
            const bannerHeight = banner.offsetHeight;

            if (scrollY >= bannerHeight) {
                pageMenu.style.position = 'fixed';
                pageMenu.style.bottom = 'initial';
                pageMenu.style.top = '180px';
                menuHideBox.style.display = 'block';
                pageMenu.style.height = 'auto';
            } else {
                pageMenu.style.position = 'relative';
                menuHideBox.style.display = 'none';
                pageMenu.style.bottom = 'initial';
                pageMenu.style.top = 'initial';
                pageMenu.style.height = '100%';
            }
        },
    },
};
</script>

<style lang="scss">
* {
  font-family: 'Montserrat', 'Noto Sans TC', sans-serif;
  box-sizing: border-box !important;
}

.nav-padding {
  height: 100px;
}

@media screen and (max-width: 960px) {
  .scroll-top {
    display: none !important;
  }

  .sns-fixed {
    display: none !important;
  }
}
</style>
