var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "explain-block block" }, [
    _c("div", { staticClass: "block-title" }, [
      _c("div", { staticClass: "dot" }),
      _vm._v(" " + _vm._s(_vm.$t("invention.explain.title[0]")) + " "),
    ]),
    _c("div", { staticClass: "video" }, [
      _vm.content
        ? _c("iframe", {
            staticClass: "video-frame",
            attrs: {
              src: _vm.getVideo(_vm.content.video),
              title: "YouTube video player",
              frameborder: "0",
              allow:
                "accelerometer; autoplay; clipboard-write;\n        encrypted-media; gyroscope; picture-in-picture",
              allowfullscreen: "",
            },
          })
        : _vm._e(),
    ]),
    _c("div", { staticClass: "sub-block" }, [
      _c("div", { staticClass: "sub-title" }, [
        _vm._v(" " + _vm._s(_vm.$t("invention.explain.title[1]")) + " "),
      ]),
      _c("div", {
        staticClass: "block-content",
        domProps: { innerHTML: _vm._s(_vm.content.purpose) },
      }),
    ]),
    _c("div", { staticClass: "sub-block" }, [
      _c("div", { staticClass: "sub-title" }, [
        _vm._v(" " + _vm._s(_vm.$t("invention.explain.title[2]")) + " "),
        _c("div", { staticClass: "title-tips" }, [
          _vm._v(" " + _vm._s(_vm.$t("invention.explain.tips")) + " "),
        ]),
      ]),
      _vm._m(0),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "block-content theme" }, [
      _c("img", {
        staticClass: "theme-image",
        attrs: { src: require("../../assets/invention/theme.jpg"), alt: "" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }