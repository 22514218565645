var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "user-incubator-list" }, [
    _c("div", { staticClass: "block-title" }, [
      _c("div", { staticClass: "dot" }),
      _vm._v(" " + _vm._s(_vm.$t("user.UserIncubatorList.title")) + " "),
    ]),
    _c("div", { staticClass: "current" }, [
      _c("div", { staticClass: "block-sub-title" }, [
        _vm._v(" " + _vm._s(_vm.$t("user.UserIncubatorList.subtitle")) + " "),
      ]),
      _c(
        "div",
        { staticClass: "current-table-block apply-table" },
        [
          _c("v-data-table", {
            staticClass: "current-table",
            attrs: {
              headers: _vm.currentHeaders,
              items: _vm.current,
              "disable-pagination": true,
              "disable-filtering": true,
              "hide-default-footer": true,
              "disable-sort": true,
              "no-data-text": _vm.$t("user.UserIncubatorList.nodata"),
            },
            scopedSlots: _vm._u([
              {
                key: "item.status",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    item.status == "draft"
                      ? _c(
                          "div",
                          {
                            staticClass: "edit-btn action-btn",
                            on: {
                              click: function ($event) {
                                return _vm.editItem(item)
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("user.UserIncubatorList.draft")) +
                                " "
                            ),
                          ]
                        )
                      : _vm._e(),
                    item.status == "submit"
                      ? _c("div", { staticClass: "apply-btn action-btn" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("user.UserIncubatorList.submit")) +
                              " "
                          ),
                        ])
                      : _vm._e(),
                    item.status == "final"
                      ? _c(
                          "div",
                          {
                            staticClass: "upload-btn action-btn",
                            on: {
                              click: function ($event) {
                                return _vm.uploadFinal(item)
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("user.UserIncubatorList.final")) +
                                " "
                            ),
                          ]
                        )
                      : _vm._e(),
                    item.status == "final-submit"
                      ? _c("div", { staticClass: "apply-btn action-btn" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("user.UserIncubatorList.final-submit")
                              ) +
                              " "
                          ),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }