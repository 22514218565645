var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("footer", { staticClass: "footer" }, [
    _c("div", { staticClass: "content" }, [
      _c("div", { staticClass: "left" }, [
        _c("div", { staticClass: "top" }, [
          _c("img", {
            staticClass: "top-logo",
            attrs: { src: require("../assets/footer-logo.png"), alt: "" },
          }),
          _c(
            "div",
            { staticClass: "footer-scroll-top", on: { click: _vm.scrollTop } },
            [
              _c("v-icon", { staticClass: "top-icon" }, [
                _vm._v(" mdi-arrow-up "),
              ]),
              _c("div", { staticClass: "text" }, [_vm._v(" ＴＯＰ ")]),
            ],
            1
          ),
        ]),
        _c(
          "div",
          { staticClass: "menu is-desktop" },
          _vm._l(_vm.menuGroups, function (group) {
            return _c("div", { key: group.top.text, staticClass: "group" }, [
              _c("div", { staticClass: "top" }, [
                _c(
                  "a",
                  {
                    staticClass: "top-link",
                    attrs: { href: "/" + _vm.choseLanguage + group.top.to },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t(group.top.text)) + " ")]
                ),
              ]),
              _c(
                "div",
                { staticClass: "list" },
                _vm._l(group.list, function (item) {
                  return _c(
                    "router-link",
                    {
                      key: group.top.text + item.text,
                      staticClass: "item",
                      attrs: { to: "/" + _vm.choseLanguage + item.to },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t(item.text)) + " ")]
                  )
                }),
                1
              ),
            ])
          }),
          0
        ),
        _c("div", { staticClass: "bottom" }, [
          _c("div", { staticClass: "logo-item" }, [
            _c("div", { staticClass: "label" }, [
              _vm._v(" " + _vm._s(_vm.$t("footer.label[0]")) + "｜ "),
            ]),
            _c("img", {
              staticClass: "bottom-logo-1",
              attrs: {
                src: require("../assets/footer-bottom-logo-1.png"),
                alt: "",
              },
            }),
          ]),
          _c("div", { staticClass: "logo-item" }, [
            _c("div", { staticClass: "label" }, [
              _vm._v(" " + _vm._s(_vm.$t("footer.label[1]")) + "｜ "),
            ]),
            _c("img", {
              staticClass: "bottom-logo-2",
              attrs: {
                src: require("../assets/footer-bottom-logo-2.png"),
                alt: "",
              },
            }),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "right" }, [
        _vm._m(0),
        _c("div", { staticClass: "bottom" }, [
          _c(
            "div",
            { staticClass: "terms" },
            [
              _c("router-link", { attrs: { to: { name: "PolicyAndTerms" } } }, [
                _vm._v(" " + _vm._s(_vm.$t("footer.terms")) + " "),
              ]),
            ],
            1
          ),
          _c("div", { staticClass: "copy-right" }, [
            _vm._v(
              " Copyright © 2023 " +
                _vm._s(_vm.$t("footer.copyRight")) +
                " All Rights Reserved. "
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "top" }, [
      _c("div", { staticClass: "sns-box" }, [
        _c(
          "a",
          {
            staticClass: "sns",
            attrs: {
              target: "_blank",
              href: "https://www.facebook.com/visionfuture.org.tw",
            },
          },
          [
            _c("img", {
              attrs: { src: require("../assets/sns/fb-footer.png"), alt: "" },
            }),
          ]
        ),
        _c(
          "a",
          {
            staticClass: "sns",
            attrs: {
              target: "_blank",
              href: "https://www.instagram.com/visionfuturefund/",
            },
          },
          [
            _c("img", {
              attrs: { src: require("../assets/sns/ig-footer.png"), alt: "" },
            }),
          ]
        ),
        _c(
          "a",
          {
            staticClass: "sns",
            attrs: {
              target: "_blank",
              href: "https://www.youtube.com/channel/UCtCQpThY5bj9eS5XXIDsp3g",
            },
          },
          [
            _c("img", {
              attrs: { src: require("../assets/sns/YT-footer.png"), alt: "" },
            }),
          ]
        ),
        _c(
          "a",
          {
            staticClass: "sns",
            attrs: {
              target: "_blank",
              href: "mailto:hivisionfuture@tzuchi.org.tw",
            },
          },
          [
            _c("img", {
              attrs: { src: require("../assets/sns/mail-footer.png"), alt: "" },
            }),
          ]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }