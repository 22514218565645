var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "invention-team-data" },
    [
      _c(
        "v-form",
        {
          ref: "form",
          model: {
            value: _vm.valid,
            callback: function ($$v) {
              _vm.valid = $$v
            },
            expression: "valid",
          },
        },
        [
          _c("div", { staticClass: "apply-form-block" }, [
            _c("div", { staticClass: "left-block" }, [
              _c("div", { staticClass: "block-title" }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("InventionApply.TeamData.title[0]")) + " "
                ),
              ]),
            ]),
            _c("div", { staticClass: "form-content" }, [
              _c(
                "div",
                { staticClass: "form-row" },
                [
                  _c("v-text-field", {
                    staticClass: "border-orange-input form-input",
                    attrs: {
                      outlined: "",
                      label: _vm.$t("InventionApply.TeamData.label[0]"),
                      placeholder: "",
                      hint: _vm.$t("InventionApply.TeamData.hint[0]"),
                      "persistent-hint": "",
                      rules: _vm.twelveWordsRule,
                    },
                    model: {
                      value: _vm.form.team.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.team, "name", $$v)
                      },
                      expression: "form.team.name",
                    },
                  }),
                  _c("v-text-field", {
                    staticClass: "border-orange-input form-input",
                    attrs: {
                      outlined: "",
                      label: _vm.$t("InventionApply.TeamData.label[1]"),
                      placeholder: "",
                      hint: _vm.$t("InventionApply.TeamData.hint[1]"),
                      rules: _vm.emptyRule,
                      "persistent-hint": "",
                    },
                    model: {
                      value: _vm.form.team.school,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.team, "school", $$v)
                      },
                      expression: "form.team.school",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _c("div", { staticClass: "apply-form-block" }, [
            _c("div", { staticClass: "left-block" }, [
              _c("div", { staticClass: "block-title" }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("InventionApply.TeamData.title[1]")) + " "
                ),
              ]),
              _c("div", { staticClass: "tips" }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("InventionApply.TeamData.tips[0]")) + " "
                ),
              ]),
            ]),
            _c("div", { staticClass: "form-content" }, [
              _c("div", { staticClass: "form-action" }, [
                _c(
                  "div",
                  {
                    staticClass: "delete-btn btn",
                    on: { click: _vm.deleteTeacher },
                  },
                  [
                    _vm._v(
                      " - " +
                        _vm._s(_vm.$t("InventionApply.TeamData.deleteMember")) +
                        " "
                    ),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "add-btn btn", on: { click: _vm.addTeacher } },
                  [
                    _vm._v(
                      " + " +
                        _vm._s(_vm.$t("InventionApply.TeamData.addMember")) +
                        " "
                    ),
                  ]
                ),
              ]),
              _c(
                "div",
                { staticClass: "teacher-list" },
                _vm._l(_vm.form.team.teachers, function (item, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "teacher-item" },
                    [
                      _c(
                        "div",
                        { staticClass: "form-row" },
                        [
                          _c("v-text-field", {
                            staticClass: "border-orange-input form-input",
                            attrs: {
                              outlined: "",
                              label:
                                "" +
                                _vm.$t("InventionApply.TeamData.label[2]") +
                                (index + 1),
                              placeholder: "",
                              hint: _vm.$t("InventionApply.TeamData.hint[3]"),
                              "persistent-hint": "",
                              rules: _vm.emptyRule,
                            },
                            model: {
                              value: item.name,
                              callback: function ($$v) {
                                _vm.$set(item, "name", $$v)
                              },
                              expression: "item.name",
                            },
                          }),
                          _c("v-text-field", {
                            staticClass: "border-orange-input form-input",
                            attrs: {
                              outlined: "",
                              label: _vm.$t("InventionApply.TeamData.label[3]"),
                              placeholder: "",
                              hint: _vm.$t("InventionApply.TeamData.hint[3]"),
                              rules: _vm.phoneRule,
                              "persistent-hint": "",
                            },
                            model: {
                              value: item.phone,
                              callback: function ($$v) {
                                _vm.$set(item, "phone", $$v)
                              },
                              expression: "item.phone",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "form-row" },
                        [
                          _c("v-text-field", {
                            staticClass: "border-orange-input form-input",
                            attrs: {
                              outlined: "",
                              label: _vm.$t("InventionApply.TeamData.label[4]"),
                              placeholder: "",
                              hint: _vm.$t("InventionApply.TeamData.hint[3]"),
                              "persistent-hint": "",
                              rules: _vm.emptyRule,
                            },
                            model: {
                              value: item.title,
                              callback: function ($$v) {
                                _vm.$set(item, "title", $$v)
                              },
                              expression: "item.title",
                            },
                          }),
                          _c("v-text-field", {
                            staticClass: "border-orange-input form-input",
                            attrs: {
                              outlined: "",
                              label: _vm.$t("InventionApply.TeamData.label[5]"),
                              placeholder: "",
                              hint: _vm.$t("InventionApply.TeamData.hint[3]"),
                              rules: _vm.emailRule,
                              "persistent-hint": "",
                            },
                            model: {
                              value: item.email,
                              callback: function ($$v) {
                                _vm.$set(item, "email", $$v)
                              },
                              expression: "item.email",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  )
                }),
                0
              ),
            ]),
          ]),
          _c("div", { staticClass: "apply-form-block" }, [
            _c("div", { staticClass: "left-block" }, [
              _c("div", { staticClass: "block-title" }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("InventionApply.TeamData.title[2]")) + " "
                ),
              ]),
              _c("div", { staticClass: "tips" }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("InventionApply.TeamData.tips[1]")) + " "
                ),
              ]),
            ]),
            _c("div", { staticClass: "form-content" }, [
              _c("div", { staticClass: "form-action" }, [
                _c(
                  "div",
                  {
                    staticClass: "delete-btn btn",
                    on: { click: _vm.deleteMember },
                  },
                  [
                    _vm._v(
                      " - " +
                        _vm._s(_vm.$t("InventionApply.TeamData.deleteMember")) +
                        " "
                    ),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "add-btn btn", on: { click: _vm.addMember } },
                  [
                    _vm._v(
                      " + " +
                        _vm._s(_vm.$t("InventionApply.TeamData.addMember")) +
                        " "
                    ),
                  ]
                ),
              ]),
              _c(
                "div",
                { staticClass: "teacher-list" },
                _vm._l(_vm.form.team.members, function (item, index) {
                  return _c("div", { key: index, staticClass: "member-item" }, [
                    _c(
                      "div",
                      { staticClass: "form-row" },
                      [
                        _c("v-text-field", {
                          staticClass: "border-orange-input form-input",
                          attrs: {
                            outlined: "",
                            label: _vm.memberNameLabel(index),
                            placeholder: "",
                            hint: _vm.$t("InventionApply.TeamData.hint[3]"),
                            "persistent-hint": "",
                            rules: _vm.emptyRule,
                          },
                          model: {
                            value: item.name,
                            callback: function ($$v) {
                              _vm.$set(item, "name", $$v)
                            },
                            expression: "item.name",
                          },
                        }),
                        _c("v-text-field", {
                          staticClass: "border-orange-input form-input",
                          attrs: {
                            outlined: "",
                            label: _vm.$t("InventionApply.TeamData.label[8]"),
                            placeholder: "",
                            hint: _vm.$t("InventionApply.TeamData.hint[3]"),
                            rules: _vm.phoneRule,
                            "persistent-hint": "",
                          },
                          model: {
                            value: item.phone,
                            callback: function ($$v) {
                              _vm.$set(item, "phone", $$v)
                            },
                            expression: "item.phone",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "form-row" },
                      [
                        _c("v-text-field", {
                          staticClass: "border-orange-input form-input",
                          attrs: {
                            outlined: "",
                            label: _vm.$t("InventionApply.TeamData.label[9]"),
                            placeholder: "",
                            hint: _vm.$t("InventionApply.TeamData.hint[3]"),
                            "persistent-hint": "",
                            rules: _vm.emptyRule,
                          },
                          model: {
                            value: item.department,
                            callback: function ($$v) {
                              _vm.$set(item, "department", $$v)
                            },
                            expression: "item.department",
                          },
                        }),
                        _c("v-text-field", {
                          staticClass: "border-orange-input form-input",
                          attrs: {
                            outlined: "",
                            label: _vm.$t("InventionApply.TeamData.label[10]"),
                            placeholder: "",
                            hint: _vm.$t("InventionApply.TeamData.hint[3]"),
                            rules: _vm.emailRule,
                            "persistent-hint": "",
                          },
                          model: {
                            value: item.email,
                            callback: function ($$v) {
                              _vm.$set(item, "email", $$v)
                            },
                            expression: "item.email",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "form-row" },
                      [
                        _c("v-text-field", {
                          staticClass: "border-orange-input form-input",
                          attrs: {
                            outlined: "",
                            label: _vm.$t("InventionApply.TeamData.label[11]"),
                            placeholder: "",
                            hint: _vm.$t("InventionApply.TeamData.hint[3]"),
                            "persistent-hint": "",
                            rules: _vm.emptyRule,
                          },
                          model: {
                            value: item.grade,
                            callback: function ($$v) {
                              _vm.$set(item, "grade", $$v)
                            },
                            expression: "item.grade",
                          },
                        }),
                        _c(
                          "div",
                          { staticClass: "pdf-row" },
                          [
                            _c("v-file-input", {
                              staticClass: "border-orange-input form-input",
                              attrs: {
                                accept: ".pdf",
                                label: _vm.$t(
                                  "InventionApply.TeamData.label[12]"
                                ),
                                outlined: "",
                                placeholder: "",
                                hint: _vm.$t("InventionApply.TeamData.hint[2]"),
                                "persistent-hint": "",
                                rules: [_vm.pdfRule(index)],
                              },
                              on: {
                                change: function (file) {
                                  return _vm.handlePdfChange(
                                    "form.team.members[" + index + "].pdfFile",
                                    file,
                                    "form.team.members[" + index + "].tmpPdf"
                                  )
                                },
                              },
                              model: {
                                value: item.tmpPdf,
                                callback: function ($$v) {
                                  _vm.$set(item, "tmpPdf", $$v)
                                },
                                expression: "item.tmpPdf",
                              },
                            }),
                            _vm.form.team.members[index].pdfFile
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "preview-link",
                                    attrs: {
                                      href: _vm.$static(
                                        _vm.form.team.members[index].pdfFile
                                      ),
                                      target: "_blank",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("InventionApply.TeamData.link")
                                      )
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ])
                }),
                0
              ),
            ]),
          ]),
          _c("div", { staticClass: "apply-form-block team-image-block" }, [
            _c("div", { staticClass: "left-block" }, [
              _c("div", { staticClass: "block-title" }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("InventionApply.TeamData.title[3]")) + " "
                ),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "form-content" },
              [
                _c("div", { staticClass: "label" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("InventionApply.TeamData.label[13]")) +
                      " "
                  ),
                ]),
                _c(
                  "v-card",
                  {
                    staticClass: "preview-card rounded-lg",
                    attrs: { elevation: "4" },
                  },
                  [
                    _c(
                      "v-tooltip",
                      {
                        attrs: { top: "", "nudge-top": "-90" },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function (ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-img",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        staticClass: "og-image",
                                        attrs: {
                                          height: "300px",
                                          src: _vm.$static(
                                            _vm.form.team.previewImg
                                          ),
                                          contain: _vm.loading,
                                        },
                                      },
                                      "v-img",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _c("v-file-input", {
                                      staticClass: "upload pa-0 ma-0",
                                      attrs: {
                                        accept: "image/png, image/jpeg",
                                        "hide-input": "",
                                        "truncate-length": "0",
                                        "prepend-icon": "mdi-upload",
                                        dark: "",
                                      },
                                      on: { change: _vm.handleImageUpload },
                                      model: {
                                        value: _vm.teamImg,
                                        callback: function ($$v) {
                                          _vm.teamImg = $$v
                                        },
                                        expression: "teamImg",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.$t("InventionApply.TeamData.image"))
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "apply-form-block" }, [
            _c("div", { staticClass: "left-block" }, [
              _c("div", { staticClass: "block-title" }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("InventionApply.TeamData.title[4]")) + " "
                ),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "form-content" },
              [
                _c("v-checkbox", {
                  staticClass: "checkbox checkbox-title",
                  staticStyle: { "pointer-events": "none" },
                  attrs: {
                    label: _vm.$t("InventionApply.TeamData.checkbox-q[0]"),
                    rules: _vm.refersRule,
                  },
                  model: {
                    value: _vm.form.team.refers,
                    callback: function ($$v) {
                      _vm.$set(_vm.form.team, "refers", $$v)
                    },
                    expression: "form.team.refers",
                  },
                }),
                _c("v-checkbox", {
                  staticClass: "checkbox",
                  attrs: {
                    label: _vm.$t("InventionApply.TeamData.checkbox[0][0]"),
                    value: "學校活動公告(學校官網、海報、育成中心等)",
                    color: "#F97E2B",
                  },
                  model: {
                    value: _vm.form.team.refers,
                    callback: function ($$v) {
                      _vm.$set(_vm.form.team, "refers", $$v)
                    },
                    expression: "form.team.refers",
                  },
                }),
                _c("v-checkbox", {
                  staticClass: "checkbox",
                  attrs: {
                    label: _vm.$t("InventionApply.TeamData.checkbox[0][1]"),
                    value: "獎金獵人平台",
                    color: "#F97E2B",
                  },
                  model: {
                    value: _vm.form.team.refers,
                    callback: function ($$v) {
                      _vm.$set(_vm.form.team, "refers", $$v)
                    },
                    expression: "form.team.refers",
                  },
                }),
                _c("v-checkbox", {
                  staticClass: "checkbox",
                  attrs: {
                    label: _vm.$t("InventionApply.TeamData.checkbox[0][2]"),
                    value: "FUN大視野官網",
                    color: "#F97E2B",
                  },
                  model: {
                    value: _vm.form.team.refers,
                    callback: function ($$v) {
                      _vm.$set(_vm.form.team, "refers", $$v)
                    },
                    expression: "form.team.refers",
                  },
                }),
                _c("v-checkbox", {
                  staticClass: "checkbox",
                  attrs: {
                    label: _vm.$t("InventionApply.TeamData.checkbox[0][3]"),
                    value: "FUN大視野FACEBOOK",
                    color: "#F97E2B",
                  },
                  model: {
                    value: _vm.form.team.refers,
                    callback: function ($$v) {
                      _vm.$set(_vm.form.team, "refers", $$v)
                    },
                    expression: "form.team.refers",
                  },
                }),
                _c("v-checkbox", {
                  staticClass: "checkbox",
                  attrs: {
                    label: _vm.$t("InventionApply.TeamData.checkbox[0][4]"),
                    value: "FUN大視野INSTAGRAM",
                    color: "#F97E2B",
                  },
                  model: {
                    value: _vm.form.team.refers,
                    callback: function ($$v) {
                      _vm.$set(_vm.form.team, "refers", $$v)
                    },
                    expression: "form.team.refers",
                  },
                }),
                _c(
                  "div",
                  { staticStyle: { display: "flex", "flex-wrap": "wrap" } },
                  [
                    _c("v-checkbox", {
                      staticClass: "checkbox",
                      attrs: {
                        label: _vm.$t("InventionApply.TeamData.checkbox[0][5]"),
                        value: "歷屆團隊推薦，團隊名:",
                        color: "#F97E2B",
                      },
                      model: {
                        value: _vm.form.team.refers,
                        callback: function ($$v) {
                          _vm.$set(_vm.form.team, "refers", $$v)
                        },
                        expression: "form.team.refers",
                      },
                    }),
                    _c("v-text-field", {
                      staticClass:
                        "checkbox-input border-orange-input form-input",
                      model: {
                        value: _vm.refers1,
                        callback: function ($$v) {
                          _vm.refers1 = $$v
                        },
                        expression: "refers1",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticStyle: { display: "flex", "flex-wrap": "wrap" } },
                  [
                    _c("v-checkbox", {
                      staticClass: "checkbox",
                      attrs: {
                        label: _vm.$t("InventionApply.TeamData.checkbox[0][6]"),
                        value: "親朋好友推薦，推薦人:",
                        color: "#F97E2B",
                      },
                      model: {
                        value: _vm.form.team.refers,
                        callback: function ($$v) {
                          _vm.$set(_vm.form.team, "refers", $$v)
                        },
                        expression: "form.team.refers",
                      },
                    }),
                    _c("v-text-field", {
                      staticClass:
                        "checkbox-input border-orange-input form-input",
                      model: {
                        value: _vm.refers2,
                        callback: function ($$v) {
                          _vm.refers2 = $$v
                        },
                        expression: "refers2",
                      },
                    }),
                  ],
                  1
                ),
                _c("v-checkbox", {
                  staticClass: "checkbox",
                  attrs: {
                    label: _vm.$t("InventionApply.TeamData.checkbox[0][7]"),
                    value: "曾經提案過",
                    color: "#F97E2B",
                  },
                  model: {
                    value: _vm.form.team.refers,
                    callback: function ($$v) {
                      _vm.$set(_vm.form.team, "refers", $$v)
                    },
                    expression: "form.team.refers",
                  },
                }),
                _c("v-checkbox", {
                  staticClass: "checkbox",
                  attrs: {
                    label: _vm.$t("InventionApply.TeamData.checkbox[0][8]"),
                    value: "原本就知道",
                    color: "#F97E2B",
                  },
                  model: {
                    value: _vm.form.team.refers,
                    callback: function ($$v) {
                      _vm.$set(_vm.form.team, "refers", $$v)
                    },
                    expression: "form.team.refers",
                  },
                }),
                _c("v-checkbox", {
                  staticClass: "checkbox",
                  attrs: {
                    label: _vm.$t("InventionApply.TeamData.checkbox[0][9]"),
                    value: "慈濟基金會宣傳平台(官網、IG、LINE、大愛台等)",
                    color: "#F97E2B",
                  },
                  model: {
                    value: _vm.form.team.refers,
                    callback: function ($$v) {
                      _vm.$set(_vm.form.team, "refers", $$v)
                    },
                    expression: "form.team.refers",
                  },
                }),
                _c(
                  "div",
                  { staticStyle: { display: "flex", "flex-wrap": "wrap" } },
                  [
                    _c("v-checkbox", {
                      staticClass: "checkbox",
                      attrs: {
                        label: _vm.$t(
                          "InventionApply.TeamData.checkbox[0][10]"
                        ),
                        value: "其他:",
                        color: "#F97E2B",
                      },
                      model: {
                        value: _vm.form.team.refers,
                        callback: function ($$v) {
                          _vm.$set(_vm.form.team, "refers", $$v)
                        },
                        expression: "form.team.refers",
                      },
                    }),
                    _c("v-text-field", {
                      staticClass:
                        "checkbox-input border-orange-input form-input",
                      model: {
                        value: _vm.refers3,
                        callback: function ($$v) {
                          _vm.refers3 = $$v
                        },
                        expression: "refers3",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "apply-form-block" }, [
            _c("div", { staticClass: "left-block" }),
            _c("div", { staticClass: "form-content" }, [
              _c("div", { staticClass: "actions" }, [
                _c(
                  "div",
                  {
                    staticClass: "btn white-btn",
                    on: { click: _vm.resetForm },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("InventionApply.TeamData.button[0]")) +
                        " "
                    ),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "btn blue-btn", on: { click: _vm.save } },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("InventionApply.TeamData.button[1]")) +
                        " "
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "btn orange-btn",
                    on: { click: _vm.nextStep },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("InventionApply.TeamData.button[2]")) +
                        " "
                    ),
                  ]
                ),
              ]),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }