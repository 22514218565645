var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "incubator-final-upload" },
    [
      _c(
        "v-form",
        {
          ref: "form",
          model: {
            value: _vm.valid,
            callback: function ($$v) {
              _vm.valid = $$v
            },
            expression: "valid",
          },
        },
        [
          _c("div", { staticClass: "apply-form-block" }, [
            _c("div", { staticClass: "left-block" }, [
              _c("div", { staticClass: "block-title" }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("IncubatorFinal.upload.title[0]")) + " "
                ),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "form-content" },
              [
                _c("v-file-input", {
                  staticClass: "border-orange-input form-input",
                  attrs: {
                    accept: ".pdf",
                    label: _vm.$t("IncubatorFinal.upload.label"),
                    outlined: "",
                    placeholder: "",
                    hint: _vm.$t("IncubatorFinal.upload.hint"),
                    "persistent-hint": "",
                    rules: [_vm.pdfRule()],
                  },
                  model: {
                    value: _vm.pdfFile,
                    callback: function ($$v) {
                      _vm.pdfFile = $$v
                    },
                    expression: "pdfFile",
                  },
                }),
                _vm.tmpPdf
                  ? _c("div", { staticClass: "preivew" }, [
                      _c(
                        "a",
                        {
                          staticClass: "preview-link",
                          attrs: {
                            href: _vm.$static(_vm.tmpPdf),
                            target: "_blank",
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("IncubatorFinal.upload.link")))]
                      ),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "apply-form-block" }, [
            _c("div", { staticClass: "left-block" }, [
              _c("div", { staticClass: "block-title" }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("IncubatorFinal.upload.title[1]")) + " "
                ),
              ]),
            ]),
            _c("div", { staticClass: "form-content" }, [
              _c("ol", { staticClass: "tips" }, [
                _c("li", [
                  _vm._v(_vm._s(_vm.$t("IncubatorFinal.upload.tips[0]"))),
                ]),
                _c("li", [
                  _vm._v(
                    " " + _vm._s(_vm.$t("IncubatorFinal.upload.tips[1]")) + " "
                  ),
                ]),
                _c("li", [
                  _vm._v(
                    " " + _vm._s(_vm.$t("IncubatorFinal.upload.tips[2]")) + " "
                  ),
                ]),
                _c("li", [
                  _vm._v(_vm._s(_vm.$t("IncubatorFinal.upload.tips[3]"))),
                ]),
                _c("li", [
                  _vm._v(
                    " " + _vm._s(_vm.$t("IncubatorFinal.upload.tips[4]")) + " "
                  ),
                ]),
              ]),
            ]),
          ]),
        ]
      ),
      _c("div", { staticClass: "apply-form-block" }, [
        _c("div", { staticClass: "left-block" }),
        _c("div", { staticClass: "form-content" }, [
          _c("div", { staticClass: "actions" }, [
            _c(
              "div",
              { staticClass: "btn orange-btn", on: { click: _vm.nextStep } },
              [
                _vm._v(
                  " " + _vm._s(_vm.$t("IncubatorFinal.upload.button")) + " "
                ),
              ]
            ),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }