var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "q-and-a block" }, [
    _c("div", { staticClass: "block-title" }, [
      _c("div", { staticClass: "dot" }),
      _vm._v(" " + _vm._s(_vm.$t("invention.QandA.title")) + " "),
    ]),
    _c("div", { staticClass: "sub-block" }, [
      _c(
        "div",
        { staticClass: "panels" },
        [
          _c(
            "v-expansion-panels",
            { attrs: { multiple: "", accordion: "" } },
            _vm._l(_vm.tmpQuestion, function (item, i) {
              return _c(
                "v-expansion-panel",
                { key: i, staticClass: "panel-item" },
                [
                  _c("v-expansion-panel-header", { staticClass: "question" }, [
                    _vm._v(" " + _vm._s(item.title) + " "),
                  ]),
                  _c("v-expansion-panel-content", { staticClass: "answer" }, [
                    _vm._v(" " + _vm._s(item.content) + " "),
                  ]),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
      !_vm.more
        ? _c(
            "div",
            { staticClass: "more", on: { click: _vm.handleMore } },
            [
              _c("div", { staticClass: "text color-orange" }, [
                _vm._v(" " + _vm._s(_vm.$t("invention.QandA.more")) + " "),
              ]),
              _c("v-icon", { staticClass: "icon" }, [
                _vm._v(" mdi-chevron-down "),
              ]),
            ],
            1
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }