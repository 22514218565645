var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "card-group" },
      _vm._l(_vm.listData, function (item, index) {
        return _c(
          "div",
          {
            key: index,
            staticClass: "card-item",
            on: {
              click: function ($event) {
                return _vm.goToLink(item)
              },
            },
          },
          [
            !_vm.showData
              ? _c("v-progress-circular", {
                  attrs: { indeterminate: "", color: "orange" },
                })
              : _vm._e(),
            _vm.showData
              ? _c(
                  "v-card",
                  { staticClass: "mx-auto", attrs: { width: "280px" } },
                  [
                    _c("v-img", {
                      staticClass: "white--text align-end",
                      attrs: {
                        width: "280px",
                        height: "210px",
                        src: _vm.image(item.image),
                      },
                    }),
                    _c(
                      "div",
                      {
                        staticClass: "card-content",
                        class: { border: _vm.captionOpen },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "card-title",
                            class: {
                              "title-center": !_vm.captionOpen,
                              "title-justify": _vm.captionOpen,
                            },
                          },
                          [_vm._v(" " + _vm._s(item.title) + " ")]
                        ),
                        _vm.captionOpen
                          ? _c("div", { staticClass: "card-caption" }, [
                              _vm._v(" " + _vm._s(item.description) + " "),
                            ])
                          : _vm._e(),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        )
      }),
      0
    ),
    _c("div", { staticClass: "more" }, [
      _vm.more === false
        ? _c(
            "div",
            { staticClass: "more-text mt-5", on: { click: _vm.handleMore } },
            [
              _c("span", { staticClass: "color-lightgrey" }, [
                _vm._v(_vm._s(_vm.$t("ThreeBlock.more"))),
              ]),
              _c(
                "v-icon",
                { attrs: { large: "", color: "selectGray darken-2" } },
                [_vm._v(" mdi-chevron-down ")]
              ),
            ],
            1
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }