var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "change-password" }, [
    _c("div", { staticClass: "block-title" }, [
      _c("div", { staticClass: "dot" }),
      _vm._v(" " + _vm._s(_vm.$t("user.ChangePassword.title")) + " "),
    ]),
    _c(
      "div",
      { staticClass: "form-data" },
      [
        _c(
          "v-form",
          {
            ref: "form",
            model: {
              value: _vm.valid,
              callback: function ($$v) {
                _vm.valid = $$v
              },
              expression: "valid",
            },
          },
          [
            _c("v-text-field", {
              staticClass: "border-orange-input",
              attrs: {
                type: _vm.showOldPWD ? "text" : "password",
                "append-icon": _vm.showOldPWD ? "mdi-eye" : "mdi-eye-off",
                outlined: "",
                label: _vm.$t("user.ChangePassword.label[0]"),
                placeholder: "",
                hint: _vm.$t("user.ChangePassword.hint[0]"),
                "persistent-hint": "",
                rules: _vm.passwordRule,
              },
              on: {
                "click:append": function ($event) {
                  _vm.showOldPWD = !_vm.showOldPWD
                },
              },
              model: {
                value: _vm.oldPassword,
                callback: function ($$v) {
                  _vm.oldPassword = $$v
                },
                expression: "oldPassword",
              },
            }),
            _c("v-text-field", {
              staticClass: "border-orange-input",
              attrs: {
                type: _vm.showNewPWD ? "text" : "password",
                "append-icon": _vm.showNewPWD ? "mdi-eye" : "mdi-eye-off",
                outlined: "",
                label: _vm.$t("user.ChangePassword.label[1]"),
                placeholder: "",
                hint: _vm.$t("user.ChangePassword.hint[0]"),
                "persistent-hint": "",
                rules: _vm.passwordRule,
              },
              on: {
                "click:append": function ($event) {
                  _vm.showNewPWD = !_vm.showNewPWD
                },
              },
              model: {
                value: _vm.newPassword,
                callback: function ($$v) {
                  _vm.newPassword = $$v
                },
                expression: "newPassword",
              },
            }),
            _c("v-text-field", {
              staticClass: "border-orange-input",
              attrs: {
                type: _vm.showRePWD ? "text" : "password",
                "append-icon": _vm.showRePWD ? "mdi-eye" : "mdi-eye-off",
                outlined: "",
                label: _vm.$t("user.ChangePassword.label[2]"),
                placeholder: "",
                hint: _vm.$t("user.ChangePassword.hint[0]"),
                "persistent-hint": "",
                rules: _vm.rePasswordRule,
              },
              on: {
                "click:append": function ($event) {
                  _vm.showRePWD = !_vm.showRePWD
                },
              },
              model: {
                value: _vm.rePassword,
                callback: function ($$v) {
                  _vm.rePassword = $$v
                },
                expression: "rePassword",
              },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "action" }, [
          _c(
            "div",
            {
              staticClass: "btn cancel-btn color-white",
              on: { click: _vm.cleanAll },
            },
            [_vm._v(" " + _vm._s(_vm.$t("user.ChangePassword.clean")) + " ")]
          ),
          _c(
            "div",
            {
              staticClass: "btn bg-orange color-white",
              on: { click: _vm.changePassword },
            },
            [_vm._v(" " + _vm._s(_vm.$t("user.ChangePassword.save")) + " ")]
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }