import { render, staticRenderFns } from "./Register.vue?vue&type=template&id=7674e665&"
import script from "./Register.vue?vue&type=script&lang=js&"
export * from "./Register.vue?vue&type=script&lang=js&"
import style0 from "./Register.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VStepper } from 'vuetify/lib/components/VStepper';
import { VStepperHeader } from 'vuetify/lib/components/VStepper';
import { VStepperStep } from 'vuetify/lib/components/VStepper';
installComponents(component, {VDivider,VStepper,VStepperHeader,VStepperStep})


/* hot reload */
if (module.hot) {
  var api = require("/Users/songlian/Developer/官網/慈濟/vision-future-web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7674e665')) {
      api.createRecord('7674e665', component.options)
    } else {
      api.reload('7674e665', component.options)
    }
    module.hot.accept("./Register.vue?vue&type=template&id=7674e665&", function () {
      api.rerender('7674e665', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Register/Register.vue"
export default component.exports