<template>
    <div class="teacher-list-block block">
        <div class="block-title">
            <div class="dot" />
            {{ $t('incubator.teacher.title') }}
        </div>
        <div class="sub-block">
            <div class="swiper swiper-teacher">
                <div class="swiper-wrapper">
                    <!-- Slides -->
                    <div
                        v-for="(item, index) in teacher"
                        :key="index"
                        class="swiper-slide"
                    >
                        <div class="content">
                            <img
                                :src="image(item.image)"
                                alt=""
                            >
                            <div class="name color-blue">
                                {{ item.name }}
                            </div>
                            <div class="text color-orange">
                                {{ item.unit }}
                            </div>
                            <div class="position color-orange">
                                {{ item.position }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="swiper-button-prev invention-swiper-button-prev" />
                <div class="swiper-button-next invention-swiper-button-next" />
            </div>
        </div>
    </div>
</template>

<script>
import Swiper, { Navigation } from 'swiper';
import '@/assets/css/swiper-bundle.min.css';

Swiper.use([Navigation]);
export default {
    components: {},
    props: {
        teacher: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
        };
    },
    computed: {},
    created() {},
    mounted() {
        this.initSwiper();
    },
    methods: {
        initSwiper() {
            let slidesPerView = 3;
            const windowWidth = this.windowInnerWidth();

            if (windowWidth <= 1280) {
                slidesPerView = 2;
            }

            if (windowWidth <= 600) {
                slidesPerView = 1;
            }

            // eslint-disable-next-line no-new
            new Swiper('.swiper-teacher', {
                loop: true,
                slidesPerView,
                spaceBetween: 0,
                navigation: {
                    nextEl: '.invention-swiper-button-next',
                    prevEl: '.invention-swiper-button-prev',
                },
                autoplay: false,
            });
        },
        windowInnerWidth() {
            return window.innerWidth;
        },
        image(item) {
            const file = `https://${process.env.VUE_APP_BASE_URL + item}`;
            return file;
        },
    },
};
</script>

<style lang="scss" scoped>
  .teacher-list-block {
    .swiper-wrapper {
      padding: 0 80px;
    }
    .swiper-slide {
      .content {
        background-size: contain;
        background-repeat: no-repeat;
        width: 200px;
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        flex-shrink: 0;

        img {
          width: 200px;
          height: 200px;
          border-radius: 100px;
          margin-bottom: 30px;
        }

        .name {
          margin-bottom: 10px;
          font-weight: 700;
          font-size: 16px;
          line-height: 1;
          letter-spacing: 0.1em;
          text-align: center;
        }

        .text {
          font-weight: 700;
          font-size: 16px;
          line-height: 2;
          letter-spacing: 0.1em;
          text-align: center;
        }

        .position{
          font-weight: 700;
          font-size: 16px;
          line-height: 2;
          letter-spacing: 0.1em;
          text-align: center;
        }
      }
    }

    .invention-swiper-button-next,
    .invention-swiper-button-prev {
      --swiper-theme-color: #f97e2b;
    }
  }
  @media screen and (max-width: 960px) {
    .recommend-block {
      .swiper-wrapper {
        padding: 0;
      }
      .swiper-slide {
        display: flex;
        justify-content: center;
        width: 100%;
      }
    }
  }

  @media screen and (max-width: 600px) {
    .recommend-block {
      .swiper-slide {
        .content {
        width: 350px;
        height: 155px;
        padding: 5px 45px;

        .text {

          font-size: 14px;
        }
      }
      }
    }
  }
  </style>
