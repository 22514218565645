<template>
    <div class="home page">
        <div class="swiper-block">
            <div class="swiper banner-swiper">
                <div class="swiper-wrapper">
                    <div
                        v-for="(item, index) in banner"
                        :key="item.id"
                        class="swiper-slide"
                        :data-swiper-slide-index="index"
                    >
                        <img
                            class="is-desktop"
                            :src="image(item.imageDes)"
                            alt=""
                        >
                        <img
                            class="is-mobile"
                            :src="image(item.imageMob)"
                            alt=""
                        >
                    </div>
                </div>
                <div class="swiper-pagination banner-swiper-pagination" />
                <div class="swiper-button-prev banner-swiper-button-prev" />
                <div class="swiper-button-next banner-swiper-button-next" />
            </div>
        </div>
        <div class="web-content">
            <Marquee />
            <Block1 />
            <Block2 />
            <Block3 />
            <Block4 />
            <Block5 />
            <Block6 />
            <Block7 />
        </div>
    </div>
</template>

<script>
import API from '@/api';
import { mapMutations } from 'vuex';
import Marquee from '@/components/home/Marquee.vue';
import Swiper, { Autoplay, Pagination, Navigation } from 'swiper';
import Block1 from '@/components/home/Block1.vue';
import Block2 from '@/components/home/Block2.vue';
import Block3 from '@/components/home/Block3.vue';
import Block4 from '@/components/home/Block4.vue';
import Block5 from '@/components/home/Block5.vue';
import Block6 from '@/components/home/Block6.vue';
import Block7 from '@/components/home/Block7.vue';
import '@/assets/css/swiper-bundle.min.css';

Swiper.use([Autoplay, Pagination, Navigation]);

export default {
    components: {
        Marquee,
        Block1,
        Block2,
        Block3,
        Block4,
        Block5,
        Block6,
        Block7,
    },
    data() {
        return {
            banner: [],
        };
    },
    computed: {},
    watch: {
        $route() {
            this.init();
        },
    },
    mounted() {
        this.init();
        this.initSwiper();
    },
    methods: {
        ...mapMutations('mNotification', ['setAlert', 'handleError']),
        async init() {
            try {
                const res = await API.HomeBanner.PublicList({
                    headers: {
                        'Content-Language': (this.$route.path.split('/')[1] || 'zhTw') === 'en' ? 'en' : 'zhTw',
                    },
                });
                this.banner = res.data.raw;
                this.showData = true;
            } catch (e) {
                this.handleError({
                    message: e || this.$t('alertMessage.getdata-fail'),
                    color: 'error',
                });
            }
        },
        initSwiper() {
            // eslint-disable-next-line no-new
            new Swiper('.banner-swiper', {
                loop: true,
                pagination: {
                    el: '.banner-swiper-pagination',
                },
                navigation: {
                    nextEl: '.banner-swiper-button-next',
                    prevEl: '.banner-swiper-button-prev',
                },
                autoplay: {
                    delay: 5000,
                    stopOnLastSlide: false,
                    disableOnInteraction: false,
                },
            });
        },
        image(item) {
            const file = `https://${process.env.VUE_APP_BASE_URL + item}`;
            return file;
        },
    },
};
</script>

<style lang="scss">
.home {
  color: $color-gray;
  .is-mobile {
    display: none;
  }

  .web-content{
    max-width: 100% !important;
  }

  .swiper-block {
    width: 100vw;
    margin-bottom: 45px;
  }
  .banner-swiper {
    width: 100vw;
    height: 300/1440*100vw;
    overflow: hidden;

    .swiper-slide {
      img {
        width: 100vw;
        object-fit: contain;
      }
    }

    .swiper-pagination-bullet {
      background-color: white;
    }

    .swiper-pagination-bullet-active {
      --swiper-theme-color: #f97e2b;
      background-color: #f97e2b;
    }

    .swiper-button-next,
    .swiper-button-prev {
      --swiper-theme-color: #f97e2b;
    }
  }

  .home-block {
    .home-block-title {
      font-size: 45px;
      font-weight: 700;
      line-height: 1;
      letter-spacing: 0.1em;
      color: $color-blue;
      text-align: center;
    }

    .home-block-subtitle {
      font-size: 28px;
      font-weight: 700;
      line-height: 1;
      letter-spacing: 0.1em;
      color: $color-gray;
      text-align: center;
    }
  }
}

@media screen and (max-width: 960px) {
  .home {
    .banner-swiper {
        height: 200/375*100vw;
      .is-desktop {
        display: none !important;
      }
      .is-mobile {
        display: block;
      }
    }

    .home-block {
      .home-block-title {
        font-size: 28px;
      }

      .home-block-subtitle {
        font-size: 21px;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .home {
    .swiper-block {
      margin-bottom: 30px;
    }

    .home-block {
      .block-title {
        font-size: 21px;
      }

      .block-subtitle {
        font-size: 16px;
      }
    }
  }
}
</style>
