var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "forget-password page-center" }, [
    _c("div", { staticClass: "web-content" }, [
      _c("div", { staticClass: "process-structure" }, [
        _c("div", { staticClass: "auth-process-body" }, [
          _c("div", { staticClass: "process-page-title-row" }, [
            _c("div", { staticClass: "process-page-title" }, [
              _vm._v(" " + _vm._s(_vm.$t("auth.ForgetPassword.title")) + " "),
            ]),
          ]),
          _c("div", { staticClass: "title-tips" }, [
            _vm._v(" " + _vm._s(_vm.$t("auth.ForgetPassword.tips")) + " "),
          ]),
          _c("div", { staticClass: "auth-form-block" }, [
            _c(
              "div",
              { staticClass: "form" },
              [
                _c(
                  "v-form",
                  {
                    ref: "form",
                    model: {
                      value: _vm.valid,
                      callback: function ($$v) {
                        _vm.valid = $$v
                      },
                      expression: "valid",
                    },
                  },
                  [
                    _c("v-text-field", {
                      staticClass: "border-orange-input",
                      attrs: {
                        outlined: "",
                        label: _vm.$t("auth.ForgetPassword.label"),
                        placeholder: "",
                        hint: "",
                        "persistent-hint": "",
                        rules: _vm.emailRule,
                      },
                      model: {
                        value: _vm.forgotForm.account,
                        callback: function ($$v) {
                          _vm.$set(_vm.forgotForm, "account", $$v)
                        },
                        expression: "forgotForm.account",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "submit-btn",
                    class: { "btn-disable": !_vm.confirmEnable },
                    on: { click: _vm.forgot },
                  },
                  [
                    _vm._v(" " + _vm._s(_vm.$t("auth.ForgetPassword.button"))),
                    !_vm.confirmEnable
                      ? _c("span", [_vm._v("(" + _vm._s(_vm.count) + ")")])
                      : _vm._e(),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }