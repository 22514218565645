var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "nav" }, [
    _c("div", { staticClass: "content desktop" }, [
      _c("img", {
        staticClass: "logo",
        attrs: { src: require("../assets/logo.png"), alt: "logo" },
        on: {
          click: function ($event) {
            return _vm.goto("Home")
          },
        },
      }),
      _c(
        "div",
        { staticClass: "menu is-desktop" },
        _vm._l(_vm.menu, function (item, index) {
          return _c(
            "router-link",
            {
              key: index,
              staticClass: "item",
              class: { "router-active": _vm.activeMenuItem === item.name },
              attrs: { to: "/" + _vm.choseLanguage + item.to },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "text font-size-small line-height-1-5 font-weight-bold",
                },
                [_vm._v(" " + _vm._s(_vm.$t(item.text)) + " ")]
              ),
            ]
          )
        }),
        1
      ),
      _c(
        "div",
        { staticClass: "action is-desktop" },
        [
          !_vm.isLogin
            ? _c(
                "router-link",
                {
                  staticClass:
                    "login font-size-small line-height-1 font-weight-bold color-gray",
                  attrs: { to: { name: "Login" } },
                },
                [_vm._v(" " + _vm._s(_vm.$t("nav.login")) + " ")]
              )
            : _c(
                "v-menu",
                {
                  attrs: { "offset-y": "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function (ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  staticClass: "profile-btn",
                                  attrs: { color: "#F97E2B", elevation: "0" },
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [
                              _c("v-icon", { staticClass: "profile-icon" }, [
                                _vm._v(" mdi-account-circle-outline "),
                              ]),
                              _c("span", { staticClass: "user-name" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      "" +
                                        _vm.userData.data.lastName +
                                        _vm.userData.data.firstName
                                    )
                                ),
                              ]),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c(
                    "v-list",
                    [
                      _c(
                        "v-list-item",
                        { attrs: { to: { name: "Profile" } } },
                        [
                          _c(
                            "v-list-item-title",
                            {
                              staticClass:
                                "color-orange font-bold font-size-small",
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("nav.member")) + " ")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        { on: { click: _vm.logout } },
                        [
                          _c(
                            "v-list-item-title",
                            {
                              staticClass:
                                "color-orange font-bold font-size-small",
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("nav.logout")) + " ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
          _c("div", { staticClass: "divide" }),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.choseLanguage,
                  expression: "choseLanguage",
                },
              ],
              staticClass:
                "language font-size-small line-height-1 font-weight-bold",
              attrs: { id: "", name: "" },
              on: {
                change: [
                  function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.choseLanguage = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  _vm.webLanguage,
                ],
              },
            },
            [
              _c("option", { attrs: { value: "zhTw" } }, [
                _vm._v(" " + _vm._s(_vm.$t("nav.chinese")) + " "),
              ]),
              _c("option", { attrs: { value: "en" } }, [
                _vm._v(" " + _vm._s(_vm.$t("nav.english")) + " "),
              ]),
            ]
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "hamburger is-mobile",
          class: { open: _vm.mobileMenuShow },
          on: { click: _vm.clickHamburger },
        },
        [_c("span"), _c("span"), _c("span")]
      ),
    ]),
    _c(
      "div",
      { staticClass: "mobile-menu", class: { open: _vm.mobileMenuShow } },
      [
        _c("div", { staticClass: "mobile-content" }, [
          _c(
            "div",
            { staticClass: "member" },
            [
              !_vm.isLogin
                ? _c(
                    "router-link",
                    {
                      staticClass: "login line-height-1 font-weight-bold",
                      attrs: { to: { name: "Login" } },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("nav.login")) + " ")]
                  )
                : _c("div", { staticClass: "member-detail" }, [
                    _c(
                      "div",
                      { staticClass: "detail" },
                      [
                        _c("v-icon", { staticClass: "profile-icon" }, [
                          _vm._v(" mdi-account-circle-outline "),
                        ]),
                        _c("span", { staticClass: "user-name" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                "" +
                                  _vm.userData.data.lastName +
                                  _vm.userData.data.firstName
                              )
                          ),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "action" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "action-item",
                            attrs: { to: { name: "Profile" } },
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("nav.member")) + " ")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "action-item",
                            on: { click: _vm.logout },
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("nav.logout")) + " ")]
                        ),
                      ],
                      1
                    ),
                  ]),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "menu" },
            _vm._l(_vm.menu, function (item, index) {
              return _c(
                "router-link",
                {
                  key: index,
                  staticClass: "item",
                  class: { "router-active": _vm.activeMenuItem === item.name },
                  attrs: { to: "/" + _vm.choseLanguage + item.to },
                },
                [
                  _c(
                    "div",
                    { staticClass: "text line-height-1-5 font-weight-bold" },
                    [_vm._v(" " + _vm._s(_vm.$t(item.text)) + " ")]
                  ),
                ]
              )
            }),
            1
          ),
          _c("div", { staticClass: "language" }, [
            _c(
              "span",
              { staticClass: "label line-height-1-5 font-weight-bold" },
              [
                _vm._v(_vm._s(_vm.$t("nav.language"))),
                _c("v-icon", { staticClass: "icon" }, [
                  _vm._v("mdi-chevron-right"),
                ]),
              ],
              1
            ),
            _c(
              "button",
              {
                staticClass: "item line-height-1-5 font-weight-bold",
                class: {
                  active: _vm.choseLanguage === "zhTw",
                  unactive: _vm.choseLanguage !== "zhTw",
                },
                on: {
                  click: function ($event) {
                    return _vm.mobileLanguage("zhTw")
                  },
                },
              },
              [_vm._v(" " + _vm._s(_vm.$t("nav.chinese")) + " ")]
            ),
            _c(
              "button",
              {
                staticClass: "item line-height-1-5 font-weight-bold",
                class: {
                  active: _vm.choseLanguage === "en",
                  unactive: _vm.choseLanguage !== "en",
                },
                on: {
                  click: function ($event) {
                    return _vm.mobileLanguage("en")
                  },
                },
              },
              [_vm._v(" " + _vm._s(_vm.$t("nav.english")) + " ")]
            ),
          ]),
        ]),
        _c("div", { staticClass: "footer" }, [
          _vm._m(0),
          _c(
            "div",
            {
              staticClass:
                "terms font-size-small line-height-2 font-weight-bold",
            },
            [
              _c("router-link", { attrs: { to: "" } }, [
                _vm._v(" " + _vm._s(_vm.$t("nav.terms")) + " "),
              ]),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass:
                "copy-right font-size-small line-height-2 font-weight-bold",
            },
            [
              _vm._v(
                " Copyright © 2023 " +
                  _vm._s(_vm.$t("nav.copyRight")) +
                  " All Rights Reserved. "
              ),
            ]
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "sns-box" }, [
      _c(
        "a",
        {
          staticClass: "sns",
          attrs: {
            href: "https://www.facebook.com/visionfuture.org.tw",
            target: "_blank",
          },
        },
        [
          _c("img", {
            attrs: { src: require("../assets/sns/fb.png"), alt: "" },
          }),
        ]
      ),
      _c(
        "a",
        {
          staticClass: "sns",
          attrs: {
            href: "https://www.instagram.com/visionfuturefund/",
            target: "_blank",
          },
        },
        [
          _c("img", {
            attrs: { src: require("../assets/sns/ig.png"), alt: "" },
          }),
        ]
      ),
      _c(
        "a",
        {
          staticClass: "sns",
          attrs: {
            href: "https://www.youtube.com/channel/UCtCQpThY5bj9eS5XXIDsp3g",
            target: "_blank",
          },
        },
        [
          _c("img", {
            attrs: { src: require("../assets/sns/YT.png"), alt: "" },
          }),
        ]
      ),
      _c(
        "a",
        {
          staticClass: "sns",
          attrs: {
            href: "mailto:hivisionfuture@tzuchi.org.tw",
            target: "_blank",
          },
        },
        [
          _c("img", {
            attrs: { src: require("../assets/sns/mail.png"), alt: "" },
          }),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }