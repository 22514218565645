var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "academy-history-item" }, [
    _c(
      "div",
      { staticClass: "block mb-16" },
      [
        _c("div", { staticClass: "block-title" }, [
          _c("div", { staticClass: "dot" }),
          _vm._v(" " + _vm._s(_vm.$t("academy.landing.title[3]")) + " "),
        ]),
        _c("Item", { attrs: { data: _vm.item, label: false } }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }