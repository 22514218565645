var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "history-block block" },
    [
      _c("div", { staticClass: "block-title" }, [
        _c("div", { staticClass: "dot" }),
        _vm._v(" " + _vm._s(_vm.$t("incubator.history.title")) + " "),
      ]),
      _c(
        "div",
        { staticClass: "panels" },
        [
          _c(
            "v-expansion-panels",
            { attrs: { accordion: "" } },
            _vm._l(_vm.historyTeam, function (annual, i) {
              return _c(
                "v-expansion-panel",
                { key: i, staticClass: "panel-item" },
                [
                  _c(
                    "v-expansion-panel-header",
                    { staticClass: "annual-title" },
                    [_vm._v(" " + _vm._s(annual.title) + " ")]
                  ),
                  _c(
                    "v-expansion-panel-content",
                    { staticClass: "annual-content" },
                    [
                      _c("div", { staticClass: "stage" }, [
                        _c(
                          "div",
                          { staticClass: "mobile-teams" },
                          [
                            _c(
                              "v-carousel",
                              {
                                attrs: { "hide-delimiters": "", height: "347" },
                              },
                              _vm._l(annual.items[0], function (team, index) {
                                return _c(
                                  "v-carousel-item",
                                  {
                                    key: index,
                                    staticClass: "team-item",
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleClickItem(team)
                                      },
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "box" }, [
                                      _c("img", {
                                        attrs: {
                                          src: _vm.image(team.image),
                                          alt: "",
                                        },
                                      }),
                                    ]),
                                    _c("div", { staticClass: "team-title" }, [
                                      _vm._v(" " + _vm._s(team.title) + " "),
                                    ]),
                                  ]
                                )
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "teams desktop-teams" },
                          _vm._l(annual.items[0], function (team, index) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticClass: "team-item",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleClickItem(team)
                                  },
                                },
                              },
                              [
                                _c("div", { staticClass: "box" }, [
                                  team.rank
                                    ? _c("div", { staticClass: "rank" }, [
                                        _vm._v(" " + _vm._s(team.rank) + " "),
                                      ])
                                    : _vm._e(),
                                  _c("img", {
                                    attrs: {
                                      src: _vm.image(team.image),
                                      alt: "",
                                    },
                                  }),
                                ]),
                                _c("div", { staticClass: "team-title" }, [
                                  _vm._v(" " + _vm._s(team.title) + " "),
                                ]),
                              ]
                            )
                          }),
                          0
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
      _vm.dialogShow
        ? _c("TeamDialog", {
            attrs: { "dialog-data": _vm.dialogData },
            on: { close: _vm.closeTeamDialog },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }