var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "team-dialog" }, [
    _vm.dialogData
      ? _c(
          "div",
          { staticClass: "dialog-body" },
          [
            _c(
              "v-icon",
              { staticClass: "close-btn", on: { click: _vm.close } },
              [_vm._v(" mdi-close ")]
            ),
            _c("div", { staticClass: "dialog-content" }, [
              _c("div", { staticClass: "top" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.dialogData.rank,
                        expression: "dialogData.rank",
                      },
                    ],
                    staticClass: "rank",
                  },
                  [_vm._v(" " + _vm._s(_vm.dialogData.rank) + " ")]
                ),
                _c("div", { staticClass: "team-title" }, [
                  _vm._v(" " + _vm._s(_vm.dialogData.title) + " "),
                ]),
                _c("div", { staticClass: "team-name" }, [
                  _vm._v(" " + _vm._s(_vm.dialogData.name) + " "),
                ]),
                _c("div", { staticClass: "image" }, [
                  _c("img", {
                    attrs: { src: _vm.image(_vm.dialogData.image), alt: "" },
                  }),
                ]),
              ]),
              _c("div", { staticClass: "content" }, [
                _c("div", { staticClass: "section" }, [
                  _c("div", { staticClass: "section-title" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("invention.teamDialog.title[0]")) +
                        " "
                    ),
                  ]),
                  _c("div", {
                    staticClass: "section-text",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.textHandle(_vm.dialogData.selfTheme)
                      ),
                    },
                  }),
                ]),
                _c("div", { staticClass: "section" }, [
                  _c("div", { staticClass: "section-title" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("invention.teamDialog.title[1]")) +
                        " "
                    ),
                  ]),
                  _c("div", {
                    staticClass: "section-text",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.textHandle(_vm.dialogData.shareTheme)
                      ),
                    },
                  }),
                ]),
                _c("div", { staticClass: "section" }, [
                  _c("div", { staticClass: "section-title" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("invention.teamDialog.title[2]")) +
                        " "
                    ),
                  ]),
                  _c("div", {
                    staticClass: "section-text",
                    domProps: {
                      innerHTML: _vm._s(_vm.textHandle(_vm.dialogData.content)),
                    },
                  }),
                ]),
              ]),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.dialogData.fb ||
                        _vm.dialogData.ig ||
                        _vm.dialogData.youtube ||
                        _vm.dialogData.mail,
                      expression:
                        "\n                    dialogData.fb ||\n                        dialogData.ig ||\n                        dialogData.youtube ||\n                        dialogData.mail\n                ",
                    },
                  ],
                  staticClass: "contact",
                },
                [
                  _c("div", { staticClass: "contact-title" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("invention.teamDialog.title[3]")) +
                        " "
                    ),
                  ]),
                  _c("div", { staticClass: "sns-list" }, [
                    _c(
                      "a",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.dialogData.fb,
                            expression: "dialogData.fb",
                          },
                        ],
                        staticClass: "sns",
                        attrs: { href: _vm.dialogData.fb, target: "_blank" },
                      },
                      [
                        _c("img", {
                          staticClass: "image",
                          attrs: {
                            src: require("../../assets/sns/fb.png"),
                            alt: "",
                          },
                        }),
                      ]
                    ),
                    _c(
                      "a",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.dialogData.ig,
                            expression: "dialogData.ig",
                          },
                        ],
                        staticClass: "sns",
                        attrs: { href: _vm.dialogData.ig, target: "_blank" },
                      },
                      [
                        _c("img", {
                          staticClass: "image",
                          attrs: {
                            src: require("../../assets/sns/ig.png"),
                            alt: "",
                          },
                        }),
                      ]
                    ),
                    _c(
                      "a",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.dialogData.youtube,
                            expression: "dialogData.youtube",
                          },
                        ],
                        staticClass: "sns",
                        attrs: {
                          href: _vm.dialogData.youtube,
                          target: "_blank",
                        },
                      },
                      [
                        _c("img", {
                          staticClass: "image",
                          attrs: {
                            src: require("../../assets/sns/YT.png"),
                            alt: "",
                          },
                        }),
                      ]
                    ),
                    _c(
                      "a",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.dialogData.mail,
                            expression: "dialogData.mail",
                          },
                        ],
                        staticClass: "sns",
                        attrs: {
                          href: _vm.mailHref(_vm.dialogData.mail),
                          target: "_blank",
                        },
                      },
                      [
                        _c("img", {
                          staticClass: "image",
                          attrs: {
                            src: require("../../assets/sns/mail.png"),
                            alt: "",
                          },
                        }),
                      ]
                    ),
                  ]),
                ]
              ),
              _c("div", { staticClass: "bottom" }, [
                _c(
                  "div",
                  { staticClass: "return-btn", on: { click: _vm.close } },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.$t("invention.teamDialog.button")) + " "
                    ),
                  ]
                ),
              ]),
            ]),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }