var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "about page normal-page" }, [
    _vm._m(0),
    _c("div", { staticClass: "web-content" }, [
      _c(
        "div",
        { staticClass: "page-structure" },
        [
          _c("PageMenu", { attrs: { "menu-data": _vm.pageMenu } }),
          _c(
            "div",
            { staticClass: "page-content about-content" },
            [
              _c("div", { staticClass: "ceo-words block" }, [
                _c("div", { staticClass: "block-title" }, [
                  _c("div", { staticClass: "dot" }),
                  _vm._v(" " + _vm._s(_vm.$t("about.ceo.title")) + " "),
                ]),
                _c("div", { staticClass: "words-box" }, [
                  _c("div", { staticClass: "left-box" }, [
                    _c("div", { staticClass: "text text-justify" }, [
                      _c("p", [
                        _vm._v(" " + _vm._s(_vm.$t("about.ceo.text[0]")) + " "),
                      ]),
                      _c("p", [
                        _vm._v(" " + _vm._s(_vm.$t("about.ceo.text[1]")) + " "),
                      ]),
                      _c("p", [
                        _vm._v(" " + _vm._s(_vm.$t("about.ceo.text[2]")) + " "),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "image-box" }, [
                    _c("img", {
                      attrs: {
                        src: require("../assets/about/ceo.jpg"),
                        alt: "",
                      },
                    }),
                    _c("div", { staticClass: "text" }, [
                      _c("span", { staticClass: "name" }, [
                        _vm._v(_vm._s(_vm.$t("about.ceo.name"))),
                      ]),
                      _c("span", { staticClass: "job-title" }, [
                        _vm._v(_vm._s(_vm.$t("about.ceo.job")) + " "),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
              _vm._m(1),
              _c("Anchor", { attrs: { id: "four-main" } }),
              _c(
                "div",
                { staticClass: "four-main block", attrs: { id: "" } },
                [
                  _c("div", { staticClass: "block-title" }, [
                    _c("div", { staticClass: "dot" }),
                    _vm._v(" " + _vm._s(_vm.$t("about.fourMain.title")) + " "),
                  ]),
                  _vm._l(_vm.fourMain, function (item) {
                    return _c("div", { key: item.title, staticClass: "item" }, [
                      _c("img", { attrs: { src: item.img, alt: "" } }),
                      _c("div", { staticClass: "text-box" }, [
                        _c("div", { staticClass: "sub-title" }, [
                          _vm._v(" " + _vm._s(_vm.$t(item.title)) + " "),
                        ]),
                        _c("div", { staticClass: "text" }, [
                          _vm._v(" " + _vm._s(_vm.$t(item.text)) + " "),
                        ]),
                      ]),
                    ])
                  }),
                ],
                2
              ),
              _c("div", { staticClass: "core block" }, [
                _c("div", { staticClass: "block-title" }, [
                  _c("div", { staticClass: "dot" }),
                  _vm._v(" " + _vm._s(_vm.$t("about.core.title")) + " "),
                ]),
                _c("div", { staticClass: "core-content" }, [
                  _c("div", { staticClass: "core-desktop" }, [
                    _c(
                      "div",
                      { staticClass: "left" },
                      _vm._l(_vm.coreLeft, function (item) {
                        return _c("div", { key: item.number }, [
                          _c(
                            "div",
                            {
                              ref: item.ref,
                              refInFor: true,
                              staticClass: "item",
                              class: item.revealClass,
                              style: { color: item.color },
                            },
                            [
                              _c("div", { staticClass: "item-title" }, [
                                _c("div", { staticClass: "number" }, [
                                  _vm._v(" " + _vm._s(item.number) + " "),
                                ]),
                                _c("div", { staticClass: "word" }, [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t(item.title)) + " "
                                  ),
                                ]),
                              ]),
                              _c("div", { staticClass: "text" }, [
                                _vm._v(" " + _vm._s(_vm.$t(item.text)) + " "),
                              ]),
                            ]
                          ),
                          _c("div", { staticClass: "item empty-item" }),
                        ])
                      }),
                      0
                    ),
                    _vm._m(2),
                    _c(
                      "div",
                      { staticClass: "right" },
                      _vm._l(_vm.coreRight, function (item) {
                        return _c("div", { key: item.number }, [
                          _c("div", { staticClass: "item empty-item" }),
                          _c(
                            "div",
                            {
                              ref: item.ref,
                              refInFor: true,
                              staticClass: "item",
                              class: item.revealClass,
                              style: { color: item.color },
                            },
                            [
                              _c("div", { staticClass: "item-title" }, [
                                _c("div", { staticClass: "number" }, [
                                  _vm._v(" " + _vm._s(item.number) + " "),
                                ]),
                                _c("div", { staticClass: "word" }, [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t(item.title)) + " "
                                  ),
                                ]),
                              ]),
                              _c("div", { staticClass: "text" }, [
                                _vm._v(" " + _vm._s(_vm.$t(item.text)) + " "),
                              ]),
                            ]
                          ),
                        ])
                      }),
                      0
                    ),
                  ]),
                  _c("div", { staticClass: "core-mobile" }, [
                    _c(
                      "div",
                      { staticClass: "mobile-content" },
                      _vm._l(_vm.coreMobile, function (item) {
                        return _c(
                          "div",
                          {
                            key: item.number,
                            ref: item.ref + "-mobile",
                            refInFor: true,
                            staticClass: "item",
                            class: item.revealClass,
                            style: { color: item.color },
                          },
                          [
                            _c("div", { staticClass: "item-title" }, [
                              _c("div", { staticClass: "number" }, [
                                _vm._v(" " + _vm._s(item.number) + " "),
                              ]),
                              _c("div", { staticClass: "word" }, [
                                _vm._v(" " + _vm._s(_vm.$t(item.title)) + " "),
                              ]),
                            ]),
                            _c("div", { staticClass: "text" }, [
                              _vm._v(" " + _vm._s(_vm.$t(item.text)) + " "),
                            ]),
                          ]
                        )
                      }),
                      0
                    ),
                    _vm._m(3),
                  ]),
                ]),
              ]),
              _c("Anchor", { attrs: { id: "partner" } }),
              _c("div", { staticClass: "partner block" }, [
                _c("div", { staticClass: "block-title" }, [
                  _c("div", { staticClass: "dot" }),
                  _vm._v(" " + _vm._s(_vm.$t("about.partner.title")) + " "),
                ]),
                _c("div", { staticClass: "partner-content" }, [
                  _c("div", { staticClass: "top" }, [
                    _c("div", { staticClass: "item line-heigh-1" }, [
                      _c("div", { staticClass: "label" }, [
                        _vm._v(
                          " " + _vm._s(_vm.$t("about.partner.label[0]")) + "｜ "
                        ),
                      ]),
                      _vm._m(4),
                    ]),
                    _c("div", { staticClass: "item" }, [
                      _c("div", { staticClass: "label" }, [
                        _vm._v(
                          " " + _vm._s(_vm.$t("about.partner.label[1]")) + "｜ "
                        ),
                      ]),
                      _vm._m(5),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "box" },
                    _vm._l(_vm.partners, function (item, index) {
                      return _c(
                        "a",
                        {
                          key: index,
                          staticClass: "item",
                          attrs: { href: item.link, target: "_blank" },
                        },
                        [_c("img", { attrs: { src: item.image, alt: "" } })]
                      )
                    }),
                    0
                  ),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page-banner" }, [
      _c("img", {
        staticClass: "desktop-banner",
        attrs: { src: require("../assets/about/banner.jpg"), alt: "" },
      }),
      _c("img", {
        staticClass: "mobile-banner",
        attrs: {
          src: require("../assets/about/mobile/banner-mobile.jpg"),
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "video" }, [
      _c("iframe", {
        staticClass: "video-frame",
        attrs: {
          src: "https://www.youtube.com/embed/Ztt8LyKrDjo?controls=0",
          title: "YouTube video player",
          frameborder: "0",
          allow:
            "accelerometer; autoplay; clipboard-write;\n        encrypted-media; gyroscope; picture-in-picture",
          allowfullscreen: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "line" }, [
      _c("img", {
        attrs: { src: require("../assets/about/core-line.png"), alt: "" },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "line" }, [
      _c("img", {
        attrs: { src: require("../assets/about/core-line.png"), alt: "" },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "link line-heigh-1",
        attrs: { href: "https://www.tzuchi.org.tw/", target: "_blank" },
      },
      [
        _c("img", {
          attrs: { src: require("../assets/about/partner/main.png"), alt: "" },
        }),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "link line-heigh-1",
        attrs: { href: "https://taipei.impacthub.net/", target: "_blank" },
      },
      [
        _c("img", {
          attrs: {
            src: require("../assets/about/partner/impact.png"),
            alt: "",
          },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }