var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "scroll-top", on: { click: _vm.scrollTop } },
    [
      _c("v-icon", { staticClass: "top-icon" }, [_vm._v(" mdi-arrow-up ")]),
      _c("div", { staticClass: "text" }, [_vm._v(" ＴＯＰ ")]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }