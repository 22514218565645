var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "incubator-data" },
    [
      _c(
        "v-form",
        {
          ref: "form",
          model: {
            value: _vm.valid,
            callback: function ($$v) {
              _vm.valid = $$v
            },
            expression: "valid",
          },
        },
        [
          _c("div", { staticClass: "apply-form-block" }, [
            _c("div", { staticClass: "left-block" }, [
              _c("div", { staticClass: "block-title" }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("IncubatorApply.TeamData.title[0]")) + " "
                ),
              ]),
            ]),
            _c("div", { staticClass: "form-content" }, [
              _c(
                "div",
                { staticClass: "form-row" },
                [
                  _c("v-text-field", {
                    staticClass: "border-orange-input form-input",
                    attrs: {
                      outlined: "",
                      label: _vm.$t("IncubatorApply.TeamData.label[0]"),
                      placeholder: "",
                      hint: _vm.$t("IncubatorApply.TeamData.hint[0]"),
                      "persistent-hint": "",
                      rules: _vm.emptyRule,
                    },
                    model: {
                      value: _vm.form.team.organizeName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.team, "organizeName", $$v)
                      },
                      expression: "form.team.organizeName",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _c("div", { staticClass: "apply-form-block" }, [
            _c("div", { staticClass: "left-block" }, [
              _c("div", { staticClass: "block-title" }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("IncubatorApply.TeamData.title[1]")) + " "
                ),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "form-content" },
              [
                _c(
                  "v-radio-group",
                  {
                    staticClass: "radio-group",
                    attrs: { rules: _vm.emptyRule },
                    model: {
                      value: _vm.form.team.type,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.team, "type", $$v)
                      },
                      expression: "form.team.type",
                    },
                  },
                  [
                    _c("v-radio", {
                      attrs: {
                        value: "非正式立案組織（個人團隊）",
                        color: "#F97E2B",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "label",
                          fn: function () {
                            return [
                              _c("div", { staticClass: "radio-label" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("IncubatorApply.TeamData.radio[0]")
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    }),
                    _c("v-radio", {
                      attrs: { value: "非營利組織", color: "#F97E2B" },
                      scopedSlots: _vm._u([
                        {
                          key: "label",
                          fn: function () {
                            return [
                              _c("div", { staticClass: "radio-label" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("IncubatorApply.TeamData.radio[1]")
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    }),
                    _c("v-radio", {
                      attrs: { value: "營利組織", color: "#F97E2B" },
                      scopedSlots: _vm._u([
                        {
                          key: "label",
                          fn: function () {
                            return [
                              _c("div", { staticClass: "radio-label" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("IncubatorApply.TeamData.radio[2]")
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "apply-form-block" }, [
            _c("div", { staticClass: "left-block" }, [
              _c("div", { staticClass: "block-title" }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("IncubatorApply.TeamData.title[2]")) + " "
                ),
              ]),
            ]),
            _c("div", { staticClass: "form-content" }, [
              _c(
                "div",
                { staticClass: "form-row" },
                [
                  _c("v-select", {
                    staticClass: "border-orange-input form-input",
                    attrs: {
                      items: _vm.countries,
                      outlined: "",
                      label: _vm.$t("IncubatorApply.TeamData.label[1]"),
                      rules: _vm.emptyRule,
                    },
                    model: {
                      value: _vm.form.team.country,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.team, "country", $$v)
                      },
                      expression: "form.team.country",
                    },
                  }),
                  _vm.form.team.country !== "臺灣"
                    ? _c("v-text-field", {
                        staticClass: "border-orange-input form-input",
                        attrs: {
                          outlined: "",
                          label: _vm.$t("IncubatorApply.TeamData.label[2]"),
                          placeholder: "",
                          hint: _vm.$t("IncubatorApply.TeamData.hint[1]"),
                          "persistent-hint": "",
                          rules: _vm.emptyRule,
                        },
                        model: {
                          value: _vm.form.team.city,
                          callback: function ($$v) {
                            _vm.$set(_vm.form.team, "city", $$v)
                          },
                          expression: "form.team.city",
                        },
                      })
                    : _c("v-select", {
                        staticClass: "border-orange-input form-input",
                        attrs: {
                          items: _vm.cities,
                          outlined: "",
                          label: _vm.$t("IncubatorApply.TeamData.label[3]"),
                          rules: _vm.emptyRule,
                        },
                        model: {
                          value: _vm.form.team.city,
                          callback: function ($$v) {
                            _vm.$set(_vm.form.team, "city", $$v)
                          },
                          expression: "form.team.city",
                        },
                      }),
                ],
                1
              ),
            ]),
          ]),
          _c("div", { staticClass: "apply-form-block" }, [
            _c("div", { staticClass: "left-block" }, [
              _c("div", { staticClass: "block-title" }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("IncubatorApply.TeamData.title[3]")) + " "
                ),
              ]),
            ]),
            _c("div", { staticClass: "form-content" }, [
              _c(
                "div",
                { staticClass: "form-row" },
                [
                  _c("v-text-field", {
                    staticClass: "border-orange-input form-input",
                    attrs: {
                      outlined: "",
                      label: _vm.$t("IncubatorApply.TeamData.label[4]"),
                      placeholder: "",
                      hint: _vm.$t("IncubatorApply.TeamData.hint[2]"),
                      "persistent-hint": "",
                    },
                    model: {
                      value: _vm.form.team.taxId,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.team, "taxId", $$v)
                      },
                      expression: "form.team.taxId",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _c("div", { staticClass: "apply-form-block" }, [
            _c("div", { staticClass: "left-block" }, [
              _c("div", { staticClass: "block-title" }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("IncubatorApply.TeamData.title[4]")) + " "
                ),
              ]),
            ]),
            _c("div", { staticClass: "form-content" }, [
              _c(
                "div",
                { staticClass: "form-row" },
                [
                  _c("v-text-field", {
                    staticClass: "border-orange-input form-input",
                    attrs: {
                      outlined: "",
                      label: _vm.$t("IncubatorApply.TeamData.label[5]"),
                      placeholder: "",
                      hint: _vm.$t("IncubatorApply.TeamData.hint[3]"),
                      "persistent-hint": "",
                      rules: _vm.emptyRule,
                    },
                    model: {
                      value: _vm.form.team.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.team, "name", $$v)
                      },
                      expression: "form.team.name",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _c("div", { staticClass: "apply-form-block" }, [
            _c("div", { staticClass: "left-block" }, [
              _c("div", { staticClass: "block-title" }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("IncubatorApply.TeamData.title[5]")) + " "
                ),
              ]),
              _c("div", { staticClass: "tips" }, [
                _vm._v(" " + _vm._s(_vm.$t("IncubatorApply.TeamData.tips[0]"))),
                _c("br"),
                _vm._v(_vm._s(_vm.$t("IncubatorApply.TeamData.tips[1]")) + " "),
              ]),
            ]),
            _c("div", { staticClass: "form-content" }, [
              _c("div", { staticClass: "form-action" }, [
                _c(
                  "div",
                  {
                    staticClass: "delete-btn btn",
                    on: { click: _vm.deleteMember },
                  },
                  [
                    _vm._v(
                      " - " +
                        _vm._s(_vm.$t("IncubatorApply.TeamData.deleteMember")) +
                        " "
                    ),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "add-btn btn", on: { click: _vm.addMember } },
                  [
                    _vm._v(
                      " + " +
                        _vm._s(_vm.$t("IncubatorApply.TeamData.addMember")) +
                        " "
                    ),
                  ]
                ),
              ]),
              _c(
                "div",
                { staticClass: "member-list" },
                _vm._l(_vm.form.team.members, function (item, index) {
                  return _c("div", { key: index, staticClass: "member-item" }, [
                    _c(
                      "div",
                      { staticClass: "form-row" },
                      [
                        _c("v-text-field", {
                          staticClass: "border-orange-input form-input",
                          attrs: {
                            outlined: "",
                            label: _vm.memberNameLabel(index),
                            placeholder: "",
                            hint: _vm.$t("IncubatorApply.TeamData.hint[3]"),
                            "persistent-hint": "",
                            rules: _vm.emptyRule,
                          },
                          model: {
                            value: item.name,
                            callback: function ($$v) {
                              _vm.$set(item, "name", $$v)
                            },
                            expression: "item.name",
                          },
                        }),
                        _c("v-text-field", {
                          staticClass: "border-orange-input form-input",
                          attrs: {
                            outlined: "",
                            label: _vm.$t("IncubatorApply.TeamData.label[8]"),
                            placeholder: "",
                            hint: _vm.$t("IncubatorApply.TeamData.hint[3]"),
                            rules: _vm.emptyRule,
                            "persistent-hint": "",
                          },
                          model: {
                            value: item.phone,
                            callback: function ($$v) {
                              _vm.$set(item, "phone", $$v)
                            },
                            expression: "item.phone",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "form-row" },
                      [
                        _c("v-text-field", {
                          staticClass: "border-orange-input form-input",
                          attrs: {
                            outlined: "",
                            label: _vm.$t("IncubatorApply.TeamData.label[9]"),
                            placeholder: "",
                            hint: _vm.$t("IncubatorApply.TeamData.hint[3]"),
                            "persistent-hint": "",
                            rules: _vm.emptyRule,
                          },
                          model: {
                            value: item.department,
                            callback: function ($$v) {
                              _vm.$set(item, "department", $$v)
                            },
                            expression: "item.department",
                          },
                        }),
                        _c(
                          "v-menu",
                          {
                            ref: "memberMenu" + index,
                            refInFor: true,
                            attrs: {
                              "close-on-content-click": false,
                              transition: "scale-transition",
                              "offset-y": "",
                              "min-width": "auto",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function (ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "v-text-field",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass:
                                                "border-orange-input form-input",
                                              attrs: {
                                                outlined: "",
                                                label: _vm.$t(
                                                  "IncubatorApply.TeamData.label[10]"
                                                ),
                                                placeholder: _vm.$t(
                                                  "IncubatorApply.TeamData.label[10]"
                                                ),
                                                hint: _vm.$t(
                                                  "IncubatorApply.TeamData.hint[3]"
                                                ),
                                                "persistent-hint": "",
                                                readonly: "",
                                                rules: _vm.emptyRule,
                                                "append-icon": "mdi-calendar",
                                              },
                                              on: {
                                                "click:append": function (
                                                  $event
                                                ) {
                                                  _vm.menu[
                                                    "memberMenu" + index
                                                  ] = true
                                                },
                                              },
                                              model: {
                                                value: item.birthday,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "birthday",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.birthday",
                                              },
                                            },
                                            "v-text-field",
                                            attrs,
                                            false
                                          ),
                                          on
                                        )
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                            model: {
                              value: _vm.menu["memberMenu" + index],
                              callback: function ($$v) {
                                _vm.$set(_vm.menu, "memberMenu" + index, $$v)
                              },
                              expression: "menu['memberMenu' + index]",
                            },
                          },
                          [
                            _c("v-date-picker", {
                              attrs: { "no-title": "", scrollable: "" },
                              on: {
                                input: function ($event) {
                                  _vm.menu["memberMenu" + index] = false
                                },
                              },
                              model: {
                                value: item.birthday,
                                callback: function ($$v) {
                                  _vm.$set(item, "birthday", $$v)
                                },
                                expression: "item.birthday",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "form-row" },
                      [
                        _c("v-text-field", {
                          staticClass: "border-orange-input",
                          attrs: {
                            outlined: "",
                            label: _vm.$t("IncubatorApply.TeamData.label[11]"),
                            placeholder: "",
                            hint: _vm.$t("IncubatorApply.TeamData.hint[3]"),
                            rules: _vm.emailRule,
                            "persistent-hint": "",
                          },
                          model: {
                            value: item.email,
                            callback: function ($$v) {
                              _vm.$set(item, "email", $$v)
                            },
                            expression: "item.email",
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                }),
                0
              ),
            ]),
          ]),
          _c("div", { staticClass: "apply-form-block" }, [
            _c("div", { staticClass: "left-block" }, [
              _c("div", { staticClass: "block-title" }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("IncubatorApply.TeamData.title[6]")) + " "
                ),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "form-content" },
              [
                _c("v-checkbox", {
                  staticClass: "checkbox checkbox-title",
                  staticStyle: { "pointer-events": "none" },
                  attrs: {
                    label: _vm.$t("IncubatorApply.TeamData.checkbox-q[0]"),
                    rules: _vm.refersRule,
                  },
                  model: {
                    value: _vm.form.team.refers,
                    callback: function ($$v) {
                      _vm.$set(_vm.form.team, "refers", $$v)
                    },
                    expression: "form.team.refers",
                  },
                }),
                _c("v-checkbox", {
                  staticClass: "checkbox",
                  attrs: {
                    label: _vm.$t("IncubatorApply.TeamData.checkbox[0][0]"),
                    value: "FUN大視野官網",
                    color: "#F97E2B",
                  },
                  model: {
                    value: _vm.form.team.refers,
                    callback: function ($$v) {
                      _vm.$set(_vm.form.team, "refers", $$v)
                    },
                    expression: "form.team.refers",
                  },
                }),
                _c("v-checkbox", {
                  staticClass: "checkbox",
                  attrs: {
                    label: _vm.$t("IncubatorApply.TeamData.checkbox[0][1]"),
                    value: "FUN大視野FACEBOOK",
                    color: "#F97E2B",
                  },
                  model: {
                    value: _vm.form.team.refers,
                    callback: function ($$v) {
                      _vm.$set(_vm.form.team, "refers", $$v)
                    },
                    expression: "form.team.refers",
                  },
                }),
                _c("v-checkbox", {
                  staticClass: "checkbox",
                  attrs: {
                    label: _vm.$t("IncubatorApply.TeamData.checkbox[0][2]"),
                    value: "FUN大視野INSTAGRAM",
                    color: "#F97E2B",
                  },
                  model: {
                    value: _vm.form.team.refers,
                    callback: function ($$v) {
                      _vm.$set(_vm.form.team, "refers", $$v)
                    },
                    expression: "form.team.refers",
                  },
                }),
                _c("v-checkbox", {
                  staticClass: "checkbox",
                  attrs: {
                    label: _vm.$t("IncubatorApply.TeamData.checkbox[0][3]"),
                    value: "FUN大視野電子報",
                    color: "#F97E2B",
                  },
                  model: {
                    value: _vm.form.team.refers,
                    callback: function ($$v) {
                      _vm.$set(_vm.form.team, "refers", $$v)
                    },
                    expression: "form.team.refers",
                  },
                }),
                _c("v-checkbox", {
                  staticClass: "checkbox",
                  attrs: {
                    label: _vm.$t("IncubatorApply.TeamData.checkbox[0][4]"),
                    value: "學校活動公告(學校官網、海報、育成中心等)",
                    color: "#F97E2B",
                  },
                  model: {
                    value: _vm.form.team.refers,
                    callback: function ($$v) {
                      _vm.$set(_vm.form.team, "refers", $$v)
                    },
                    expression: "form.team.refers",
                  },
                }),
                _c("v-checkbox", {
                  staticClass: "checkbox",
                  attrs: {
                    label: _vm.$t("IncubatorApply.TeamData.checkbox[0][5]"),
                    value: "縣市政府宣傳平台",
                    color: "#F97E2B",
                  },
                  model: {
                    value: _vm.form.team.refers,
                    callback: function ($$v) {
                      _vm.$set(_vm.form.team, "refers", $$v)
                    },
                    expression: "form.team.refers",
                  },
                }),
                _c("v-checkbox", {
                  staticClass: "checkbox",
                  attrs: {
                    label: _vm.$t("IncubatorApply.TeamData.checkbox[0][6]"),
                    value: "社會創新平台官網",
                    color: "#F97E2B",
                  },
                  model: {
                    value: _vm.form.team.refers,
                    callback: function ($$v) {
                      _vm.$set(_vm.form.team, "refers", $$v)
                    },
                    expression: "form.team.refers",
                  },
                }),
                _c("v-checkbox", {
                  staticClass: "checkbox",
                  attrs: {
                    label: _vm.$t("IncubatorApply.TeamData.checkbox[0][7]"),
                    value: "獎金獵人平台",
                    color: "#F97E2B",
                  },
                  model: {
                    value: _vm.form.team.refers,
                    callback: function ($$v) {
                      _vm.$set(_vm.form.team, "refers", $$v)
                    },
                    expression: "form.team.refers",
                  },
                }),
                _c(
                  "div",
                  { staticStyle: { display: "flex", "flex-wrap": "wrap" } },
                  [
                    _c("v-checkbox", {
                      staticClass: "checkbox",
                      attrs: {
                        label: _vm.$t("IncubatorApply.TeamData.checkbox[0][8]"),
                        value: "歷屆團隊推薦，團隊名:",
                        color: "#F97E2B",
                      },
                      model: {
                        value: _vm.form.team.refers,
                        callback: function ($$v) {
                          _vm.$set(_vm.form.team, "refers", $$v)
                        },
                        expression: "form.team.refers",
                      },
                    }),
                    _c("v-text-field", {
                      staticClass:
                        "checkbox-input border-orange-input form-input",
                      model: {
                        value: _vm.refers1,
                        callback: function ($$v) {
                          _vm.refers1 = $$v
                        },
                        expression: "refers1",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticStyle: { display: "flex", "flex-wrap": "wrap" } },
                  [
                    _c("v-checkbox", {
                      staticClass: "checkbox",
                      attrs: {
                        label: _vm.$t("IncubatorApply.TeamData.checkbox[0][9]"),
                        value: "親朋好友推薦，推薦人:",
                        color: "#F97E2B",
                      },
                      model: {
                        value: _vm.form.team.refers,
                        callback: function ($$v) {
                          _vm.$set(_vm.form.team, "refers", $$v)
                        },
                        expression: "form.team.refers",
                      },
                    }),
                    _c("v-text-field", {
                      staticClass:
                        "checkbox-input border-orange-input form-input",
                      model: {
                        value: _vm.refers2,
                        callback: function ($$v) {
                          _vm.refers2 = $$v
                        },
                        expression: "refers2",
                      },
                    }),
                  ],
                  1
                ),
                _c("v-checkbox", {
                  staticClass: "checkbox",
                  attrs: {
                    label: _vm.$t("IncubatorApply.TeamData.checkbox[0][10]"),
                    value: "曾經提案過",
                    color: "#F97E2B",
                  },
                  model: {
                    value: _vm.form.team.refers,
                    callback: function ($$v) {
                      _vm.$set(_vm.form.team, "refers", $$v)
                    },
                    expression: "form.team.refers",
                  },
                }),
                _c("v-checkbox", {
                  staticClass: "checkbox",
                  attrs: {
                    label: _vm.$t("IncubatorApply.TeamData.checkbox[0][11]"),
                    value: "原本就知道",
                    color: "#F97E2B",
                  },
                  model: {
                    value: _vm.form.team.refers,
                    callback: function ($$v) {
                      _vm.$set(_vm.form.team, "refers", $$v)
                    },
                    expression: "form.team.refers",
                  },
                }),
                _c("v-checkbox", {
                  staticClass: "checkbox",
                  attrs: {
                    label: _vm.$t("IncubatorApply.TeamData.checkbox[0][12]"),
                    value:
                      "IMPACT HUB TAIPEI宣傳平台(官網、電子報、口頭邀約等)",
                    color: "#F97E2B",
                  },
                  model: {
                    value: _vm.form.team.refers,
                    callback: function ($$v) {
                      _vm.$set(_vm.form.team, "refers", $$v)
                    },
                    expression: "form.team.refers",
                  },
                }),
                _c("v-checkbox", {
                  staticClass: "checkbox",
                  attrs: {
                    label: _vm.$t("IncubatorApply.TeamData.checkbox[0][13]"),
                    value: "慈濟基金會宣傳平台(官網、IG、LINE、大愛台等)",
                    color: "#F97E2B",
                  },
                  model: {
                    value: _vm.form.team.refers,
                    callback: function ($$v) {
                      _vm.$set(_vm.form.team, "refers", $$v)
                    },
                    expression: "form.team.refers",
                  },
                }),
                _c(
                  "div",
                  { staticStyle: { display: "flex", "flex-wrap": "wrap" } },
                  [
                    _c("v-checkbox", {
                      staticClass: "checkbox",
                      attrs: {
                        label: _vm.$t(
                          "IncubatorApply.TeamData.checkbox[0][14]"
                        ),
                        value: "媒體宣傳平台，平台名:",
                        color: "#F97E2B",
                      },
                      model: {
                        value: _vm.form.team.refers,
                        callback: function ($$v) {
                          _vm.$set(_vm.form.team, "refers", $$v)
                        },
                        expression: "form.team.refers",
                      },
                    }),
                    _c("v-text-field", {
                      staticClass:
                        "checkbox-input border-orange-input form-input",
                      model: {
                        value: _vm.refers3,
                        callback: function ($$v) {
                          _vm.refers3 = $$v
                        },
                        expression: "refers3\n                        ",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticStyle: { display: "flex", "flex-wrap": "wrap" } },
                  [
                    _c("v-checkbox", {
                      staticClass: "checkbox",
                      attrs: {
                        label: _vm.$t(
                          "IncubatorApply.TeamData.checkbox[0][15]"
                        ),
                        value: "其他:",
                        color: "#F97E2B",
                      },
                      model: {
                        value: _vm.form.team.refers,
                        callback: function ($$v) {
                          _vm.$set(_vm.form.team, "refers", $$v)
                        },
                        expression: "form.team.refers",
                      },
                    }),
                    _c("v-text-field", {
                      staticClass:
                        "checkbox-input border-orange-input form-input",
                      model: {
                        value: _vm.refers4,
                        callback: function ($$v) {
                          _vm.refers4 = $$v
                        },
                        expression: "refers4",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "apply-form-block" }, [
            _c("div", { staticClass: "left-block" }),
            _c("div", { staticClass: "form-content" }, [
              _c("div", { staticClass: "actions" }, [
                _c(
                  "div",
                  {
                    staticClass: "btn white-btn",
                    on: { click: _vm.resetForm },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("IncubatorApply.TeamData.button[0]")) +
                        " "
                    ),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "btn blue-btn", on: { click: _vm.save } },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("IncubatorApply.TeamData.button[1]")) +
                        " "
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "btn orange-btn",
                    on: { click: _vm.nextStep },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("IncubatorApply.TeamData.button[2]")) +
                        " "
                    ),
                  ]
                ),
              ]),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }