var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "user page normal-page" }, [
    _c("div", { staticClass: "web-content" }, [
      _c(
        "div",
        { staticClass: "page-structure" },
        [
          _c("PageMenu", { attrs: { "menu-data": _vm.pageMenu } }),
          _c("div", { staticClass: "page-content" }, [_c("router-view")], 1),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }