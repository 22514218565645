import { render, staticRenderFns } from "./Anchor.vue?vue&type=template&id=c2a1f336&scoped=true&"
import script from "./Anchor.vue?vue&type=script&lang=js&"
export * from "./Anchor.vue?vue&type=script&lang=js&"
import style0 from "./Anchor.vue?vue&type=style&index=0&id=c2a1f336&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c2a1f336",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/songlian/Developer/官網/慈濟/vision-future-web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('c2a1f336')) {
      api.createRecord('c2a1f336', component.options)
    } else {
      api.reload('c2a1f336', component.options)
    }
    module.hot.accept("./Anchor.vue?vue&type=template&id=c2a1f336&scoped=true&", function () {
      api.rerender('c2a1f336', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Anchor.vue"
export default component.exports