var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "recommend-block block" }, [
    _c("div", { staticClass: "block-title" }, [
      _c("div", { staticClass: "dot" }),
      _vm._v(" " + _vm._s(_vm.$t("invention.recommend.title")) + " "),
    ]),
    _c("div", { staticClass: "sub-block" }, [
      _c("div", { staticClass: "swiper swiper-invention" }, [
        _c(
          "div",
          { staticClass: "swiper-wrapper" },
          _vm._l(_vm.recommend, function (item, index) {
            return _c("div", { key: index, staticClass: "swiper-slide" }, [
              _c("div", { staticClass: "content" }, [
                _c("div", { staticClass: "text item-name" }, [
                  _vm._v(" " + _vm._s(item.name) + " "),
                ]),
                _c("div", { staticClass: "text item-text" }, [
                  _vm._v(" " + _vm._s(item.content) + " "),
                ]),
              ]),
            ])
          }),
          0
        ),
        _c("div", {
          staticClass: "swiper-button-prev invention-swiper-button-prev",
        }),
        _c("div", {
          staticClass: "swiper-button-next invention-swiper-button-next",
        }),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }