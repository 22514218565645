var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "explain-block block" }, [
    _c("div", { staticClass: "block-title" }, [
      _c("div", { staticClass: "dot" }),
      _vm._v(" " + _vm._s(_vm.$t("incubator.explain.title")) + " "),
    ]),
    _c("div", { staticClass: "video" }, [
      _c("iframe", {
        staticClass: "video-frame",
        attrs: {
          src: _vm.getVideo(_vm.content.video),
          title: "YouTube video player",
          frameborder: "0",
          allow:
            "accelerometer; autoplay; clipboard-write;\n        encrypted-media; gyroscope; picture-in-picture",
          allowfullscreen: "",
        },
      }),
    ]),
    _c("div", { staticClass: "content sub-block" }, [
      _c("div", {
        staticClass: "block-content",
        domProps: { innerHTML: _vm._s(_vm.content.explain) },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }