import { render, staticRenderFns } from "./ThreeBlock.vue?vue&type=template&id=3b31b1e0&"
import script from "./ThreeBlock.vue?vue&type=script&lang=js&"
export * from "./ThreeBlock.vue?vue&type=script&lang=js&"
import style0 from "./ThreeBlock.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
installComponents(component, {VCard,VIcon,VImg,VProgressCircular})


/* hot reload */
if (module.hot) {
  var api = require("/Users/songlian/Developer/官網/慈濟/vision-future-web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3b31b1e0')) {
      api.createRecord('3b31b1e0', component.options)
    } else {
      api.reload('3b31b1e0', component.options)
    }
    module.hot.accept("./ThreeBlock.vue?vue&type=template&id=3b31b1e0&", function () {
      api.rerender('3b31b1e0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/list/ThreeBlock.vue"
export default component.exports