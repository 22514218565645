var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "register-validate-success" }, [
    _c("div", { staticClass: "status" }, [
      _c("img", {
        staticClass: "status-img",
        attrs: { src: require("../../assets/register/success.png"), alt: "" },
      }),
      _c("div", { staticClass: "text" }, [
        _c("div", { staticStyle: { color: "#61C88A" } }, [
          _vm._v(" " + _vm._s(_vm.$t("Register.ValidateSuccess.text")) + " "),
        ]),
      ]),
    ]),
    _c("button", { staticClass: "submit-btn", on: { click: _vm.backToHome } }, [
      _vm._v(" " + _vm._s(_vm.$t("Register.ValidateSuccess.button")) + " "),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }