var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "registration-block block" },
    [
      _c("div", { staticClass: "block-title" }, [
        _c("div", { staticClass: "dot" }),
        _vm._v(" " + _vm._s(_vm.$t("incubator.registration.title[0]")) + " "),
      ]),
      _c("Anchor", { attrs: { id: "qualification" } }),
      _c("div", { staticClass: "sub-block" }, [
        _c("div", { staticClass: "sub-title" }, [
          _vm._v(" " + _vm._s(_vm.$t("incubator.registration.title[1]")) + " "),
        ]),
        _c("div", {
          staticClass: "block-content",
          domProps: { innerHTML: _vm._s(_vm.content.qualifications) },
        }),
      ]),
      _c("Anchor", { attrs: { id: "apply-method" } }),
      _c("div", { staticClass: "sub-block" }, [
        _c("div", { staticClass: "sub-title" }, [
          _vm._v(" " + _vm._s(_vm.$t("incubator.registration.title[2]")) + " "),
        ]),
        _c("div", {
          staticClass: "block-content",
          domProps: { innerHTML: _vm._s(_vm.content.applyMethod) },
        }),
      ]),
      _c("Anchor", { attrs: { id: "judge-method" } }),
      _c("div", { staticClass: "sub-block" }, [
        _c("div", { staticClass: "sub-title" }, [
          _vm._v(" " + _vm._s(_vm.$t("incubator.registration.title[3]")) + " "),
        ]),
        _c("div", {
          staticClass: "block-content",
          domProps: { innerHTML: _vm._s(_vm.content.judgeMethod) },
        }),
      ]),
      _c("Anchor", { attrs: { id: "schedule" } }),
      _c("div", { staticClass: "sub-block" }, [
        _c("div", { staticClass: "sub-title" }, [
          _vm._v(" " + _vm._s(_vm.$t("incubator.registration.title[4]")) + " "),
        ]),
        _c("div", {
          staticClass: "block-content",
          domProps: { innerHTML: _vm._s(_vm.content.schedule) },
        }),
      ]),
      _c("Anchor", { attrs: { id: "grants" } }),
      _c("div", { staticClass: "sub-block" }, [
        _c("div", { staticClass: "sub-title" }, [
          _vm._v(" " + _vm._s(_vm.$t("incubator.registration.title[5]")) + " "),
        ]),
        _c("div", {
          staticClass: "block-content",
          domProps: { innerHTML: _vm._s(_vm.content.grants) },
        }),
      ]),
      _c("Anchor", { attrs: { id: "theme" } }),
      _c("div", { staticClass: "sub-block" }, [
        _c("div", { staticClass: "sub-title" }, [
          _vm._v(" " + _vm._s(_vm.$t("incubator.registration.title[6]")) + " "),
        ]),
        _c("div", {
          staticClass: "block-content",
          domProps: { innerHTML: _vm._s(_vm.content.theme) },
        }),
      ]),
      _c("Anchor", { attrs: { id: "contact-us" } }),
      _c("div", { staticClass: "sub-block contact" }, [
        _c("div", { staticClass: "sub-title" }, [
          _vm._v(" " + _vm._s(_vm.$t("incubator.registration.title[7]")) + " "),
        ]),
        _c("div", {
          staticClass: "block-content",
          domProps: { innerHTML: _vm._s(_vm.content.contact) },
        }),
      ]),
      _c("Anchor", { attrs: { id: "rights" } }),
      _c("div", { staticClass: "sub-block" }, [
        _c("div", { staticClass: "sub-title" }, [
          _vm._v(" " + _vm._s(_vm.$t("incubator.registration.title[8]")) + " "),
        ]),
        _c("div", {
          staticClass: "block-content",
          domProps: { innerHTML: _vm._s(_vm.content.rights) },
        }),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }