var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "subscribe-edm" }, [
    _c("div", { attrs: { id: "mc_embed_signup" } }, [
      _c(
        "form",
        {
          staticClass: "validate",
          attrs: {
            id: "mc-embedded-subscribe-form",
            action:
              "https://visionfuture.us19.list-manage.com/subscribe/post?u=be391e76a1d636d987ca92cdb&id=8814cc3b93",
            method: "post",
            name: "mc-embedded-subscribe-form",
            target: "_blank",
            novalidate: "",
          },
        },
        [
          _c("div", { attrs: { id: "mc_embed_signup_scroll" } }, [
            _c("h2", { staticClass: "edm-title" }, [
              _c("div", { staticClass: "text" }, [
                _vm._v(" " + _vm._s(_vm.$t("SubscribeEDM.text")) + " "),
              ]),
            ]),
            _c("div", { staticClass: "indicates-required" }, [
              _c("span", { staticClass: "asterisk" }, [_vm._v("*")]),
              _vm._v(" " + _vm._s(_vm.$t("SubscribeEDM.asterisk")) + " "),
            ]),
            _c("div", { staticClass: "mc-field-group" }, [
              _c("label", { attrs: { for: "mce-EMAIL" } }, [
                _vm._v(_vm._s(_vm.$t("SubscribeEDM.label"))),
                _c("span", { staticClass: "asterisk" }, [_vm._v("*")]),
              ]),
              _c("input", {
                staticClass: "required email",
                attrs: {
                  id: "mce-EMAIL",
                  type: "email",
                  value: "",
                  name: "EMAIL",
                },
              }),
            ]),
            _vm._m(0),
            _vm._m(1),
            _c("div", { staticClass: "optionalParent" }, [
              _c("div", { staticClass: "clear foot" }, [
                _c("input", {
                  staticClass: "button submit-button",
                  attrs: {
                    id: "mc-embedded-subscribe",
                    type: "submit",
                    name: "subscribe",
                  },
                  domProps: { value: _vm.$t("SubscribeEDM.input") },
                }),
              ]),
            ]),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "clear foot", attrs: { id: "mce-responses" } },
      [
        _c("div", {
          staticClass: "response",
          staticStyle: { display: "none" },
          attrs: { id: "mce-error-response" },
        }),
        _c("div", {
          staticClass: "response",
          staticStyle: { display: "none" },
          attrs: { id: "mce-success-response" },
        }),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: { position: "absolute", left: "-5000px" },
        attrs: { "aria-hidden": "true" },
      },
      [
        _c("input", {
          attrs: {
            type: "text",
            name: "b_be391e76a1d636d987ca92cdb_8814cc3b93",
            tabindex: "-1",
            value: "",
          },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }