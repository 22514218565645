var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "study-index page normal-page" }, [
    _c("div", { staticClass: "page-banner swiper-block" }, [
      _c("div", { staticClass: "swiper banner-swiper" }, [
        _c(
          "div",
          { staticClass: "swiper-wrapper" },
          _vm._l(_vm.banner, function (item, index) {
            return _c(
              "div",
              {
                key: item.id,
                staticClass: "swiper-slide",
                attrs: { "data-swiper-slide-index": index },
              },
              [
                _c("img", {
                  staticClass: "is-desktop",
                  attrs: { src: _vm.image(item.imageDes), alt: "" },
                }),
                _c("img", {
                  staticClass: "is-mobile",
                  attrs: { src: _vm.image(item.imageMob), alt: "" },
                }),
              ]
            )
          }),
          0
        ),
        _c("div", {
          staticClass: "swiper-pagination banner-swiper-pagination",
        }),
        _c("div", {
          staticClass: "swiper-button-prev banner-swiper-button-prev",
        }),
        _c("div", {
          staticClass: "swiper-button-next banner-swiper-button-next",
        }),
      ]),
    ]),
    _c("div", { staticClass: "web-content" }, [
      _c(
        "div",
        { staticClass: "page-structure" },
        [
          _c("PageMenu", { attrs: { "menu-data": _vm.pageMenu } }),
          _c("div", { staticClass: "page-content" }, [_c("router-view")], 1),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }