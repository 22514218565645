var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-menu-box" }, [
    _c("div", { staticClass: "page-menu-hide-box" }),
    _c("div", { staticClass: "page-menu-block" }, [
      _c(
        "div",
        { staticClass: "page-menu menu" },
        [
          _c("transition", { attrs: { name: "menu-show" } }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.pageMenuOpen,
                    expression: "pageMenuOpen",
                  },
                ],
                staticClass: "page-menu-body",
              },
              [
                _c("div", { staticClass: "page-menu-title-item" }, [
                  _c("img", { attrs: { src: _vm.menuData.icon, alt: "" } }),
                  _c("div", { staticClass: "page-menu-title" }, [
                    _vm._v(" " + _vm._s(_vm.$t(_vm.menuData.title)) + " "),
                  ]),
                ]),
                _vm.menuData.button && _vm.menuData.button.text
                  ? _c(
                      "div",
                      {
                        staticClass: "page-menu-list-btn",
                        on: {
                          click: function ($event) {
                            return _vm.goToRouter(_vm.menuData.button.routerTo)
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.$t(_vm.menuData.button.text)) + " "
                        ),
                      ]
                    )
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "page-menu-content" },
                  _vm._l(_vm.menuData.items, function (item, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "page-menu-item" },
                      [
                        item.type == "router" || item.type == "link"
                          ? _c(
                              "div",
                              { staticClass: "item-content" },
                              [
                                item.type == "router"
                                  ? _c(
                                      "router-link",
                                      {
                                        staticClass: "item-link",
                                        attrs: {
                                          to: {
                                            name: item.routeName,
                                            hash: item.hash,
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(_vm.$t(item.text)) + " "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                item.type == "link"
                                  ? _c(
                                      "a",
                                      {
                                        staticClass: "item-link",
                                        attrs: {
                                          href: item.link,
                                          target: item.target,
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.$t(item.text)))]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                        item.type == "panel"
                          ? _c(
                              "v-expansion-panels",
                              { attrs: { accordion: "" } },
                              [
                                _c(
                                  "v-expansion-panel",
                                  { staticClass: "item-panel" },
                                  [
                                    _c(
                                      "v-expansion-panel-header",
                                      { staticClass: "panel-title" },
                                      [
                                        _vm._v(
                                          " " + _vm._s(_vm.$t(item.text)) + " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "v-expansion-panel-content",
                                      { staticClass: "panel-content" },
                                      _vm._l(
                                        item.blockItems,
                                        function (subItem, i) {
                                          return _c(
                                            "div",
                                            {
                                              key: i,
                                              staticClass: "panel-sub-item",
                                            },
                                            [
                                              subItem.type == "router"
                                                ? _c(
                                                    "router-link",
                                                    {
                                                      staticClass:
                                                        "panel-sub-link",
                                                      attrs: {
                                                        to: {
                                                          name: subItem.routerName,
                                                          hash: subItem.hash,
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(subItem.text)
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              subItem.type == "link"
                                                ? _c(
                                                    "a",
                                                    {
                                                      staticClass:
                                                        "panel-sub-link",
                                                      attrs: {
                                                        href: subItem.link,
                                                        target: subItem.target,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(subItem.text)
                                                        )
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ]
            ),
          ]),
          _c(
            "div",
            { staticClass: "page-menu-btn", on: { click: _vm.clickPageMenu } },
            [
              _c(
                "v-icon",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.pageMenuOpen,
                      expression: "!pageMenuOpen",
                    },
                  ],
                  staticClass: "icon",
                },
                [_vm._v(" mdi-chevron-down ")]
              ),
              _c(
                "v-icon",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.pageMenuOpen,
                      expression: "pageMenuOpen",
                    },
                  ],
                  staticClass: "icon",
                },
                [_vm._v(" mdi-chevron-up ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }